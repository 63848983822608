import axios from "axios";
import { utils } from "../utils/utils";

class VendorsAPI {
  // Vendors
  getVendors() {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {};

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/my_vendors",
      requestBody,
      headers
    );
  }
  createVendor(name, email, countryCode, phoneNumber, location, address, city) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {
      vendor_name: name,
      vendor_email: email,
      vendor_phone_country: countryCode,
      vendor_phone: phoneNumber,
      vendor_location: location,
      vendor_address: address,
      vendor_city: city,
      metadata: {},
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/new_vendor",
      body,
      headers
    );
  }
  updateVendor(state, id) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {
      vendor_name: state.name,
      vendor_email: state.email,
      vendor_location: state.location,
      vendor_phone_country: state.countryCode,
      vendor_phone: state.phoneNumber,
      vendor_address: state.address,
      vendor_city: state.city,
      metadata: {},
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/update_vendor/" + id,
      body,
      headers
    );
  }
  deleteVendor(id) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {};

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/delete_vendor/" + id,
      body,
      headers
    );
  }

  // Vendor Payment Methods
  getVendorPaymentMethods() {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {};

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/my_vendor_accounts",
      requestBody,
      headers
    );
  }

  // Vendor Payment Accounts
  getPaymentMethods() {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {};

    return axios.post(
      process.env.REACT_APP_BASE_API_URL +
        "/payments/all_vendor_payment_methods",
      body,
      headers
    );
  }
  checkVendorPaymentAccountName(clientVendorID, payAccountName) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {
      client_vendor_id: clientVendorID,
      pay_account_name: payAccountName,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL +
        "/payments/check_vendor_account_exists",
      body,
      headers
    );
  }
  createVendorPaymentAccount(
    paymentAccountName,
    methodType,
    clientVendorId,
    metaData
  ) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {
      pay_account_name: paymentAccountName,
      pay_method_type: methodType,
      client_vendor_id: clientVendorId,
      metadata: metaData,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/new_vendor_account",
      body,
      headers
    );
  }
  updateVendorPaymentAccount(
    paymentAccountName,
    methodType,
    clientVendorId,
    metaData,
    vendorAccountId
  ) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {
      pay_account_name: paymentAccountName,
      pay_method_type: methodType,
      client_vendor_id: clientVendorId,
      metadata: metaData,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL +
        "/payments/update_vendor_account/" +
        vendorAccountId,
      body,
      headers
    );
  }
  deleteVendorPaymentAccount(id) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {};

    return axios.post(
      process.env.REACT_APP_BASE_API_URL +
        "/payments/delete_vendor_account/" +
        id,
      body,
      headers
    );
  }

  // Payments
  getPayments(vendorId) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {
      vendor_id: vendorId,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/get_vendor_payments",
      requestBody,
      headers
    );
  }
  payVendor(id, requestData) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {
      payment_account_id: id,
      amount: Number(requestData.amount),
      pay_currency: requestData.currencyTo,
      source_currency: requestData.currencyFrom,
      description: requestData.description,
      external_memo: "For you",
      metadata: {
        purpose: "For me",
      },
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/pay_vendor",
      body,
      headers
    );
  }

  payPeer(requestData) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {
      phone: requestData.phone,
      amount: Number(requestData.amount),
      currency: "USD",
      code: requestData.code,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/send2peer",
      body,
      headers
    );
  }
}

export const vendorsAPI = new VendorsAPI();
