import axios from "axios";
import { vendorsAPI } from "../../../apis/vendorsAPI";
import { utils } from "../../../utils/utils";

export default class VendorDetailService {
  getVendors(setState, id) {
    vendorsAPI
      .getVendors()
      .then((response) => {
        setState({
          loading: false,
        });

        if (response.status === 200 && response.data) {
          if (Array.isArray(response.data)) {
            setState({
              vendor: response.data.find((x) => x.client_vendor_id === id),
            });
          }
        } else {
        }
      })
      .catch((error) => {
        //Failure
      });
  }

  getVendorPaymentMethods(setState, getSelectedVendorPaymentMethods, id) {
    vendorsAPI
      .getVendorPaymentMethods()
      .then((response) => {
        console.log("getVendorPaymentMethods", response);
        if (response.status === 200 && response.data) {
          if (Array.isArray(response.data)) {
            setState(
              {
                listPaymentMethods: response.data,
                loadingPaymentMethods: false,
              },
              () => {
                const listSelectedVendorPaymentMethods =
                  getSelectedVendorPaymentMethods(id);
                setState({
                  listSelectedVendorPaymentMethods:
                    listSelectedVendorPaymentMethods,
                  listSelectedVendorPaymentMethodsShowed:
                    listSelectedVendorPaymentMethods,
                });
              }
            );
          } else {
            setState(
              {
                listPaymentMethods: [],
                loadingPaymentMethods: false,
              },
              () => {
                setState({
                  listSelectedVendorPaymentMethods: [],
                  listSelectedVendorPaymentMethodsShowed: [],
                });
              }
            );
          }
        } else {
          console.log("failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("error", error);
      });
  }

  getPayments(vendorId, setState, openPaymentDetailsFromSelectedPayment) {
    vendorsAPI
      .getPayments(vendorId)
      .then((response) => {
        console.log("getPayments", response);
        if (response.status === 200 && response.data) {
          setState(
            {
              loadingPayments: false,
              listPayments:
                process.env.REACT_APP_ENV != "prod"
                  ? this.getPaymentsMockResponse()
                  : response.data,
              listPaymentsShowed:
                process.env.REACT_APP_ENV != "prod"
                  ? this.getPaymentsMockResponse()
                  : response.data,
            },
            () => {
              openPaymentDetailsFromSelectedPayment();
            }
          );
        } else {
        }
      })
      .catch((error) => {
        //Failure
        console.log("getPayments", error);
      });
  }

  deletePaymentMethod(id, reloadInfo, setState) {
    vendorsAPI
      .deleteVendorPaymentAccount(id)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("deletePaymentMethod success", response);
          setState(
            { loadingSentDelete: false, showModalDeletePaymentMethod: false },
            () => {
              reloadInfo();
            }
          );
        } else {
          console.log("deletePaymentMethod failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("deletePaymentMethod error", error);
      });
  }

  deleteVendor(id, goToDeleteVendorResult) {
    vendorsAPI
      .deleteVendor(id)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("deleteVendor success", response);
          goToDeleteVendorResult("true");
        } else {
          console.log("deleteVendor failure", response);
          goToDeleteVendorResult("false");
        }
      })
      .catch((error) => {
        //Failure
        console.log("deleteVendor error", error);
        goToDeleteVendorResult("false");
      });
  }

  getPaymentsMockResponse() {
    return [
      {
        creation_time: "2024-01-11T21:42:15.118166+00:00",
        vendor: "william barrios",
        account: "william barrios",
        account_id: "37991b32-5945-4553-a949-7f977a25e3bc",
        description:
          "Description try, example number 1, this is example text just to make it long",
        amount: 1.0,
        paid_with: "1.0000000000000000 USD @ 1",
        status: "Approved",
        notes: '{"purpose":"For me"}',
      },
      {
        creation_time: "2024-01-11T21:42:15.118166+00:00",
        vendor: "william barrios",
        account: "william barrios",
        account_id: "37991b32-5945-4553-a949-7f977a25e3bc",
        description: "Description try, example number 2",
        amount: 1.0,
        paid_with: "1.0000000000000000 USD @ 1",
        status: "Failed",
        notes: '{"purpose":"For me"}',
      },
      {
        creation_time: "2024-01-11T21:42:15.118166+00:00",
        vendor: "william barrios",
        account: "william barrios",
        account_id: "37991b32-5945-4553-a949-7f977a25e3bc",
        description: null,
        amount: 1.0,
        paid_with: "1.0000000000000000 USD @ 1",
        status: "Pending",
        notes: '{"purpose":"For me"}',
      },
    ];
  }
}
