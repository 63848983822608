import axios from "axios";
import { utils } from "../utils/utils";

class PaymentAPI {
  checkPeer(id) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {
      key: id,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/v2/check_peer",
      body,
      headers
    );
  }

  payPeer(phone, amount, currency, code) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {
      phone: phone,
      amount: Number(amount),
      currency: currency,
      code: code,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/send2peer",
      body,
      headers
    );
  }
}

export const paymentAPI = new PaymentAPI();
