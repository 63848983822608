import { loginAPI } from "../../../apis/loginAPI";

export default class TwoStepVerificationService {
  confirmEmail(setState) {
    loginAPI
      .confirmEmail()
      .then((response) => {
        if (response.status === 200) {
          //Success
          var arrays = response.data.payload.split(" ");
          var cutEmail = arrays[arrays.length - 1].slice(3);

          setState(
            {
              message: "***" + cutEmail,
            },
            () => {
              setState({ loadingEmail: false });
            }
          );
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
      });
  }

  validateEmail(code, setState, setMessages, getClientConfig) {
    loginAPI
      .validateEmail(code)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          //Success
          getClientConfig();
          setState(
            {
              loadingSent: false,
            },
            () => {
              setState({
                stage: 2,
              });
            }
          );
        } else {
          //Failure
          setMessages(
            "*it-looks-like-you-have-entered-a-wrong-code.-please-try-again."
          );
          setState({
            invalidCode: true,
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        setMessages(
          "*it-looks-like-you-have-entered-a-wrong-code.-please-try-again."
        );
        setState({
          invalidCode: true,
          loadingSent: false,
        });
      });
  }
}
