import React, { Component } from "react";
import "./paymentInfoModal.css";
import { withRouter } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

class PaymentInfoModal extends Component {
  state = { show: true };

  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
    }
  }

  goToURL = (url) => {
    this.handleClose();
    this.props.history.push({ pathname: url, megastate: { from: true } });
  };

  handleClose = () => {
    this.props.toggleModalVerify(false);
  };

  getTitle = (type) => {
    switch (type) {
      case "COL":
        return this.props.localizedText["important-notice"];
      default:
        return this.props.localizedText["payment-notice"];
    }
  };

  getMessage = (type) => {};

  render() {
    return (
      <React.Fragment>
        <Modal
          show={this.props.showVerifyModal}
          onHide={this.handleClose}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.getTitle(process.env.REACT_APP_REGION)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {process.env.REACT_APP_REGION != "COL" ? (
              this.props.localizedText[
                "due-to-enchanced-compliance-procedures-the-current-processing"
              ]
            ) : (
              <div class="pay-info-important-notice-div">
                <div class="pay-info-important-notice-row">
                  1.{" "}
                  {
                    this.props.localizedText[
                      "minimum-wire-transaction-is-15000"
                    ]
                  }
                </div>
                <div class="pay-info-important-notice-row">
                  2.{" "}
                  {
                    this.props.localizedText[
                      "cutoff-time-for-bank-transfer-is-2"
                    ]
                  }
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {" "}
            <Button
              className="outside-secondary-button"
              onClick={this.handleClose}
            >
              {this.props.localizedText["close"]}
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(PaymentInfoModal);
