import { depositAPI } from "../../../apis/depositAPI.jsx";
import { swapAPI } from "../../../apis/swapAPI.jsx";

export default class DepositCashService {
  getConversion(amount, fromCurrency, toCurrency, setState, setMessages) {
    swapAPI
      .convertEstimate(amount, fromCurrency, toCurrency, 0)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("convertEstimate success", response);
          setState({
            conversion:
              Number(response.data.amount_to_receive) /
              Number(response.data.amount_to_send),
            loadingConversion: false,
          });
        } else {
          console.log("convertEstimate failure", response);
          setMessages(
            "*an-error-has-ocurred-while-trying-to-convert-a-currency"
          );
          setState({
            loadingConversion: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("convertEstimate error", error);
        setMessages("*an-error-has-ocurred-while-trying-to-convert-a-currency");
        setState({
          loadingConversion: false,
        });
      });
  }

  depositCash(currency, amount, note, usdEquivalent, setState, setMessages) {
    depositAPI
      .depositCash(
        amount,
        currency,
        note,
        usdEquivalent,
        process.env.REACT_APP_BASE_API_URL + "/dashboard/result/deposit/true"
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setState({
            loadingSent: false,
            //depositURL: responseDeposit.data.url,
            //showDepositModal: true,
          });
          window.open(response.data.url, "_blank");
        } else {
          setMessages("*an-error-has-ocurred-while-trying-to-deposit");
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        setMessages("*an-error-has-ocurred-while-trying-to-deposit");
        setState({
          loadingSent: false,
        });
      });
  }
}
