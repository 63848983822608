import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./portfolio.css";
import PortfolioService from "./portfolioService";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { TabContent } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import { ImSearch, ImStarFull, ImStarEmpty } from "react-icons/im";
import { FaPrint } from "react-icons/fa";
import { IoMdDownload } from "react-icons/io";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import TotalBalance from "../../components/total-balance/totalBalance";
import { utils } from "../../utils/utils";
import GoBackButton from "../../components/go-back-button/goBackButton";
import Accordion from "react-bootstrap/Accordion";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ReceiptComponent from "../../components/receipt/receipt";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import PrintPDFComponent from "../../components/printPDF/printPDF";

class Portfolio extends Component {
  state = {
    isMobileWidth: false,

    name: null,
    account: {},

    chartIndexPicked: 2,
    chartOption: [
      {
        name: "1D",
        length: 1,
        "length-in-granularity": 24,
        granularity: "HOUR",
        "number-points": 24,
      },
      {
        name: "5D",
        length: 5,
        "length-in-granularity": 120,
        granularity: "HOUR",
        "number-points": 40,
      },
      {
        name: "10D",
        length: 10,
        "length-in-granularity": 240,
        granularity: "HOUR",
        "number-points": 40,
      },
      {
        name: "1M",
        length: 30,
        "length-in-granularity": 30,
        granularity: "DAY",
        "number-points": 30,
      },
      {
        name: "2M",
        length: 60,
        "length-in-granularity": 60,
        granularity: "DAY",
        "number-points": 30,
      },
      {
        name: "3M",
        length: 90,
        "length-in-granularity": 90,
        granularity: "DAY",
        "number-points": 45,
      },
    ],

    listPortfolios: [],
    listPortfoliosShowed: [],

    listTransactions: [],
    listTransactionsShowed: [],

    listPortfolioSkeleton: [1, 2, 3, 4, 5, 6],

    showModalTransactionDetails: false,

    selectedDivIndex: null,
    selectedTransaction: {},

    tabPicked: "assets",
    shouldCheckTotalBalance: false,
    listLoadingFavorite: [],
    loadingAssets: true,
    loadingHistoricalCandles: true,
    loadingTransactions: true,
  };

  service = new PortfolioService();

  constructor(props) {
    super(props);

    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend
    );
  }

  componentDidMount() {
    if (this.props.phoneVerified != null && !this.props.phoneVerified) {
      this.goToURL("/dashboard/settings/verify-phone");
    } else if (this.props.emailVerified != null && !this.props.emailVerified) {
      this.goToURL("/dashboard/settings/verify-email");
    } else if (this.props.idVerified != null && !this.props.idVerified) {
      this.goToURL("/dashboard/settings/id-verification");
    }

    if (this.props.account) {
      this.setState({
        account: this.props.account,
      });
    } else {
      //ComponentDidUpdateWill take care of it
    }
    this.checkForTab();
    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      "/dashboard"
    );
    window.addEventListener("resize", this.checkWindowWidth);

    this.service.getDefaultPortfolio(
      this.setState.bind(this),
      this.state.chartOption,
      this.state.chartIndexPicked
    );
    this.service.getTransactionHistory(this.setState.bind(this));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.account) {
        this.setState({
          account: this.props.account,
        });
      }
    }
  }

  componentWillUnmount() {}

  vstyle = getComputedStyle(document.body);

  chartStyles = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
          color: this.vstyle.getPropertyValue("--inside-highlight-color"),
        },
      },
      y: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
          color: this.vstyle.getPropertyValue("--inside-highlight-color"),
        },
      },
    },
    animation: { duration: 500, y: { from: 25 }, x: { from: false } },
    responsive: true,
    maintainAspectRatio: false,
  };

  getDataset = (chartData, lengthInGranularity, numberOfPoints) => {
    //numberOfPointswill always be so that intervals are the same, so that it can be proportional
    let values = [];
    let pointIndex = 0;
    // chartData.length is length in days
    const adder = lengthInGranularity / numberOfPoints;

    for (let i = 0; i < numberOfPoints; i++) {
      values.push(chartData[pointIndex][4]);
      pointIndex = pointIndex + adder;
    }

    let labels = [];
    for (let i = 0; i < numberOfPoints; i++) {
      labels.push("i" + i);
    }

    let chartDataWithStyles = {
      labels: labels,
      datasets: [
        {
          label: "",
          data: values,
          backgroundColor: this.vstyle.getPropertyValue(
            "--inside-highlight-color"
          ),
          borderColor: this.vstyle.getPropertyValue("--inside-highlight-color"),
          pointRadius: 0,
        },
      ],
    };

    return chartDataWithStyles;
  };

  checkGoBackNavBar = () => {
    this.props.changeGoBackNavBar(false, null);
  };

  checkForTab = () => {
    if (
      this.props.match.params.tab &&
      this.props.match.params.tab == "history"
    ) {
      this.setState({
        tabPicked: "history",
      });
    }
  };

  goToExternalUrl = (url) => {
    window.location.replace(url);
  };

  goToURL = (url) => {
    this.props.history.push({ pathname: url, megastate: { from: true } });
  };

  clickTab = (tabClicked) => {
    this.setState({
      tabPicked: tabClicked,
      selectedDivIndex: null,
    });
  };

  getTitle = (card) => {
    if (
      card["transaction_type"] === "DEPOSIT" &&
      card["transaction_subtype"] === null
    ) {
      return this.props.localizedText["deposit"];
    } else if (
      card["transaction_type"] === "DEPOSIT" &&
      card["transaction_subtype"] === "RECEIVED_FROM_PEER"
    ) {
      return this.props.localizedText["received-from-peer"];
    } else if (
      card["transaction_type"] === "HOLD" &&
      card["transaction_subtype"] === null
    ) {
      return this.props.localizedText["hold"];
    } else if (
      card["transaction_type"] === "WITHDRAW" &&
      card["transaction_subtype"] === null
    ) {
      return this.props.localizedText["withdraw"];
    } else if (
      (card["transaction_type"] === "WITHDRAW" ||
        card["transaction_type"] === "HOLD") &&
      card["transaction_subtype"] === "PAY_VENDOR"
    ) {
      return this.props.localizedText["pay-vendor"];
    } else if (
      (card["transaction_type"] === "WITHDRAW" ||
        card["transaction_type"] === "HOLD") &&
      card["transaction_subtype"] === "SEND_TO_PEER"
    ) {
      return this.props.localizedText["send-to-peer"];
    } else if (card["transaction_type"] === "BUY") {
      return this.props.localizedText["buy"];
    } else if (card["transaction_type"] === "SELL") {
      return this.props.localizedText["sell"];
    } else {
      return card["transaction_type"];
    }
  };

  getDescription = (card) => {
    if (
      (card["transaction_type"] === "WITHDRAW" &&
        card["transaction_subtype"] === null) ||
      (card["transaction_type"] === "HOLD" &&
        card["transaction_subtype"] === null)
    ) {
      return this.props.localizedText["withdraw-to-"] + card["to"];
    } else if (
      card["transaction_type"] === "WITHDRAW" &&
      card["transaction_subtype"] === "SEND_TO_PEER"
    ) {
      return this.props.localizedText["sent-to-"] + card["to"];
    } else if (
      card["transaction_type"] === "WITHDRAW" &&
      card["transaction_subtype"] === "PAY_VENDOR"
    ) {
      return this.props.localizedText["paid-to-"] + card["to"];
    } else if (
      card["transaction_type"] === "BUY" &&
      card["transaction_subtype"] === null
    ) {
      return (
        this.props.localizedText["buy-"] +
        card["qty"] +
        card["base_currency"] +
        this.props.localizedText["-at-"] +
        card["price"]
      );
    } else if (
      card["transaction_type"] === "DEPOSIT" &&
      card["transaction_subtype"] === "RECEIVED_FROM_PEER"
    ) {
      return this.props.localizedText["received-from-"] + card["from"];
    } else if (
      card["transaction_type"] === "SELL" &&
      card["transaction_subtype"] === null
    ) {
      return (
        this.props.localizedText["sell-"] +
        card["qty"] +
        card["base_currency"] +
        this.props.localizedText["-at-"] +
        card["price"]
      );
    } else {
      return card["description"];
    }
  };

  getStatus = (card) => {
    if (card["transaction_type"] === "HOLD") {
      return this.props.localizedText["processing"];
    } else {
      return this.props.localizedText["completed"];
    }
  };

  toMonthString = (monthKey) => {
    var monthName = "";

    if (monthKey === "01") {
      monthName = this.props.localizedText["january"];
    } else if (monthKey === "02") {
      monthName = this.props.localizedText["february"];
    } else if (monthKey === "03") {
      monthName = this.props.localizedText["march"];
    } else if (monthKey === "04") {
      monthName = this.props.localizedText["april"];
    } else if (monthKey === "05") {
      monthName = this.props.localizedText["may"];
    } else if (monthKey === "06") {
      monthName = this.props.localizedText["june"];
    } else if (monthKey === "07") {
      monthName = this.props.localizedText["july"];
    } else if (monthKey === "08") {
      monthName = this.props.localizedText["august"];
    } else if (monthKey === "09") {
      monthName = this.props.localizedText["september"];
    } else if (monthKey === "10") {
      monthName = this.props.localizedText["october"];
    } else if (monthKey === "11") {
      monthName = this.props.localizedText["november"];
    } else if (monthKey === "12") {
      monthName = this.props.localizedText["december"];
    }

    return monthName;
  };

  editSearchTermDefault = (e, setState, list, listShowed) => {
    if (e.target.value != null && e.target.value != "") {
      setState({
        [listShowed]: this.filterFunction(list, e.target.value),
      });
    } else {
      setState({
        [listShowed]: list,
      });
    }
  };

  filterFunction = (objects, value) => {
    var filteredObjects = [];
    var lowerCaseName = "";
    var flagMatches = false;
    for (const i in objects) {
      flagMatches = false;

      lowerCaseName = objects[i]["data"]["base_currency"].toLowerCase();
      if (lowerCaseName.includes(value.toLowerCase())) {
        flagMatches = true;
      }

      if (flagMatches) {
        filteredObjects.push(objects[i]);
      }
    }

    return filteredObjects;
  };

  handleChartLengthChange = (index) => {
    if (this.state.chartIndexPicked != index) {
      this.setState(
        {
          loadingHistoricalCandles: true,
        },
        () => {
          this.service.getHistoricalCandles(
            this.setState.bind(this),
            this.state.listPortfolios,
            this.state.chartOption,
            index
          );
        }
      );
    }
  };

  skeletonCards = ({ cards }) => (
    <div class="div-cards">
      {cards.map(() => (
        <div class="card-asset">
          <div class="metadata-column-skeleton">
            <div class=" skeleton-box image-skeleton"></div>
            <div class="text-skeleton">
              <div class="skeleton-box title-skeleton"></div>
              <div class="skeleton-box abbreviation-skeleton"></div>
            </div>
          </div>
          <div class="skeleton-box  graph-column-skeleton"></div>
          <div class="balance-column-skeleton">
            <div class="skeleton-box value-skeleton"></div>
            <div class="skeleton-box percentage-skeleton"></div>
          </div>
        </div>
      ))}
    </div>
  );

  assets = ({ assets }) => (
    <div style={{ height: "100%" }}>
      <div class="time-length-div">
        {" "}
        <div class="filter-div">
          <div class="compare-option-div"></div>
          <div class="chart-length-option-div">
            {this.state.chartOption.map((option, index) => (
              <div
                class={
                  this.state.chartOption[this.state.chartIndexPicked]["name"] ==
                  option.name
                    ? "option option-picked"
                    : "option"
                }
                onClick={this.handleChartLengthChange.bind(this, index)}
              >
                {option.name}
              </div>
            ))}
          </div>
        </div>
        {this.state.loadingHistoricalCandles ? (
          <Spinner className="loader-small-outside "></Spinner>
        ) : null}
      </div>
      <div class="div-cards">
        {assets.map((asset, index) => (
          <div
            class="card-asset"
            onClick={this.goToURL.bind(
              this,
              "/dashboard/portfolio/asset/" + asset.data["base_currency"]
            )}
          >
            <div class="metadata-column">
              <div class="image"></div>
              <div class="text">
                <div class="title">{asset.data["base_currency"]}</div>
                <div class="abbreviation">{asset.data["base_currency"]}</div>
              </div>
            </div>
            <div class="chart-column">
              {asset["chart-data"] != null ? (
                <Line
                  options={this.chartStyles}
                  data={this.getDataset(
                    asset["chart-data"],
                    this.state.chartOption[this.state.chartIndexPicked][
                      "length-in-granularity"
                    ],
                    this.state.chartOption[this.state.chartIndexPicked][
                      "number-points"
                    ]
                  )}
                />
              ) : null}
            </div>
            <div class="favorite-column">
              {this.state.listLoadingFavorite.includes(
                asset.data["base_currency"]
              ) ? (
                <Spinner className="loader-favorite"></Spinner>
              ) : null}
              {!this.state.listLoadingFavorite.includes(
                asset.data["base_currency"]
              ) && asset.data["fav"] ? (
                <ImStarFull
                  className="favorite-icon"
                  onClick={(e) =>
                    this.checkFavorite(e, asset.data["base_currency"], false)
                  }
                />
              ) : null}
              {!this.state.listLoadingFavorite.includes(
                asset.data["base_currency"]
              ) && !asset.data["fav"] ? (
                <ImStarEmpty
                  className="favorite-icon"
                  onClick={(e) =>
                    this.checkFavorite(e, asset.data["base_currency"], true)
                  }
                />
              ) : null}
            </div>
            <div class="balance-column">
              {asset["value-usd"] != null ? (
                <div class="value">
                  ${utils.roundToTwoDecimals(asset["value-usd"])}
                </div>
              ) : (
                <div class="value">
                  ${utils.roundToTwoDecimals(asset.data["qty"])}
                </div>
              )}
              <div class="percentage">
                {utils.roundToTwoDecimals(asset.data["qty"])}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  openTransactionDetails = (payment, index) => {
    this.setState({
      selectedDivIndex: index,
      selectedTransaction: payment,
      showModalTransactionDetails: true,
    });
  };

  handleCloseTransactionDetails = () => {
    this.setState({
      selectedDivIndex: null,
      showModalTransactionDetails: false,
    });
  };

  getReceiptProps = () => {
    let data = [
      {
        title: this.props.localizedText["transfer-summary"],
        data: [
          {
            name: this.props.localizedText["transaction-id"],
            value: this.state.selectedTransaction.transaction_id,
            type: "normal",
          },
          {
            name: this.props.localizedText["transaction-type"],
            value: this.state.selectedTransaction.transaction_type,
            type: "normal",
          },
          {
            name: this.props.localizedText["transaction-sub-type"],
            value: this.state.selectedTransaction.transaction_subtype
              ? this.state.selectedTransaction.transaction_subtype
              : "-",
            type: "normal",
          },
          {
            name: this.props.localizedText["from"],
            value: this.state.selectedTransaction.from,
            type: "normal",
          },
          {
            name: this.props.localizedText["to"],
            value: this.state.selectedTransaction.to,
            type: "normal",
          },
          {
            name: this.props.localizedText["amount"],
            value: this.state.selectedTransaction.qty,
            type: "amount",
          },
          {
            name: this.props.localizedText["date"],
            value: this.state.selectedTransaction.creation_time,
            type: "date",
          },
          {
            name: this.props.localizedText["fee-amount"],
            value: this.state.selectedTransaction.fee_amount
              ? this.state.selectedTransaction.fee_amount
              : "-",
            type: "normal",
          },
          {
            name: this.props.localizedText["description"],
            value: this.state.selectedTransaction.description,
            type: "normal",
          },
        ],
      },
      {
        title: this.props.localizedText["transfer-summary"],
        data: [
          {
            name: this.props.localizedText["transaction-id"],
            value: this.state.selectedTransaction.transaction_id,
            type: "normal",
          },
          {
            name: this.props.localizedText["transaction-type"],
            value: this.state.selectedTransaction.transaction_type,
            type: "normal",
          },
          {
            name: this.props.localizedText["transaction-sub-type"],
            value: this.state.selectedTransaction.transaction_subtype
              ? this.state.selectedTransaction.transaction_subtype
              : "-",
            type: "normal",
          },
          {
            name: this.props.localizedText["from"],
            value: this.state.selectedTransaction.from,
            type: "normal",
          },
          {
            name: this.props.localizedText["to"],
            value: this.state.selectedTransaction.to,
            type: "normal",
          },
          {
            name: this.props.localizedText["amount"],
            value: this.state.selectedTransaction.qty,
            type: "amount",
          },
          {
            name: this.props.localizedText["date"],
            value: this.state.selectedTransaction.creation_time,
            type: "date",
          },
          {
            name: this.props.localizedText["fee-amount"],
            value: this.state.selectedTransaction.fee_amount
              ? this.state.selectedTransaction.fee_amount
              : "-",
            type: "normal",
          },
          {
            name: this.props.localizedText["description"],
            value: this.state.selectedTransaction.description,
            type: "normal",
          },
        ],
      },
    ];

    return data;
  };

  paymentsHistory = ({ transactions }) => (
    <div class="transaction-history-div">
      {transactions.map((month, index) => (
        <Accordion
          defaultActiveKey={["0"]}
          className="accordion-transaction-history"
          alwaysOpen
        >
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {Object.keys(month)[0].slice(3, 7) +
                " - " +
                this.toMonthString(Object.keys(month)[0].slice(0, 2))}
            </Accordion.Header>
            <Accordion.Body>
              <div class="div-transaction-cards">
                {month[Object.keys(month)[0]].map((card, index) => (
                  <div
                    class={
                      this.state.selectedDivIndex === card.transaction_id
                        ? "card-portfolio selected-card"
                        : "card-portfolio"
                    }
                    onClick={this.openTransactionDetails.bind(
                      this,
                      card,
                      card.transaction_id
                    )}
                  >
                    <div class="metadata-column">
                      <div class="text">
                        <div class="title">{this.getTitle(card)}</div>
                        <div class="date">
                          {utils.formatDateUSWithTime(card["creation_time"])}
                        </div>
                      </div>
                    </div>

                    <div class="status-column">{this.getStatus(card)}</div>

                    <div class="description-column">
                      {this.getDescription(card)}
                    </div>

                    <div class="amount-column">
                      <div
                        class={
                          card["qty"] > 0 ? "amount" : "amount amount-danger"
                        }
                      >
                        {utils.roundToTwoDecimals(card["qty"])}&nbsp;
                        {card["base_currency"]}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ))}
    </div>
  );

  checkFavorite = (event, currency, value) => {
    event.stopPropagation();
    var auxArr = this.state.listLoadingFavorite;
    auxArr.push(currency);
    this.disableChartAnimation();
    this.setState(
      {
        listLoadingFavorite: auxArr,
      },
      () => {
        this.service.changeCurrencyFavorite(
          this.state,
          this.setState.bind(this),
          currency,
          value
        );
      }
    );
  };

  stopChecking = () => {
    this.setState(
      {
        shouldCheckTotalBalance: false,
      },
      () => {
        this.enableChartAnimation();
      }
    );
  };

  //Since enabling righ after setState is not working, I will be enabling the animation on a case by case basis, instead of having it as default
  disableChartAnimation = () => {
    this.chartStyles.animation.duration = 0;
  };

  enableChartAnimation = () => {
    setTimeout(() => {
      this.chartStyles.animation.duration = 500;
    }, 0);
  };

  render() {
    return (
      <React.Fragment>
        <div class="portfolio-container">
          <div class="portfolio-body">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
                goBack={"/dashboard"}
              ></GoBackButton>
            </div>
            <div class="middle-container container-padding">
              <TotalBalance
                account={this.state.account}
                region={this.props.region}
                localizedText={this.props.localizedText}
                shouldCheckTotalBalance={this.state.shouldCheckTotalBalance}
                stopChecking={this.stopChecking}
              ></TotalBalance>
              <div class="bottom-content">
                <Tab.Container
                  defaultActiveKey={
                    this.props.match.params.tab &&
                    this.props.match.params.tab == "history"
                      ? "history"
                      : "assets"
                  }
                >
                  <div class="tab-header">
                    <div class="tab-buttons">
                      <Nav.Item
                        class={
                          this.state.tabPicked == "assets"
                            ? "tab-button tab-picked"
                            : "tab-button"
                        }
                      >
                        <Nav.Link
                          eventKey="assets"
                          onClick={this.clickTab.bind(this, "assets")}
                        >
                          {this.props.localizedText["assets"]}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        class={
                          this.state.tabPicked == "history"
                            ? "tab-button tab-picked"
                            : "tab-button"
                        }
                      >
                        <Nav.Link
                          eventKey="history"
                          onClick={this.clickTab.bind(this, "history")}
                        >
                          {this.props.localizedText["transaction-history"]}
                        </Nav.Link>
                      </Nav.Item>
                    </div>

                    {this.state.tabPicked == "assets" ? (
                      <div class="filter-search-div">
                        <InputGroup className="search-bar">
                          <ImSearch class="search-icon"></ImSearch>
                          <FormControl
                            onChange={(e) => {
                              this.editSearchTermDefault(
                                e,
                                this.setState.bind(this),
                                this.state.listPortfolios,
                                "listPortfoliosShowed"
                              );
                            }}
                            id="search-bar-entities"
                            placeholder={
                              this.props.localizedText["search-your-assets"]
                            }
                          />
                        </InputGroup>
                      </div>
                    ) : (
                      <div class="filter-search-div">
                        <InputGroup className="search-bar">
                          <ImSearch class="search-icon"></ImSearch>
                          <FormControl
                            onChange={this.editSearchTerm}
                            id="search-bar-entities"
                            placeholder={
                              this.props.localizedText["search-transactions"]
                            }
                          />
                        </InputGroup>
                      </div>
                    )}
                  </div>
                  <Tab.Content>
                    <Tab.Pane
                      eventKey="assets"
                      title="Assets"
                      tabClassName="tab"
                    >
                      <TabContent>
                        {!this.state.loadingAssets ? (
                          <this.assets
                            assets={this.state.listPortfoliosShowed}
                          ></this.assets>
                        ) : (
                          <this.skeletonCards
                            cards={this.state.listPortfolioSkeleton}
                          ></this.skeletonCards>
                        )}
                      </TabContent>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="history"
                      title="History"
                      tabClassName="tab"
                    >
                      <TabContent>
                        {!this.state.loadingTransactions ? (
                          <this.paymentsHistory
                            transactions={this.state.listTransactionsShowed}
                          ></this.paymentsHistory>
                        ) : (
                          <div>
                            <div class="skeleton-card-seperator"></div>
                            <this.skeletonCards
                              cards={this.state.listPortfolioSkeleton}
                            ></this.skeletonCards>
                          </div>
                        )}
                      </TabContent>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
            <div class="side-container"></div>
          </div>
        </div>

        <Modal
          show={this.state.showModalTransactionDetails}
          onHide={this.handleCloseTransactionDetails}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.localizedText["transaction-details"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="detail-container transaction-history-detail">
              {Object.keys(this.state.selectedTransaction).length > 0 ? (
                <ReceiptComponent
                  data={this.getReceiptProps()}
                ></ReceiptComponent>
              ) : null}
              <div class="icons-row">
                <IoMdDownload
                  class="left-icon"
                  onClick={utils.generatePDF.bind(
                    this,
                    this.state.selectedTransaction.transaction_type +
                      "-" +
                      this.state.selectedTransaction.transaction_id
                  )}
                />{" "}
                <ReactToPrint content={() => this.componentRef}>
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <FaPrint class="right-icon" onClick={handlePrint} />
                    )}
                  </PrintContextConsumer>
                </ReactToPrint>
              </div>{" "}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div class="payment-detail-footer">
              {this.props.localizedText["if-you-need-help"]}
              <br />
              {this.props.localizedText["go-to-our-"]}
              <a href="https://www.blox.global/contact/">
                {this.props.localizedText["resolution-center"]}
              </a>{" "}
              {
                this.props.localizedText[
                  "for-help-with-this-transaction,-to-settle-a-dispute-or-open-a-claim"
                ]
              }
            </div>
          </Modal.Footer>
        </Modal>

        <div id="hidden-div" style={{ display: "none" }}>
          <div
            class="detail-container-print"
            ref={(el) => (this.componentRef = el)}
            id="pdf"
          >
            {Object.keys(this.state.selectedTransaction).length > 0 ? (
              <PrintPDFComponent
                theme={this.props.theme}
                title={this.props.localizedText["transaction-details"]}
                receiptData={this.getReceiptProps()}
                selectedTransaction={this.state.selectedTransaction}
                text={this.props.localizedText["powered-by-blox"]}
              ></PrintPDFComponent>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Portfolio);
