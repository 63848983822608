import { portfolioAPI } from "../../../apis/portfolioAPI";
import { refdataAPI } from "../../../apis/refdataAPI";
import { vendorsAPI } from "../../../apis/vendorsAPI";
import { loginAPI } from "../../../apis/loginAPI";
import { swapAPI } from "../../../apis/swapAPI";
import { withdrawAPI } from "../../../apis/withdrawAPI";
import { marketDataAPI } from "../../../apis/marketDataAPI";

export default class PayVendorService {
  payVendor(id, state, setState, setMessages) {
    vendorsAPI
      .payVendor(id, state)
      .then((response) => {
        if (response.data.code === 0) {
          setState({
            loadingSent: false,
            stage: state.stage + 1,
            resultSuccess: true,
          });
        } else {
          setState({
            loadingSent: false,
            stage: state.stage + 1,
            resultSuccess: false,
          });
        }
      })
      .catch((error) => {
        setState({
          loadingSent: false,
          stage: state.stage + 1,
          resultSuccess: false,
        });
      });
  }

  withdrawCrypto(walletAddress, cryptoToken, network, amount, stage, setState) {
    withdrawAPI
      .withdrawCrypto(walletAddress, cryptoToken, network, amount)
      .then((response) => {
        if (response.status === 200 && response.data && response.data.success) {
          console.log("withdrawCrypto success", response);
          setState({
            loadingSent: false,
            stage: stage + 1,
            resultSuccess: true,
          });
        } else {
          console.log("withdrawCrypto failure", response);
          setState({
            loadingSent: false,
            stage: stage + 1,
            resultSuccess: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("withdrawCrypto error", error);
        setState({
          loadingSent: false,
          stage: stage + 1,
          resultSuccess: false,
        });
      });
  }

  getCurrencies(setState) {
    refdataAPI
      .getCurrenciesHolding()
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getCurrenciesHolding success", response);
          var arr = response.data;

          setState({
            listCurrenciesTo: arr,
          });
        } else {
          console.log("getCurrenciesHolding failure", response);
        }
      })
      .catch((error) => {
        console.log("getCurrenciesHolding error", error);
        //Failure
      });
  }

  getDefaultPortfolio(setState) {
    portfolioAPI
      .getDefaultPortfolio()
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("success", response);
          this.getPortfolioBalances(setState, response.data.name);
        } else {
          console.log("failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("error", error);
      });
  }

  getPortfolioBalances(setState, portfolioName) {
    portfolioAPI
      .getPortfolioBalances(portfolioName)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let total = 0;
          let fiatRequests = [];
          let fiatResponses = [];
          let cryptoRequests = [];
          let cryptoResponses = [];
          setState({
            listCurrenciesFrom: response.data,
          });
          for (let i = 0; i < response.data.length; i++) {
            if (
              response.data[i].fiat &&
              response.data[i].base_currency != "USD"
            ) {
              //conversion for fiat
              fiatRequests.push(response.data[i].base_currency);
              fiatResponses.push(
                marketDataAPI.getFiatConversion(
                  response.data[i].base_currency,
                  "USD",
                  response.data[i].qty
                )
              );
            } else if (!response.data[i].fiat) {
              //conversion for crypto
              cryptoRequests.push(response.data[i].base_currency);
              cryptoResponses.push(
                marketDataAPI.getProductStats24H(
                  response.data[i].base_currency,
                  "USD"
                )
              );
            } else {
              total = total + response.data[i].qty;
            }
          }
          Promise.all([
            Promise.all(fiatResponses),
            Promise.all(cryptoResponses),
          ])
            .then(([fiatResponses, cryptoResponses]) => {
              for (let i = 0; i < fiatResponses.length; i++) {
                if (JSON.parse(fiatResponses[i].data.payload).price) {
                  total =
                    total +
                    JSON.parse(fiatResponses[i].data.payload).price *
                      response.data.filter(
                        (element) => element.base_currency == fiatRequests[i]
                      )[0].qty;
                }
              }

              for (let i = 0; i < cryptoResponses.length; i++) {
                if (JSON.parse(cryptoResponses[i].data.payload).last) {
                  total =
                    total +
                    JSON.parse(cryptoResponses[i].data.payload).last *
                      response.data.filter(
                        (element) => element.base_currency == cryptoRequests[i]
                      )[0].qty;
                }
              }
              setState({
                loadingTotalBalance: false,
                totalBalance: total,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log("error", error);
      });
  }

  getConversion(amount, fromCurrency, toCurrency, setState, setMessages) {
    swapAPI
      .convertEstimate(amount, fromCurrency, toCurrency, 0)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("convertEstimate success", response);
          setState({
            conversion:
              Number(response.data.amount_to_receive) /
              Number(response.data.amount_to_send),
            loadingConversion: false,
          });
        } else {
          console.log("convertEstimate failure", response);
          setMessages(
            "*an-error-has-ocurred-while-trying-to-convert-a-currency"
          );
          setState({
            loadingConversion: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("convertEstimate error", error);
        setMessages("*an-error-has-ocurred-while-trying-to-convert-a-currency");
        setState({
          loadingConversion: false,
        });
      });
  }

  getClientConfig(setState) {
    loginAPI
      .getClientConfig()
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("config", response);
          setState({
            authChoice:
              response.data.auth_choice == "auth_pending" ||
              response.data.auth_choice == "auth_declined"
                ? "Phone"
                : "Google Auth",
          });

          if (
            response.data.auth_choice == "auth_pending" ||
            response.data.auth_choice == "auth_declined"
          ) {
            this.confirmPhone(setState);
          }
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
      });
  }

  confirmPhone(setState) {
    loginAPI
      .confirmPhone()
      .then((response) => {
        if (response.status === 200) {
          //Success
          var mySubString = response.data.payload.substring(
            response.data.payload.indexOf("[") + 1,
            response.data.payload.lastIndexOf("]]")
          );

          setState({
            message:
              "xxx-xxx-" +
              mySubString.slice(mySubString.length - 3, mySubString.length),
          });
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
      });
  }

  validatePhone(code, payVendor, setState, setMessages) {
    loginAPI
      .validatePhone(code)
      .then((response) => {
        console.log("responssse", response);
        if (response.status === 200 && response.data.success) {
          //Success
          payVendor();
        } else {
          //Failure
          setMessages("*we-couldn't-validate-the-entered-code");
          setState({
            invalidCode: true,
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        setMessages("*we-couldn't-validate-the-entered-code");
        setState({
          invalidCode: true,
          loadingSent: false,
        });
      });
  }

  validateAuth(code, payVendor, setState, setMessages) {
    loginAPI
      .validateAuth(code)
      .then((response) => {
        console.log("responssse", response);
        if (response.status === 200 && response.data.success) {
          //Success
          payVendor();
        } else {
          //Failure
          setMessages("*we-couldn't-validate-the-entered-code");
          setState({
            invalidCode: true,
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        setMessages("*we-couldn't-validate-the-entered-code");
        setState({
          invalidCode: true,
          loadingSent: false,
        });
      });
  }
}
