import { settingsAPI } from "../../apis/settingsAPI";

export default class SettingsService {
  changePassword(state, setState, setMessages, redirectCallback) {
    settingsAPI
      .changePassword(state.password, state.newPassword)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          localStorage.clear();
          redirectCallback("/");
        } else {
          //Failure
          setMessages("*an-error-has-ocurred-during-password-change");
          setState({
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        console.log(error.response);
        setMessages("*an-error-has-ocurred-during-password-change");
        setState({
          loadingSent: false,
        });
      });
  }
}
