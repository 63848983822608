import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { withRouter } from "react-router-dom";
import { MdCheckCircle, MdOutlineError } from "react-icons/md";
import ThemeRegionDiv from "../../../components/theme-region-div/themeRegionDiv";
import Footer from "../../../components/footer/footer";
import "./outsideResult.css";
import { utils } from "../../../utils/utils";

class OutsideResult extends Component {
  state = {
    isMobileWidth: false,
    title: "",
    message: "",
    buttonMessage: "",
  };

  componentDidMount() {
    if (this.props.location.megastate) {
    } else {
      this.goToURL("/");
    }

    this.checkWindowWidth();
    window.addEventListener("resize", this.checkWindowWidth);
  }

  checkWindowWidth = () => {
    this.windowSize = window.innerWidth;
    let flag = true;

    if (this.windowSize <= 1200) {
      flag = true;
    } else {
      flag = false;
    }

    this.setState({
      isMobileWidth: flag,
    });
  };

  goToURL = (url) => {
    this.props.history.push({ pathname: url, megastate: { from: true } });
  };

  getResultMessage = () => {
    if (this.props.location.megastate.type == "register") {
      if (this.props.location.megastate.success) {
        return this.props.localizedText[
          "your-account-was-created-successfully"
        ];
      } else {
        return this.props.localizedText[
          "unfortunately-we-couldn't-create-your-account"
        ];
      }
    } else if (this.props.location.megastate.type == "forgot-password") {
      if (this.props.location.megastate.success) {
        return this.props.localizedText[
          "a-temporary-password-has-emailed-to-your-account"
        ];
      } else {
        return this.props.localizedText[
          "unfortunately,-we-have-encountered-a-problem-while-trying-to-generate-a-new-password"
        ];
      }
    } else if (this.props.location.megastate.type == "change-password") {
      if (this.props.location.megastate.success) {
        return this.props.localizedText[
          "your-password-was-successfully-changed"
        ];
      } else {
        return this.props.localizedText[
          "unfortunately,-we-have-encountered-a-problem-while-trying-to-change-your-password"
        ];
      }
    } else if (this.props.location.megastate.type == "delete-account") {
      if (this.props.location.megastate.success) {
        return this.props.localizedText[
          "your-account-was-deleted-successfully"
        ];
      } else {
        return this.props.localizedText[
          "unfortunately-we-couldn't-delete-your-account"
        ];
      }
    } else if (this.props.location.megastate.type == "deactivate-account") {
      if (this.props.location.megastate.success) {
        return this.props.localizedText[
          "your-account-was-deactivated-successfully"
        ];
      } else {
        return this.props.localizedText[
          "unfortunately-we-couldn't-deactivate-your-account"
        ];
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div class="result-container">
          <div class="login-2fa-div">
            <div class="result-form-div">
              {this.props.location.megastate &&
              this.props.location.megastate.success ? (
                <MdCheckCircle class="icon"></MdCheckCircle>
              ) : (
                <MdOutlineError class="icon"></MdOutlineError>
              )}
              <div className="">
                {
                  this.props.localizedText[
                    utils.getResultTitle(this.props.location.megastate.success)
                  ]
                }
              </div>
              <div>{this.getResultMessage()}</div>
            </div>
            <div class="buttons-div">
              <Button
                className={
                  this.state.isMobileWidth
                    ? "primary-button outside-primary-button"
                    : "primary-button inside-primary-button"
                }
                onClick={this.props.location.megastate.primaryButtonFunction}
              >
                {this.props.localizedText["continue"]}
              </Button>
              {this.props.location.megastate.secondButton ? (
                <Button
                  className="back-button outside-secondary-button"
                  onClick={this.props.secondaryButtonFunction}
                >
                  {this.props.localizedText["continue"]}
                </Button>
              ) : null}
            </div>
          </div>
        </div>
        <ThemeRegionDiv
          changeTheme={this.props.changeTheme}
          theme={this.props.theme}
          handleModalOpen={this.props.handleModalOpen}
          changeRegion={this.props.changeRegion} //localization change
          region={this.props.region}
          localizedText={this.props.localizedText}
        ></ThemeRegionDiv>
        <Footer
          region={this.props.region}
          localizedText={this.props.localizedText}
          theme={this.props.theme}
        ></Footer>
      </React.Fragment>
    );
  }
}

export default withRouter(OutsideResult);
