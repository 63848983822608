import axios from "axios";
import { utils } from "../utils/utils";

class PortfolioAPI {
  getDefaultPortfolio() {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return axios.get(
      process.env.REACT_APP_BASE_API_URL + "/portfolio/get_default",
      headers
    );
  }

  getPortfolioBalances(portfolioName) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = { portfolio_name: portfolioName };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/portfolio/get_portfolio_balances",
      requestBody,
      headers
    );
  }

  getAllProducts() {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return axios.get(
      process.env.REACT_APP_BASE_API_URL + "/portfolio/get_all_products",
      headers
    );
  }

  getTransactionHistory() {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {};

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/portfolio/get_deep_portfolio_info",
      requestBody,
      headers
    );
  }

  getTransactionHistoryForSymbol(portfolioName, symbol) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      portfolio_name: portfolioName,
      symbol: symbol,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL +
        "/portfolio/portfolio_transactions_for_symbol",
      requestBody,
      headers
    );
  }

  changeCurrencyFavorite(portfolioName, currency, enabled) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      portfolio_name: portfolioName,
      base_currency: currency,
      enabled: enabled,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/portfolio/portafolio_fav",
      requestBody,
      headers
    );
  }
}

export const portfolioAPI = new PortfolioAPI();
