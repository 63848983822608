import { loginAPI } from "../../../apis/loginAPI";
import { paymentAPI } from "../../../apis/paymentAPI";
import { portfolioAPI } from "../../../apis/portfolioAPI";

export default class PaymentInAppUserService {
  getDefaultPortfolio(setState) {
    portfolioAPI
      .getDefaultPortfolio()
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getDefaultPortfolio success", response);
          this.getPortfolioBalances(setState, response.data.name);
        } else {
          console.log("getDefaultPortfolio failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("getDefaultPortfolio error", error);
      });
  }

  getPortfolioBalances(setState, portfolioName) {
    portfolioAPI
      .getPortfolioBalances(portfolioName)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          console.log("getPortfolioBalances success", response);
          setState({
            listCurrencies: response.data,
          });
        } else {
          //Failure
          console.log("getPortfolioBalances failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("getPortfolioBalances error", error);
      });
  }

  getClientConfig(setState) {
    loginAPI
      .getClientConfig()
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("config", response);
          setState({
            authChoice:
              response.data.auth_choice == "auth_pending" ||
              response.data.auth_choice == "auth_declined"
                ? "Phone"
                : "Google Auth",
          });

          if (
            response.data.auth_choice == "auth_pending" ||
            response.data.auth_choice == "auth_declined"
          ) {
            this.confirmPhone(setState);
          }
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
      });
  }

  confirmPhone(setState) {
    loginAPI
      .confirmPhone()
      .then((response) => {
        if (response.status === 200) {
          //Success
          var mySubString = response.data.payload.substring(
            response.data.payload.indexOf("[") + 1,
            response.data.payload.lastIndexOf("]]")
          );

          setState({
            message:
              "xxx-xxx-" +
              mySubString.slice(mySubString.length - 3, mySubString.length),
          });
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
      });
  }

  checkPeer(state, setState, setMessages) {
    paymentAPI
      .checkPeer(state.id)
      .then((response) => {
        console.log("success", response);
        if (response.data.code === 0) {
          setState({
            loadingSent: false,
            invalidID: false,
            name: JSON.parse(response.data.payload).name,
            email: JSON.parse(response.data.payload).email,
            phone: JSON.parse(response.data.payload).phone,
            stage: 2,
          });
        } else {
          setMessages("*couldn't-find-any-user-with-this-id");
          setState({
            loadingPeer: false,
            invalidID: true,
          });
        }
      })
      .catch((error) => {
        console.log("fail", error);

        setMessages("*couldn't-find-any-user-with-this-id");
        setState({
          loadingPeer: false,
          invalidID: true,
        });
      });
  }

  payPeer(stage, phone, amount, currency, code, setState) {
    paymentAPI
      .payPeer(phone, amount, currency, code)
      .then((response) => {
        if (response.data.code === 0) {
          setState({
            invalidCode: false,
            stage: stage + 1,
            resultSuccess: true,
          });
        } else {
          setState({
            invalidCode: false,
            stage: stage + 1,
            resultSuccess: false,
          });
        }
      })
      .catch((error) => {
        setState({
          invalidCode: false,
          stage: stage + 1,
          resultSuccess: false,
        });
      });
  }
}
