import axios from "axios";
import { utils } from "../utils/utils";

class SwapAPI {
  getLockedRates() {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(
      process.env.REACT_APP_BASE_API_URL +
        "/portfolio/swap/convert_get_my_locked",
      headers
    );
  }

  convertEstimate(amount, fromCurrency, toCurrency, lockTime) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      amount: Number(amount),
      from_currency: fromCurrency,
      to_currency: toCurrency,
      desired_lock_time_in_secs: Number(lockTime),
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/portfolio/swap/convert_estimate",
      requestBody,
      headers
    );
  }

  reverseConvertEstimate(amount, fromCurrency, toCurrency, lockTime) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      amount_to_receive: Number(amount),
      from_currency: fromCurrency,
      to_currency: toCurrency,
      desired_lock_time_in_secs: Number(lockTime),
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL +
        "/portfolio/swap/convert_estimate_reverse",
      requestBody,
      headers
    );
  }

  conversionLock(amount, fromCurrency, toCurrency, lockTime) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      amount: Number(amount),
      from_currency: fromCurrency,
      to_currency: toCurrency,
      desired_lock_time_in_secs: Number(lockTime),
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/portfolio/swap/convert_lock",
      requestBody,
      headers
    );
  }

  reverseConversionLock(amount, fromCurrency, toCurrency, lockTime) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      amount_to_receive: Number(amount),
      from_currency: fromCurrency,
      to_currency: toCurrency,
      desired_lock_time_in_secs: Number(lockTime),
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL +
        "/portfolio/swap/convert_lock_reverse",
      requestBody,
      headers
    );
  }

  convertNow(amount, fromCurrency, toCurrency) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      amount: Number(amount),
      from_currency: fromCurrency,
      to_currency: toCurrency,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/portfolio/swap/convert",
      requestBody,
      headers
    );
  }

  sendWithLockedRate(locked_rate_id, txhash) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      locked_rate_id: locked_rate_id,
      txhash: txhash,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/portfolio/swap/convert_execute",
      requestBody,
      headers
    );
  }

  convertWithLockedRate(locked_rate_id) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      locked_rate_id: locked_rate_id,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL +
        "/portfolio/swap/convert_with_estimate",
      requestBody,
      headers
    );
  }

  cancelLockedRate(locked_rate_id) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      locked_rate_id: locked_rate_id,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/portfolio/swap/convert_cancel",
      requestBody,
      headers
    );
  }
}

export const swapAPI = new SwapAPI();
