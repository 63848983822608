import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./depositBank.css";
import DepositBankService from "./depositBankService.jsx";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import { Spinner } from "react-bootstrap";
import { utils } from "../../../utils/utils.jsx";
import ErrorMessageComponent from "../../errorMessage/errorMessage.jsx";
import ConversionLinkComponent from "../../conversionLink/conversionLink.jsx";
import { AiFillCopy } from "react-icons/ai";
import { FaCheck } from "react-icons/fa6";

class DepositBank extends Component {
  state = {
    width: window.innerWidth,
    account: {},

    errorMessageType: "",

    currency: "",
    amount: "",
    conversion: "",
    fundsToRecieve: "",
    note: "",
    listPixTypes: ["CPF", "CNPJ", "EMAIL", "PHONE", "RANDOM"],
    pixType: "",
    pixValue: "",
    pixID: "",
    userPixKeyCreated: false,

    qrcodeBase64: "",
    inboundAddress: "",
    inboundAddressCopied: false,

    depositURL: "",
    showDepositModal: false,

    loadingCurrencies: true,
    loadingConversion: false,
    loadingKeys: false,
    loadingSent: false,

    invalidCurrency: false,
    invalidAmount: false,
    invalidPixType: false,
    invalidPixValue: false,

    resultSuccess: true,
  };

  service = new DepositBankService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      this.props.tryToGoBackStage
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        this.props.tryToGoBackStage
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {}

  goToURL = (url) => {
    this.props.history.push({ pathname: url, megastate: { from: true } });
  };

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  handleChangeForRatio = (event, setState) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (name == "currency" && value == "BRL") {
      this.setState(
        {
          loadingKeys: true,
        },
        () => {
          this.service.getBrlPixKey(this.setState.bind(this));
        }
      );
    }

    setState(
      {
        [name]: value,
      },
      () => {
        this.props.setCurrency(this.state.currency);
        if (
          this.state.currency != "USD" &&
          this.state.amount != "" &&
          this.state.currency != ""
        ) {
          setState(
            {
              conversion: "",
              loadingConversion: true,
            },
            () => {
              this.service.getConversion(
                this.state.amount,
                this.state.currency,
                "USD",
                this.setState.bind(this),
                this.setMessages.bind(this)
              );
            }
          );
        } else if (this.state.currency == "USD") {
          setState(
            {
              conversion: "",
            },
            () => {
              setState({
                conversion: "1",
              });
            }
          );
        }
      }
    );
  };

  copyText = () => {
    this.setState({
      inboundAddressCopied: true,
    });
    navigator.clipboard.writeText(this.state.inboundAddress);
  };

  goToDeposit = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (this.state.currency != "BRL") {
          if (this.state.currency != "" && this.state.amount != "") {
            this.setState(
              {
                invalidCurrency: false,
                invalidAmount: false,

                loadingSent: true,
              },
              () => {
                this.service.depositBank(
                  this.state.currency,
                  this.state.amount,
                  this.state.note,
                  Number(this.state.amount * this.state.conversion),
                  this.setState.bind(this),
                  this.setMessages.bind(this)
                );
              }
            );
          } else {
            this.setMessages("*one-or-more-required-field(s)-are-missing");
            this.setState({
              invalidCurrency: this.state.currency == "",
              invalidAmount: this.state.amount == "",
            });
          }
        } else {
          if (
            this.state.currency != "" &&
            this.state.amount != "" &&
            this.state.userPixKeyCreated
          ) {
            this.setState(
              {
                invalidCurrency: false,
                invalidAmount: false,

                loadingSent: true,
              },
              () => {
                this.service.depositBank(
                  this.state.currency,
                  this.state.amount,
                  this.state.note,
                  Number(this.state.amount * this.state.conversion),
                  this.setState.bind(this)
                );
              }
            );
          } else if (
            this.state.currency != "" &&
            this.state.amount != "" &&
            this.state.pixType != "" &&
            this.state.pixValue != ""
          ) {
            this.setState(
              {
                invalidCurrency: false,
                invalidAmount: false,

                loadingSent: true,
              },
              () => {
                this.createPixKey(true);
              }
            );
          } else {
            this.setMessages("*one-or-more-required-field(s)-are-missing");
            this.setState({
              invalidCurrency: this.state.currency == "",
              invalidAmount: this.state.amount == "",
            });
          }
        }
      }
    );
  };

  createPixKey = (depositAfter) => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (this.state.pixType != "" && this.state.pixValue != "") {
          this.setState(
            {
              invalidPixType: false,
              invalidPixValue: false,

              loadingKeys: true,
            },
            () => {
              this.service.createPixKey(
                this.state.pixType,
                this.state.pixValue,
                this.setState.bind(this),
                this.setMessages.bind(this),
                depositAfter ? this.goToDeposit.bind(this) : null
              );
            }
          );
        } else {
          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidPixType: this.state.pixType == "",
            invalidPixValue: this.state.pixValue == "",
          });
        }
      }
    );
  };

  deletePixKey = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        this.setState(
          {
            invalidPixType: false,
            invalidPixValue: false,

            loadingKeys: true,
          },
          () => {
            this.service.deletePixKey(
              this.state.pixID,
              this.setState.bind(this)
            );
          }
        );
      }
    );
  };

  removeCurrency = (array, currency) => {
    if (process.env.REACT_APP_ENV == "prod") {
      return array.filter((obj) => obj.base_currency !== currency);
    } else {
      return array;
    }
  };

  render() {
    return (
      <React.Fragment>
        <div class="deposit-bank-div">
          <ConversionLinkComponent
            width={this.state.width}
            currencyFrom={this.state.currency}
            currencyTo={"USD"}
            region={this.props.region}
            localizedText={this.props.localizedText}
          />
          <div class="section">
            <div class="left-input">
              <div class="background"></div>
              <div class="content">
                <div class="form-div">
                  <Form.Group className="mb-3">
                    <Form.Label className={"input-label-inside-div"}>
                      {this.props.localizedText["currency"]}
                    </Form.Label>
                    <InputGroup className="mb-3 input-field">
                      <Form.Select
                        variant="outline-secondary"
                        onChange={(e) =>
                          this.handleChangeForRatio(e, this.setState.bind(this))
                        }
                        name="currency"
                        isInvalid={this.state.invalidCurrency}
                        style={{
                          width: "100%",
                          flex: "0 1 auto",
                          textAlign: "start",
                        }}
                        disabled={this.state.loadingSent}
                      >
                        <option></option>
                        {this.removeCurrency(
                          this.props.listCurrenciesFiat,
                          "BRL"
                        ).map((currency) => (
                          <option value={currency.base_currency}>
                            {currency.name +
                              " (" +
                              currency.base_currency +
                              ")"}
                          </option>
                        ))}
                      </Form.Select>
                    </InputGroup>
                  </Form.Group>
                </div>
                <div class="form-div">
                  <Form.Group className="mb-3">
                    <Form.Label className="input-label-inside-div">
                      {this.props.localizedText["amount"]}
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        className="input-text no-background-input-inside"
                        type="number"
                        name="amount"
                        value={this.state.amount}
                        isInvalid={this.state.invalidAmount}
                        onChange={(e) =>
                          this.handleChangeForRatio(e, this.setState.bind(this))
                        }
                        disabled={this.state.loadingSent}
                        autoComplete="off"
                      />{" "}
                      <InputGroup.Text
                        className={
                          this.state.invalidAmount
                            ? "inside-input-symbol input-symbol-danger"
                            : "inside-input-symbol"
                        }
                      >
                        {this.state.currency}
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>{" "}
                </div>{" "}
                <div class="message-div">
                  {
                    this.props.localizedText[
                      "*upon-completion-of-your-payment,-you-will-be-redirected-back-to-this-page"
                    ]
                  }
                </div>
              </div>
            </div>{" "}
            <div class="right-div">
              <div
                class={
                  this.state.currency != "BRL"
                    ? "value-input"
                    : "value-input value-input-pix"
                }
              >
                {this.state.currency != "BRL" ? (
                  <InputGroup className="input-group">
                    <Form.Label className="input-label-outside-div">
                      {this.props.localizedText["note-(optional)"]}
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="note"
                      value={this.state.note}
                      onChange={(e) =>
                        utils.handleChange(e, this.setState.bind(this))
                      }
                      className="text-area"
                      disabled={this.state.loadingSent}
                    />
                  </InputGroup>
                ) : (
                  <div class="pix-div">
                    {this.state.loadingKeys != "" ? (
                      <div>
                        <Spinner className="loading-keys" />
                      </div>
                    ) : null}

                    {!this.state.loadingKeys &&
                    !this.state.userPixKeyCreated ? (
                      <div
                        class="add-remove-pix-button link-outside fade-in-up"
                        onClick={this.createPixKey.bind(this, null)}
                      >
                        {this.props.localizedText["add-pix"]}
                      </div>
                    ) : null}

                    {!this.state.loadingKeys && this.state.userPixKeyCreated ? (
                      <div
                        class="add-remove-pix-button link-outside fade-in-up"
                        onClick={this.deletePixKey}
                      >
                        {this.props.localizedText["remove-pix"]}
                      </div>
                    ) : null}

                    {!this.state.loadingKeys ? (
                      <Form.Group className="mb-3 fade-in-up">
                        <Form.Label className="input-label-outside-div">
                          {this.props.localizedText["pix-reference"]}
                        </Form.Label>
                        <InputGroup className="mb-3 input-field">
                          <Form.Select
                            variant="outline-secondary"
                            onChange={(e) =>
                              utils.handleChange(e, this.setState.bind(this))
                            }
                            name="pixType"
                            isInvalid={this.state.invalidPixType}
                            style={{
                              width: "7rem",
                              flex: "0 1 auto",
                              textAlign: "start",
                            }}
                            disabled={
                              this.state.loadingSent ||
                              this.state.userPixKeyCreated
                            }
                            value={this.state.pixType}
                          >
                            <option></option>
                            {this.state.listPixTypes.map((type) => (
                              <option value={type}>{type}</option>
                            ))}
                          </Form.Select>
                          <Form.Control
                            name="pixValue"
                            value={this.state.pixValue}
                            onChange={(e) =>
                              utils.handleChange(e, this.setState.bind(this))
                            }
                            isInvalid={this.state.invalidPixValue}
                            className="input-field"
                            disabled={
                              this.state.loadingSent ||
                              this.state.userPixKeyCreated
                            }
                          />
                        </InputGroup>
                      </Form.Group>
                    ) : null}
                  </div>
                )}
                {this.state.conversion != "" ? (
                  <Form.Group className="mb-3 form-group fade-in-up">
                    <Form.Label className="input-label-outside-div">
                      {this.props.localizedText["funds-to-recieve"]}
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <FormControl
                        className="input-text no-background-input-outside"
                        type="text"
                        name="fundsToRecieve"
                        value={utils.roundToTwoDecimals(
                          Number(this.state.amount * this.state.conversion)
                        )}
                        disabled
                      />
                      <InputGroup.Text className="outside-input-symbol">
                        USD
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                ) : null}
                {this.state.loadingConversion != "" ? (
                  <div>
                    <Spinner className="loading-conversions" />
                  </div>
                ) : null}
                {!this.state.loadingWallet && this.state.qrcodeBase64 != "" ? (
                  <div class="fade-in-up">
                    <img
                      className="qrcode"
                      src={`data:image/png;base64, ${this.state.qrcodeBase64}`}
                    />
                    <div class="key-div" onClick={this.copyText}>
                      <div class="key">{this.state.inboundAddress}</div>
                      {this.state.inboundAddressCopied ? (
                        <FaCheck class="copy-success-icon" />
                      ) : (
                        <AiFillCopy class="copy-key-icon" />
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>{" "}
          <div>
            <ErrorMessageComponent
              errorMessage={
                this.props.localizedText[this.state.errorMessageType]
              }
            ></ErrorMessageComponent>
            <div class="buttons-div fade-in-up">
              <Button
                className="primary-button outside-primary-button"
                onClick={this.goToDeposit}
                disabled={this.state.loadingSent}
              >
                {this.state.loadingSent ? (
                  <Spinner className="loader-send-outside-div-button"></Spinner>
                ) : null}
                {this.props.localizedText["deposit"]}
              </Button>

              <Button
                className="secondary-button outside-secondary-button"
                onClick={this.props.tryToGoBackStage}
                disabled={this.state.loadingSent}
              >
                {this.props.localizedText["cancel"]}
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(DepositBank);
