import axios from "axios";
import { utils } from "../utils/utils";

class DepositAPI {
  depositDev(amount, instrument, portfolioName) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      portfolio_name: portfolioName,
      instrument: instrument,
      qty: Number(amount),
      cost_basis: 1,
      from: "Chase-8074",
      notes: "Wired from Chase",
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/portfolio/deposit",
      requestBody,
      headers
    );
  }

  depositSimple(
    portfolioName,
    currency,
    amount,
    note,
    usdEquivalent,
    payer_redirect_url
  ) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {
      portfolio_name: portfolioName,
      amount: amount,
      currency: currency,
      note: note,
      payer_redirect_url: payer_redirect_url,
      usd_equiv: usdEquivalent,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/simple_funds_in",
      requestBody,
      headers
    );
  }

  depositBank(currency, amount, note, usdEquivalent, payer_redirect_url) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {
      amount: amount,
      currency: currency,
      note: note,
      payer_redirect_url: payer_redirect_url,
      usd_equiv: usdEquivalent,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/funds_in_bank",
      requestBody,
      headers
    );
  }

  depositCash(currency, amount, note, usdEquivalent, payer_redirect_url) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {
      amount: amount,
      currency: currency,
      note: note,
      payer_redirect_url: payer_redirect_url,
      usd_equiv: usdEquivalent,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/funds_in_cash",
      requestBody,
      headers
    );
  }

  getPixKeys() {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(
      process.env.REACT_APP_BASE_API_URL + "/pix-api/user/keys",
      headers
    );
  }

  createPixKey(type, value) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {
      type: type,
      value: value,
    };
    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/pix-api/user/keys",
      requestBody,
      headers
    );
  }

  deletePixKey(id) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return axios.delete(
      process.env.REACT_APP_BASE_API_URL + "/pix-api/user/keys/delete/" + id,
      headers
    );
  }

  getQuote(fiatCurrency, fiatAmount, cryptoSymbol, network) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let url =
      "/shift4/integration/quote-comparison?fiatCurrency=" +
      fiatCurrency +
      "&fiatAmount=" +
      fiatAmount +
      "&cryptoSymbol=" +
      cryptoSymbol +
      "&blockChain=" +
      network;
    return axios.get(process.env.REACT_APP_BASE_API_URL + url, headers);
  }

  checkoutUrl(
    provider,
    fiatCurrency,
    fiatAmount,
    cryptoSymbol,
    network,
    payerEmail
  ) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let url = "/shift4/integration/email?provider=" + provider;
    const requestBody = {
      amount: Number(fiatAmount),
      currency: fiatCurrency,
      cryptoSymbol: cryptoSymbol,
      blockchain: network,
      payerEmail: payerEmail,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + url,
      requestBody,
      headers
    );
  }
}

export const depositAPI = new DepositAPI();
