import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./withdraw.css";
import WithdrawService from "./withdrawService.jsx";
import GoBackButton from "../../components/go-back-button/goBackButton.jsx";
import TotalBalance from "../../components/total-balance/totalBalance.jsx";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import { Spinner } from "react-bootstrap";
import { utils } from "../../utils/utils.jsx";
import { ImSearch } from "react-icons/im";
import ErrorMessageComponent from "../../components/errorMessage/errorMessage.jsx";
import { RiBankFill } from "react-icons/ri";
import { SiBitcoinsv } from "react-icons/si";
import { FaMoneyBillWave } from "react-icons/fa";
import Result from "../../components/result/result";
import ConversionComponent from "../../components/conversion-component/conversionComponent";
import { MdCheckCircle } from "react-icons/md";
import ConversionLinkComponent from "../../components/conversionLink/conversionLink.jsx";
import { MdOutlineArrowForwardIos } from "react-icons/md";

class Withdraw extends Component {
  state = {
    width: window.innerWidth,
    account: {},

    listPaymentProviders: [],
    listFiatWithdraw: [],
    listCryptoWithdraw: [],
    listNetworks: [],

    errorMessageType: "",

    currency: "",
    amount: "",
    accountType: "",
    accountNumber: "",
    bankID: "",
    note: "",

    walletAddress: "",
    token: "",
    network: {},

    code: "",
    authChoice: "",
    confirmPhoneMessage: "",

    withdrawCashResponse: {},

    loadingPaymentProviders: true,
    loadingCurrenciesWithdraw: true,
    loadingNetworks: true,
    loadingSent: false,

    invalidCurrency: false,
    invalidAmount: false,
    invalidAccountType: false,
    invalidAccountNumber: false,
    invalidBankID: false,
    invalidWalletAddress: false,
    invalidToken: false,
    invalidNetwork: false,
    invalidCode: false,

    resultSuccess: true,

    stage: 1,
  };

  service = new WithdrawService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.phoneVerified != null && !this.props.phoneVerified) {
      this.goToURL("/dashboard/settings/verify-phone");
    } else if (this.props.emailVerified != null && !this.props.emailVerified) {
      this.goToURL("/dashboard/settings/verify-email");
    } else if (this.props.idVerified != null && !this.props.idVerified) {
      this.goToURL("/dashboard/settings/id-verification");
    }

    this.service.getClientConfig(this.setState.bind(this));
    this.service.getPaymentProvidersByCountry(this.setState.bind(this));
    this.service.getCurrenciesWithdraw(this.setState.bind(this));

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      this.tryToGoBackStage
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        this.tryToGoBackStage
      )
    );

    if (this.props.account) {
      this.setState({
        account: this.props.account,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.account) {
        this.setState({
          account: this.props.account,
        });
      }
    }
  }

  goToURL = (url) => {
    this.props.history.push({ pathname: url, megastate: { from: true } });
  };

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  moveStage = (stage) => {
    this.setState({
      stage: stage,
    });
  };

  goBackStage = () => {
    this.setState({
      stage: 1,
      currency: "",
      amount: "",
      accountType: "",
      accountNumber: "",
      bankID: "",
      note: "",
      token: "",
      network: {},
      walletAddress: "",
      errorMessageType: "",
      invalidCurrency: false,
      invalidAmount: false,
      invalidAccountType: false,
      invalidAccountNumber: false,
      invalidBankID: false,
      invalidWalletAddress: false,
      invalidToken: false,
      invalidNetwork: false,
      loadingConversion: false,
    });
  };

  validateWithdrawBank = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (
          this.state.currency != "" &&
          this.state.amount != "" &&
          this.state.bankID != "" &&
          this.state.accountType != "" &&
          this.state.accountNumber != "" &&
          this.state.code != ""
        ) {
          if (this.state.amount != "" && this.state.currency != "") {
            if (
              this.state.amount >
              this.state.listFiatWithdraw.filter(
                (element) => element.base_currency == this.state.currency
              )[0].available_qty
            ) {
              this.setMessages(
                "*please-adjust-the-entered-amount.-it-exceeds-your-current-holdings"
              );
              this.setState({
                invalidAmount: true,
              });
            } else {
              //Proceed with withdraw
              this.setState(
                {
                  invalidCurrency: false,
                  invalidAmount: false,
                  invalidBankID: false,
                  invalidAccountType: false,
                  invalidAccountNumber: false,

                  loadingSent: true,
                },
                () => {
                  if (this.state.authChoice == "Phone") {
                    this.service.validatePhone(
                      this.state.code,
                      this.setState.bind(this),
                      this.setMessages.bind(this),
                      this.doWithdrawBank.bind(this)
                    );
                  } else {
                    this.service.validateAuth(
                      this.state.code,
                      this.setState.bind(this),
                      this.setMessages.bind(this),
                      this.doWithdrawBank.bind(this)
                    );
                  }
                }
              );
            }
          }
        } else {
          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidCurrency: this.state.currency == "",
            invalidAmount: this.state.amount == "",
            invalidBankID: this.state.bankID == "",
            invalidAccountType: this.state.accountType == "",
            invalidAccountNumber: this.state.accountNumber == "",
            invalidCode: this.state.code == "",
          });
        }
      }
    );
  };

  doWithdrawBank = () => {
    this.service.withdrawBank(
      this.state.currency,
      this.state.amount,
      this.state.accountType,
      this.state.accountNumber,
      this.state.bankID,
      this.state.note,
      this.setState.bind(this)
    );
  };

  validateWithdrawCash = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (
          this.state.currency != "" &&
          this.state.amount != "" &&
          this.state.code != ""
        ) {
          if (
            this.state.amount >
            Number(
              this.state.listFiatWithdraw.filter(
                (element) => element.base_currency == this.state.currency
              )[0].available_qty
            )
          ) {
            this.setMessages(
              "*please-adjust-the-entered-amount.-it-exceeds-your-current-holdings"
            );
            this.setState({
              invalidAmount: true,
            });
          } else {
            //Proceed with withdraw
            this.setState(
              {
                invalidCurrency: false,
                invalidAmount: false,

                loadingSent: true,
              },
              () => {
                if (this.state.authChoice == "Phone") {
                  this.service.validatePhone(
                    this.state.code,
                    this.setState.bind(this),
                    this.setMessages.bind(this),
                    this.doWithdrawCash.bind(this)
                  );
                } else {
                  this.service.validateAuth(
                    this.state.code,
                    this.setState.bind(this),
                    this.setMessages.bind(this),
                    this.doWithdrawCash.bind(this)
                  );
                }
              }
            );
          }
        } else {
          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidCurrency: this.state.currency == "",
            invalidAmount: this.state.amount == "",
            invalidCode: this.state.code == "",
          });
        }
      }
    );
  };

  doWithdrawCash = () => {
    this.service.withdrawCash(
      this.state.currency,
      this.state.amount,
      this.state.note,
      this.setState.bind(this)
    );
  };

  validateWithdrawCrypto = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (
          this.state.token != "" &&
          this.state.amount != "" &&
          this.state.network != "" &&
          this.state.walletAddress != "" &&
          this.state.code != ""
        ) {
          if (this.state.amount != "" && this.state.token != "") {
            if (
              this.state.amount >
              this.state.listCryptoWithdraw.filter(
                (element) => element.base_currency == this.state.token
              )[0].available_qty
            ) {
              this.setMessages(
                "*please-adjust-the-entered-amount.-it-exceeds-your-current-holdings"
              );
              this.setState({
                invalidAmount: true,
              });
            } else {
              //Proceed with withdraw

              this.setState(
                {
                  invalidToken: false,
                  invalidAmount: false,
                  invalidNetwork: false,
                  invalidWalletAddress: false,

                  loadingSent: true,
                },
                () => {
                  if (this.state.authChoice == "Phone") {
                    this.service.validatePhone(
                      this.state.code,
                      this.setState.bind(this),
                      this.setMessages.bind(this),
                      this.doWithdrawCrypto.bind(this)
                    );
                  } else {
                    this.service.validateAuth(
                      this.state.code,
                      this.setState.bind(this),
                      this.setMessages.bind(this),
                      this.doWithdrawCrypto.bind(this)
                    );
                  }
                }
              );
            }
          }
        } else {
          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidToken: this.state.token == "",
            invalidAmount: this.state.amount == "",
            invalidNetwork: this.state.network == {},
            invalidWalletAddress: this.state.walletAddress == "",
            invalidCode: this.state.code == "",
          });
        }
      }
    );
  };

  doWithdrawCrypto = () => {
    this.service.withdrawCrypto(
      this.state.walletAddress,
      this.state.token,
      this.state.network,
      this.state.amount,
      this.setState.bind(this)
    );
  };

  handleChangeForCrypto = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (this.state.crypto != value) {
      if (value != "" && value != null) {
        this.setState(
          {
            [name]: value,
          },
          () => {
            this.setState({
              loadingNetworks: true,
              loadingWallet: false,
              qrcodeBase64: "",
            });
            this.service.getNetworks(
              this.state.token,
              this.setState.bind(this)
            );
            //utils.handleChangeForInvalidAmount(event, this.state.listCryptoWithdraw, this.state.amount, this.state.token, this.setMessages.bind(this, "withdraw-error-invalid-amount"), this.setState.bind(this))
            if (this.state.amount != "" && this.state.token != "") {
              if (
                this.state.amount >
                this.state.listCryptoWithdraw.filter(
                  (element) => element.base_currency == this.state.token
                )[0].qty
              ) {
                this.setMessages(
                  "*please-adjust-the-entered-amount.-it-exceeds-your-current-holdings"
                );
                this.setState({
                  invalidAmount: true,
                });
              } else {
                this.setState({
                  invalidAmount: false,
                  errorMessageType: "",
                });
              }
            }
          }
        );
      } else {
        if (value == "") {
          this.setState({
            network: "",
            loadingNetworks: false,
            loadingWallet: false,
            qrcodeBase64: "",
          });
        }

        this.setState({
          [name]: value,
        });
      }
    }
  };

  handChangeForInvalidAmountFiat = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (this.state.amount != "" && this.state.currency != "") {
          //utils.handleChangeForInvalidAmount(event, this.state.listFiatWithdraw, this.state.amount, this.state.currency, this.setMessages.bind(this, "withdraw-error-invalid-amount"), this.setState.bind(this))
          if (
            this.state.amount >
            Number(
              this.state.listFiatWithdraw.filter(
                (element) => element.base_currency == this.state.currency
              )[0].available_qty
            )
          ) {
            this.setMessages(
              "*please-adjust-the-entered-amount.-it-exceeds-your-current-holdings"
            );
            this.setState({
              invalidAmount: true,
            });
          } else {
            this.setState({
              invalidAmount: false,
              errorMessageType: "",
            });
          }
        }
      }
    );
  };

  handChangeForInvalidAmountCrypto = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (this.state.amount != "" && this.state.token != "") {
          if (
            this.state.amount >
            this.state.listCryptoWithdraw.filter(
              (element) => element.base_currency == this.state.token
            )[0].qty
          ) {
            this.setMessages(
              "*please-adjust-the-entered-amount.-it-exceeds-your-current-holdings"
            );
            this.setState({
              invalidAmount: true,
            });
          } else {
            this.setState({
              invalidAmount: false,
              errorMessageType: "",
            });
          }
        }
      }
    );
  };

  confirmPhone = () => {
    this.setState(
      {
        loadingPhone: true,
        code: "",
        invalidCode: false,
        errorMessageType: "",
      },
      () => {
        this.service.confirmPhone(this.setState.bind(this));
      }
    );
  };

  tryToGoBackStage = () => {
    if (this.state.stage == 1) {
      this.goToURL("/dashboard");
    } else {
      this.goBackStage();
    }
  };

  getResultMessage = () => {
    if (this.state.resultSuccess) {
      return this.props.localizedText["you-have-successfully-withdrawn"];
    } else {
      return this.props.localizedText[
        "unfortunately,-there-was-a-problem-with-the-withdraw"
      ];
    }
  };

  render() {
    return (
      <React.Fragment>
        <div class="withdraw-container">
          <div class="withdraw-body">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
                goBack={this.tryToGoBackStage}
              ></GoBackButton>
            </div>
            <div class="middle-container container-padding">
              {this.state.stage == 1 ||
              this.state.stage == 2 ||
              this.state.stage == 3 ||
              this.state.stage == 4 ||
              this.state.stage == 5 ? (
                <div>
                  {" "}
                  <TotalBalance
                    account={this.state.account}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                  ></TotalBalance>
                  <div class="title fade-in-up">
                    {this.props.localizedText["withdraw"]}
                  </div>
                  {this.state.stage == 1 ? (
                    <div>
                      {this.state.loadingCurrenciesWithdraw ||
                      this.state.loadingPaymentProviders ? (
                        <div class="fade-in-up">
                          <Spinner className="loader loading-currencies" />
                        </div>
                      ) : (
                        <div>
                          <div class="section">
                            <div class="options-div">
                              {" "}
                              <div class="sub-title">
                                {
                                  this.props.localizedText[
                                    "*please-choose-a-withdraw-type-from-the-options-available-on-the-right"
                                  ]
                                }
                              </div>
                              <div class="withdraw-list">
                                <div
                                  class="withdraw-row-container"
                                  onClick={this.moveStage.bind(this, 2)}
                                >
                                  <div class="withdraw-row">
                                    <div class="background"> </div>
                                    <div class="content">
                                      <div class="metadata-column">
                                        <RiBankFill class="icon" />
                                        <div class="text">
                                          <div class="option-name">
                                            {" "}
                                            {this.props.localizedText["bank"]}
                                          </div>
                                          <div class="option-description">
                                            {
                                              this.props.localizedText[
                                                "withdraw-funds-directly-into-your-bank-account"
                                              ]
                                            }
                                          </div>
                                        </div>
                                      </div>{" "}
                                      <MdOutlineArrowForwardIos class="arrow"></MdOutlineArrowForwardIos>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="withdraw-row-container"
                                  onClick={this.moveStage.bind(this, 3)}
                                >
                                  <div class="withdraw-row">
                                    <div class="background"> </div>
                                    <div class="content">
                                      <div class="metadata-column">
                                        <SiBitcoinsv class="icon" />
                                        <div class="text">
                                          <div class="option-name">
                                            {" "}
                                            {this.props.localizedText["crypto"]}
                                          </div>
                                          <div class="option-description">
                                            {
                                              this.props.localizedText[
                                                "withdraw-funds-to-your-crypto-wallet"
                                              ]
                                            }
                                          </div>
                                        </div>
                                      </div>{" "}
                                      <MdOutlineArrowForwardIos class="arrow"></MdOutlineArrowForwardIos>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="withdraw-row-container"
                                  onClick={this.moveStage.bind(this, 4)}
                                >
                                  <div class="withdraw-row">
                                    <div class="background"> </div>
                                    <div class="content">
                                      <div class="metadata-column">
                                        <FaMoneyBillWave class="icon" />
                                        <div class="text">
                                          <div class="option-name">
                                            {" "}
                                            {this.props.localizedText["cash"]}
                                          </div>
                                          <div class="option-description">
                                            {
                                              this.props.localizedText[
                                                "withdraw-funds-in-cash"
                                              ]
                                            }
                                          </div>
                                        </div>
                                      </div>{" "}
                                      <MdOutlineArrowForwardIos class="arrow"></MdOutlineArrowForwardIos>
                                    </div>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      )}
                    </div>
                  ) : null}
                  {this.state.stage == 2 ? (
                    <div>
                      {this.state.loadingPaymentProviders ? (
                        <div class="fade-in-up">
                          <Spinner className="loader loading-currencies" />
                        </div>
                      ) : (
                        <div>
                          <ConversionLinkComponent
                            width={this.state.width}
                            message={
                              this.props.localizedText["see-conversion-rate"]
                            }
                            currencyFrom={this.state.currency}
                            currencyTo={"USD"}
                            region={this.props.region}
                            localizedText={this.props.localizedText}
                          />
                          <div class="section">
                            <div class="left-input left-input-no-height">
                              <div class="background"></div>
                              <div class="content">
                                <div class="form-div">
                                  <Form.Group className="mb-3">
                                    <Form.Label
                                      className={"input-label-inside-div"}
                                    >
                                      {this.props.localizedText["currency"]}
                                    </Form.Label>
                                    <InputGroup className="mb-3 input-field">
                                      <Form.Select
                                        variant="outline-secondary"
                                        onChange={
                                          this.handChangeForInvalidAmountFiat
                                        }
                                        name="currency"
                                        isInvalid={this.state.invalidCurrency}
                                        style={{
                                          width: "100%",
                                          flex: "0 1 auto",
                                          textAlign: "start",
                                        }}
                                        disabled={this.state.loadingSent}
                                      >
                                        <option></option>
                                        {this.state.listFiatWithdraw.map(
                                          (currency) => (
                                            <option
                                              value={currency.base_currency}
                                            >
                                              {currency.name +
                                                " (" +
                                                currency.base_currency +
                                                ")" +
                                                " (" +
                                                utils.roundToTwoDecimals(
                                                  currency.available_qty
                                                ) +
                                                ")"}
                                            </option>
                                          )
                                        )}
                                      </Form.Select>
                                    </InputGroup>
                                  </Form.Group>
                                </div>
                                <div class="form-div">
                                  <Form.Group className="mb-3">
                                    <Form.Label className="input-label-inside-div">
                                      {this.props.localizedText["amount"]}
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        className="input-text no-background-input-inside"
                                        type="number"
                                        name="amount"
                                        value={this.state.amount}
                                        isInvalid={this.state.invalidAmount}
                                        onChange={
                                          this.handChangeForInvalidAmountFiat
                                        }
                                        disabled={this.state.loadingSent}
                                      />{" "}
                                      <InputGroup.Text
                                        className={
                                          this.state.invalidAmount
                                            ? "inside-input-symbol input-symbol-danger"
                                            : "inside-input-symbol"
                                        }
                                      >
                                        {this.state.currency}
                                      </InputGroup.Text>
                                    </InputGroup>
                                  </Form.Group>{" "}
                                </div>{" "}
                                <div class="form-div">
                                  <Form.Group className="mb-3">
                                    <Form.Label
                                      className={"input-label-inside-div"}
                                    >
                                      {this.props.localizedText["bank"]}
                                    </Form.Label>
                                    <InputGroup className="mb-3 input-field">
                                      <Form.Select
                                        variant="outline-secondary"
                                        onChange={(e) =>
                                          utils.handleChange(
                                            e,
                                            this.setState.bind(this)
                                          )
                                        }
                                        name="bankID"
                                        isInvalid={this.state.invalidBankID}
                                        style={{
                                          width: "100%",
                                          flex: "0 1 auto",
                                          textAlign: "start",
                                        }}
                                        disabled={this.state.loadingSent}
                                      >
                                        <option></option>
                                        {this.state.listPaymentProviders.map(
                                          (paymentProvider) => (
                                            <option
                                              value={
                                                paymentProvider.provider_id
                                              }
                                            >
                                              {paymentProvider.provider_name}
                                            </option>
                                          )
                                        )}
                                      </Form.Select>
                                    </InputGroup>
                                  </Form.Group>
                                </div>
                                <div class="form-div">
                                  <Form.Group className="mb-3">
                                    <Form.Label className="input-label-inside-div">
                                      {this.props.localizedText["code"]}
                                    </Form.Label>
                                    <FormControl
                                      name="code"
                                      value={this.state.code}
                                      onChange={(e) =>
                                        utils.handleChange(
                                          e,
                                          this.setState.bind(this)
                                        )
                                      }
                                      isInvalid={this.state.invalidCode}
                                      className="input-field"
                                      disabled={this.state.loadingSent}
                                      autoComplete="off"
                                    />
                                  </Form.Group>
                                  {this.state.authChoice == "Phone" ? (
                                    <div class="auth-div">
                                      {
                                        this.props.localizedText[
                                          "a-text-message-with-a-8-digit-verification-code-was-just-sent-to"
                                        ]
                                      }
                                      &nbsp;
                                      {this.state.message + " "}
                                      <b onClick={this.confirmPhone}>
                                        {" "}
                                        {
                                          this.props.localizedText[
                                            "resend-code"
                                          ]
                                        }
                                      </b>
                                    </div>
                                  ) : (
                                    <div class="auth-div">
                                      {
                                        this.props.localizedText[
                                          "please-enter-the-code-from-your-authenticator-app"
                                        ]
                                      }
                                      <br />
                                      <b
                                        onClick={this.goToURL.bind(
                                          this,
                                          "/dashboard/settings"
                                        )}
                                      >
                                        {
                                          this.props.localizedText[
                                            "change-auth-method"
                                          ]
                                        }
                                      </b>
                                    </div>
                                  )}{" "}
                                </div>
                              </div>
                            </div>{" "}
                            <div class="right-div">
                              <div class="value-input">
                                <Form.Group className="mb-3">
                                  <Form.Label
                                    className={"input-label-outside-div"}
                                  >
                                    {this.props.localizedText["account-type"]}
                                  </Form.Label>
                                  <InputGroup className="mb-3 input-field">
                                    <Form.Select
                                      variant="outline-secondary"
                                      onChange={(e) =>
                                        utils.handleChange(
                                          e,
                                          this.setState.bind(this)
                                        )
                                      }
                                      name="accountType"
                                      isInvalid={this.state.invalidAccountType}
                                      style={{
                                        width: "100%",
                                        flex: "0 1 auto",
                                        textAlign: "start",
                                      }}
                                      disabled={this.state.loadingSent}
                                    >
                                      <option></option>
                                      <option value={"SAVINGS"}>
                                        {this.props.localizedText["savings"]}
                                      </option>
                                      <option value={"CHECKING"}>
                                        {this.props.localizedText["checking"]}
                                      </option>
                                    </Form.Select>
                                  </InputGroup>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Label className="input-label-outside-div">
                                    {this.props.localizedText["account-number"]}
                                  </Form.Label>
                                  <FormControl
                                    name="accountNumber"
                                    value={this.state.email}
                                    onChange={(e) =>
                                      utils.handleChange(
                                        e,
                                        this.setState.bind(this)
                                      )
                                    }
                                    isInvalid={this.state.invalidAccountNumber}
                                    className="input-field"
                                    disabled={this.state.loadingSent}
                                  />
                                </Form.Group>
                                <InputGroup className="input-group">
                                  <Form.Label className="input-label-outside-div">
                                    {
                                      this.props.localizedText[
                                        "note-(optional)"
                                      ]
                                    }
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    name="note"
                                    value={this.state.note}
                                    onChange={(e) =>
                                      utils.handleChange(
                                        e,
                                        this.setState.bind(this)
                                      )
                                    }
                                    className="text-area"
                                    disabled={this.state.loadingSent}
                                  />
                                </InputGroup>
                              </div>{" "}
                            </div>
                          </div>{" "}
                          <div>
                            <ErrorMessageComponent
                              errorMessage={
                                this.props.localizedText[
                                  this.state.errorMessageType
                                ]
                              }
                            ></ErrorMessageComponent>
                            <div class="buttons-div fade-in-up">
                              <Button
                                className="primary-button outside-primary-button"
                                onClick={this.validateWithdrawBank}
                                disabled={this.state.loadingSent}
                              >
                                {this.state.loadingSent ? (
                                  <Spinner className="loader-send-outside-div-button"></Spinner>
                                ) : null}
                                {this.props.localizedText["withdraw"]}
                              </Button>

                              <Button
                                className="secondary-button outside-secondary-button"
                                onClick={this.tryToGoBackStage}
                                disabled={this.state.loadingSent}
                              >
                                {this.props.localizedText["cancel"]}
                              </Button>
                            </div>
                          </div>{" "}
                        </div>
                      )}
                    </div>
                  ) : null}{" "}
                  {this.state.stage == 3 ? (
                    <div>
                      {this.state.loadingCurrenciesWithdraw ? (
                        <div class="fade-in-up">
                          <Spinner className="loader loading-currencies" />
                        </div>
                      ) : (
                        <div>
                          <ConversionLinkComponent
                            width={this.state.width}
                            message={
                              this.props.localizedText["see-conversion-rate"]
                            }
                            currencyFrom={this.state.token}
                            currencyTo={"USD"}
                            region={this.props.region}
                            localizedText={this.props.localizedText}
                          />
                          <div class="section">
                            <div class="left-input left-input-no-height">
                              <div class="background"></div>
                              <div class="content">
                                <div class="form-div">
                                  <Form.Group className="mb-3">
                                    <Form.Label
                                      className={"input-label-inside-div"}
                                    >
                                      {this.props.localizedText["token"]}
                                    </Form.Label>
                                    <InputGroup className="mb-3 input-field">
                                      <Form.Select
                                        variant="outline-secondary"
                                        onChange={(e) =>
                                          this.handleChangeForCrypto(e)
                                        }
                                        name="token"
                                        isInvalid={this.state.invalidToken}
                                        style={{
                                          width: "100%",
                                          flex: "0 1 auto",
                                          textAlign: "start",
                                        }}
                                        disabled={this.state.loadingSent}
                                      >
                                        <option></option>
                                        {this.state.listCryptoWithdraw.map(
                                          (currency) => (
                                            <option
                                              value={currency.base_currency}
                                            >
                                              {currency.base_currency +
                                                " (" +
                                                utils.roundToTwoDecimals(
                                                  currency.qty
                                                ) +
                                                ")"}
                                            </option>
                                          )
                                        )}
                                      </Form.Select>
                                    </InputGroup>
                                  </Form.Group>
                                </div>
                                <div class="form-div">
                                  <Form.Group className="mb-3">
                                    <Form.Label className="input-label-inside-div">
                                      {this.props.localizedText["amount"]}
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        className="input-text no-background-input-inside"
                                        type="number"
                                        name="amount"
                                        value={this.state.amount}
                                        isInvalid={this.state.invalidAmount}
                                        onChange={
                                          this.handChangeForInvalidAmountCrypto
                                        }
                                        disabled={this.state.loadingSent}
                                      />{" "}
                                      <InputGroup.Text
                                        className={
                                          this.state.invalidAmount
                                            ? "inside-input-symbol input-symbol-danger"
                                            : "inside-input-symbol"
                                        }
                                      >
                                        {this.state.token}
                                      </InputGroup.Text>
                                    </InputGroup>
                                  </Form.Group>{" "}
                                </div>{" "}
                                <div class="form-div">
                                  <Form.Group className="mb-3">
                                    <Form.Label className="input-label-inside-div">
                                      {this.props.localizedText["code"]}
                                    </Form.Label>
                                    <FormControl
                                      name="code"
                                      value={this.state.code}
                                      onChange={(e) =>
                                        utils.handleChange(
                                          e,
                                          this.setState.bind(this)
                                        )
                                      }
                                      isInvalid={this.state.invalidCode}
                                      className="input-field"
                                      disabled={this.state.loadingSent}
                                      autoComplete="off"
                                    />
                                  </Form.Group>
                                  {this.state.authChoice == "Phone" ? (
                                    <div class="auth-div">
                                      {
                                        this.props.localizedText[
                                          "a-text-message-with-a-8-digit-verification-code-was-just-sent-to"
                                        ]
                                      }
                                      &nbsp;
                                      {this.state.message + " "}
                                      <b onClick={this.confirmPhone}>
                                        {" "}
                                        {
                                          this.props.localizedText[
                                            "resend-code"
                                          ]
                                        }
                                      </b>
                                    </div>
                                  ) : (
                                    <div class="auth-div">
                                      {
                                        this.props.localizedText[
                                          "please-enter-the-code-from-your-authenticator-app"
                                        ]
                                      }
                                      <br />
                                      <b
                                        onClick={this.goToURL.bind(
                                          this,
                                          "/dashboard/settings"
                                        )}
                                      >
                                        {
                                          this.props.localizedText[
                                            "change-auth-method"
                                          ]
                                        }
                                      </b>
                                    </div>
                                  )}{" "}
                                </div>
                              </div>
                            </div>{" "}
                            <div class="right-div">
                              <div class="value-input">
                                {" "}
                                {this.state.token != "" ? (
                                  <div>
                                    {this.state.loadingNetworks ? (
                                      <div class="fade-in-up">
                                        <Spinner className="loading-conversions" />
                                      </div>
                                    ) : (
                                      <div>
                                        <Form.Group className="mb-3 fade-in-up">
                                          <Form.Label
                                            className={
                                              "input-label-outside-div"
                                            }
                                          >
                                            {
                                              this.props.localizedText[
                                                "network"
                                              ]
                                            }
                                          </Form.Label>
                                          <InputGroup className="mb-3 input-field">
                                            <Form.Select
                                              variant="outline-secondary"
                                              onChange={(e) =>
                                                utils.handleChange(
                                                  e,
                                                  this.setState.bind(this)
                                                )
                                              }
                                              name="network"
                                              style={{
                                                width: "100%",
                                                flex: "0 1 auto",
                                                textAlign: "start",
                                              }}
                                              disabled={
                                                this.state.loadingSent ||
                                                this.state.listNetworks
                                                  .length <= 1
                                              }
                                            >
                                              {this.state.listNetworks.length ==
                                              1 ? (
                                                <option>
                                                  {
                                                    this.state.listNetworks[0]
                                                      .description
                                                  }
                                                </option>
                                              ) : null}
                                              <option></option>
                                              {this.state.listNetworks.map(
                                                (network) => (
                                                  <option
                                                    value={network.blockchain}
                                                  >
                                                    {network.description}
                                                  </option>
                                                )
                                              )}
                                            </Form.Select>
                                          </InputGroup>
                                        </Form.Group>

                                        <Form.Group className="mb-3 fade-in-up">
                                          <Form.Label className="input-label-outside-div">
                                            {
                                              this.props.localizedText[
                                                "wallet-address"
                                              ]
                                            }
                                          </Form.Label>
                                          <FormControl
                                            name="walletAddress"
                                            value={this.state.walletAddress}
                                            onChange={(e) =>
                                              utils.handleChange(
                                                e,
                                                this.setState.bind(this)
                                              )
                                            }
                                            isInvalid={
                                              this.state.invalidWalletAddress
                                            }
                                            className="input-field"
                                            disabled={this.state.loadingSent}
                                          />
                                        </Form.Group>
                                      </div>
                                    )}
                                  </div>
                                ) : null}
                              </div>{" "}
                            </div>
                          </div>{" "}
                          <div>
                            <ErrorMessageComponent
                              errorMessage={
                                this.props.localizedText[
                                  this.state.errorMessageType
                                ]
                              }
                            ></ErrorMessageComponent>
                            <div class="buttons-div fade-in-up">
                              <Button
                                className="primary-button outside-primary-button"
                                onClick={this.validateWithdrawCrypto}
                                disabled={this.state.loadingSent}
                              >
                                {this.state.loadingSent ? (
                                  <Spinner className="loader-send-outside-div-button"></Spinner>
                                ) : null}
                                {this.props.localizedText["withdraw"]}
                              </Button>

                              <Button
                                className="secondary-button outside-secondary-button"
                                onClick={this.tryToGoBackStage}
                                disabled={this.state.loadingSent}
                              >
                                {this.props.localizedText["cancel"]}
                              </Button>
                            </div>
                          </div>{" "}
                        </div>
                      )}
                    </div>
                  ) : null}{" "}
                  {this.state.stage == 4 ? (
                    <div>
                      {this.state.loadingPaymentProviders ? (
                        <div class="fade-in-up">
                          <Spinner className="loader loading-currencies" />
                        </div>
                      ) : (
                        <div>
                          <ConversionLinkComponent
                            width={this.state.width}
                            message={
                              this.props.localizedText["see-conversion-rate"]
                            }
                            currencyFrom={this.state.currency}
                            currencyTo={"USD"}
                            region={this.props.region}
                            localizedText={this.props.localizedText}
                          />
                          <div class="section">
                            <div class="left-input left-input-no-height">
                              <div class="background"></div>
                              <div class="content">
                                <div class="form-div">
                                  <Form.Group className="mb-3">
                                    <Form.Label
                                      className={"input-label-inside-div"}
                                    >
                                      {this.props.localizedText["currency"]}
                                    </Form.Label>
                                    <InputGroup className="mb-3 input-field">
                                      <Form.Select
                                        variant="outline-secondary"
                                        onChange={
                                          this.handChangeForInvalidAmountFiat
                                        }
                                        name="currency"
                                        isInvalid={this.state.invalidCurrency}
                                        style={{
                                          width: "100%",
                                          flex: "0 1 auto",
                                          textAlign: "start",
                                        }}
                                        disabled={this.state.loadingSent}
                                      >
                                        <option></option>
                                        {this.state.listFiatWithdraw.map(
                                          (currency) => (
                                            <option
                                              value={currency.base_currency}
                                            >
                                              {currency.name +
                                                " (" +
                                                currency.base_currency +
                                                ")" +
                                                " (" +
                                                utils.roundToTwoDecimals(
                                                  currency.available_qty
                                                ) +
                                                ")"}
                                            </option>
                                          )
                                        )}
                                      </Form.Select>
                                    </InputGroup>
                                  </Form.Group>
                                </div>
                                <div class="form-div">
                                  <Form.Group className="mb-3">
                                    <Form.Label className="input-label-inside-div">
                                      {this.props.localizedText["amount"]}
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        className="input-text no-background-input-inside"
                                        type="number"
                                        name="amount"
                                        value={this.state.amount}
                                        isInvalid={this.state.invalidAmount}
                                        onChange={
                                          this.handChangeForInvalidAmountFiat
                                        }
                                        disabled={this.state.loadingSent}
                                      />{" "}
                                      <InputGroup.Text
                                        className={
                                          this.state.invalidAmount
                                            ? "inside-input-symbol input-symbol-danger"
                                            : "inside-input-symbol"
                                        }
                                      >
                                        {this.state.currency}
                                      </InputGroup.Text>
                                    </InputGroup>
                                  </Form.Group>{" "}
                                </div>{" "}
                                <div class="form-div">
                                  <Form.Group className="mb-3">
                                    <Form.Label className="input-label-inside-div">
                                      {this.props.localizedText["code"]}
                                    </Form.Label>
                                    <FormControl
                                      name="code"
                                      value={this.state.code}
                                      onChange={(e) =>
                                        utils.handleChange(
                                          e,
                                          this.setState.bind(this)
                                        )
                                      }
                                      isInvalid={this.state.invalidCode}
                                      className="input-field"
                                      disabled={this.state.loadingSent}
                                      autoComplete="off"
                                    />
                                  </Form.Group>
                                  {this.state.authChoice == "Phone" ? (
                                    <div class="auth-div">
                                      {
                                        this.props.localizedText[
                                          "a-text-message-with-a-8-digit-verification-code-was-just-sent-to"
                                        ]
                                      }
                                      &nbsp;
                                      {this.state.message + " "}
                                      <b onClick={this.confirmPhone}>
                                        {" "}
                                        {
                                          this.props.localizedText[
                                            "resend-code"
                                          ]
                                        }
                                      </b>
                                    </div>
                                  ) : (
                                    <div class="auth-div">
                                      {
                                        this.props.localizedText[
                                          "please-enter-the-code-from-your-authenticator-app"
                                        ]
                                      }
                                      <br />
                                      <b
                                        onClick={this.goToURL.bind(
                                          this,
                                          "/dashboard/settings"
                                        )}
                                      >
                                        {
                                          this.props.localizedText[
                                            "change-auth-method"
                                          ]
                                        }
                                      </b>
                                    </div>
                                  )}{" "}
                                </div>
                              </div>
                            </div>{" "}
                            <div class="right-div">
                              <div class="value-input">
                                <InputGroup className="input-group">
                                  <Form.Label className="input-label-outside-div">
                                    {
                                      this.props.localizedText[
                                        "note-(optional)"
                                      ]
                                    }
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    name="note"
                                    value={this.state.note}
                                    onChange={(e) =>
                                      utils.handleChange(
                                        e,
                                        this.setState.bind(this)
                                      )
                                    }
                                    className="text-area"
                                    disabled={this.state.loadingSent}
                                  />
                                </InputGroup>
                              </div>{" "}
                            </div>
                          </div>{" "}
                          <div>
                            <ErrorMessageComponent
                              errorMessage={
                                this.props.localizedText[
                                  this.state.errorMessageType
                                ]
                              }
                            ></ErrorMessageComponent>
                            <div class="buttons-div fade-in-up">
                              <Button
                                className="primary-button outside-primary-button"
                                onClick={this.validateWithdrawCash}
                                disabled={this.state.loadingSent}
                              >
                                {this.state.loadingSent ? (
                                  <Spinner className="loader-send-outside-div-button"></Spinner>
                                ) : null}
                                {this.props.localizedText["withdraw"]}
                              </Button>

                              <Button
                                className="secondary-button outside-secondary-button"
                                onClick={this.tryToGoBackStage}
                                disabled={this.state.loadingSent}
                              >
                                {this.props.localizedText["cancel"]}
                              </Button>
                            </div>
                          </div>{" "}
                        </div>
                      )}
                    </div>
                  ) : null}{" "}
                  {this.state.stage == 5 ? (
                    <div class="withdraw-cash-result-div">
                      <MdCheckCircle className="withdraw-cash-result-icon"></MdCheckCircle>
                      <span class="withdraw-cash-result-title">
                        {this.props.localizedText["good-news!"]}
                      </span>

                      <div class="withdraw-cash-result-data-div">
                        <div class="withdraw-cash-result-data-row">
                          {" "}
                          <div class="sub-title">
                            {this.props.localizedText["otp"]}
                          </div>{" "}
                          <div>{this.state.withdrawCashResult.otp}</div>{" "}
                        </div>
                        <div class="withdraw-cash-result-data-row">
                          {" "}
                          <div class="sub-title">
                            {this.props.localizedText["contract-number"]}
                          </div>{" "}
                          <div>
                            {this.state.withdrawCashResult.contract_number}
                          </div>{" "}
                        </div>
                        <div class="withdraw-cash-result-data-row">
                          {" "}
                          <div class="sub-title">
                            {this.props.localizedText["amount-in-usd"]}
                          </div>{" "}
                          <div>{this.state.withdrawCashResult.usd_amount}</div>{" "}
                        </div>
                        <div class="withdraw-cash-result-data-row">
                          {" "}
                          <div class="sub-title">
                            {this.props.localizedText["operation"]}
                          </div>{" "}
                          <div>{this.state.withdrawCashResult.operation}</div>{" "}
                        </div>
                      </div>

                      <div class="buttons-div">
                        <Button
                          className="continue-button outside-primary-button"
                          onClick={this.goToURL.bind(this, "/dashboard")}
                        >
                          {this.props.localizedText["continue"]}
                        </Button>
                      </div>
                    </div>
                  ) : null}{" "}
                </div>
              ) : (
                <Result
                  success={this.state.resultSuccess}
                  secondButton={false}
                  title={
                    this.props.localizedText[
                      utils.getResultTitle(this.state.resultSuccess)
                    ]
                  }
                  message={this.getResultMessage()}
                  primaryButtonText={this.props.localizedText["continue"]}
                  primaryButtonFunction={this.goToURL.bind(this, "/dashboard")}
                ></Result>
              )}
            </div>
            <div class="side-container ">
              {this.state.stage == 2 && this.state.currency ? (
                <ConversionComponent
                  currencyFrom={this.state.currency}
                  currencyTo={"USD"}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                ></ConversionComponent>
              ) : null}
              {this.state.stage == 3 && this.state.token ? (
                <ConversionComponent
                  currencyFrom={this.state.token}
                  currencyTo={"USD"}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                ></ConversionComponent>
              ) : null}
              {this.state.stage == 4 && this.state.currency ? (
                <ConversionComponent
                  currencyFrom={this.state.currency}
                  currencyTo={"USD"}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                ></ConversionComponent>
              ) : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Withdraw);
