import { portfolioAPI } from "../../apis/portfolioAPI";
import { depositAPI } from "../../apis/depositAPI";
import { refdataAPI } from "../../apis/refdataAPI";
import { marketDataAPI } from "../../apis/marketDataAPI";
import { walletAPI } from "../../apis/walletAPI";
import { utils } from "../../utils/utils.jsx";
import { swapAPI } from "../../apis/swapAPI.jsx";

export default class ConversionService {
  getCurrencies(setState) {
    refdataAPI
      .getCurrenciesDeposit()
      .then((response) => {
        console.log("getCurrenciesDeposit", response);
        if (response.status === 200 && response.data) {
          setState({
            listCurrencies: response.data,
            loadingCurrencies: false,
          });
        } else {
          setState({
            loadingCurrencies: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        setState({
          loadingCurrencies: false,
        });
      });
  }

  getLockedRates(setState) {
    swapAPI
      .getLockedRates()
      .then((response) => {
        console.log("getLockedRates", response);
        if (response.status === 200 && response.data) {
          const listOrdered = response.data.sort(
            (a, b) => new Date(b.created_on) - new Date(a.created_on)
          );
          setState({
            listLockedRates: listOrdered,
            listLockedRatesShowed: listOrdered,
            loadingLockedRates: false,
          });
        } else {
          console.log("No data");
          setState({
            loadingLockedRates: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("getLockedRates error");
        setState({
          loadingLockedRates: false,
        });
      });
  }

  getNetworks(crypto, setState) {
    walletAPI
      .getNetworks(crypto)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getNetworks success", response.data);
          setState(
            {
              listNetworks: response.data,
              loadingNetworks: false,
            },
            () => {
              if (response.data.length == 1) {
                this.getWallet(crypto, response.data[0].blockchain, setState);
                setState({
                  network: response.data[0].description,
                });
              }
            }
          );
        } else {
          console.log("getNetworks failure", response.data);
          setState({
            loadingNetworks: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("getNetworks error", error);
        setState({
          loadingNetworks: false,
        });
      });
  }

  getWallet(crypto, network, setState) {
    console.log("getWallet being called");
    if (process.env.REACT_APP_ENV != "prod") {
      setState({
        qrcodeBase64:
          "iVBORw0KGgoAAAANSUhEUgAAAZAAAAGQAQAAAACoxAthAAAB70lEQVR4Xu2XO5LDMAxDtZWPoaNaR9UxUkUrgPrYyczusAwDFLZF4rHCUElqbqXXwv8S4pUQr4R4JcQrIV4J8UqIV0K8EgI909DRD/V4prPyq1dOPKhTSFTEzstdUvrBkbWbRcjtHASpiMSl/UBC8MCYniQhX4CMdkJgSmZDyNcgDcZd484QEh7hm+386HB79MDQeFkhQoIiQ+a5P2ZPSFDkqoJ7otGN1LxJyKs+HRmBsXBkbArsDOZnHhcsJCBCT257U6AOt81ifoSERNhmYLgpuDNoxO/G1RASE0EabCEwF+tB98GBQgIj+zeiXREmC8zcGUJiIuhMeHQoC8yMjtWEmOIgo7izcq7b4ZoVSggUC0nZOK4GuHE7VNgzZmFnCImLwFjw6hw8cwzdJR2TEBIQGXdCw37AV4WnIjpsrK6QkIjth4MxoRs3Bo5I0lVCoHBIgpsIw/FSExId6QkZ62JofrEhJCiyRXjtB4zZA60m5K7PR24JyahUxITGytS8BUZIIOTkux5YF+ZmatjAGMBCwiIVRoTDPPh5WJgfThgNIeGRav8Lujvzwej8HRghURBeDCXZsWGF7DFCgiJ8s23hQEw4BseRGiFBkaEVGHhKXmMYHSExEY+EeCXEKyFeCfFKiFdCvBLilRCvhHj1C0pSfLXc630IAAAAAElFTkSuQmCC",
        inboundAddress: "0xcba359c887276f36ac14a58aa7876d6a6d4df3ca",
        loadingWallet: false,
      });
    } else {
      walletAPI
        .getWallet(crypto, network)
        .then((response) => {
          console.log("getWallet", response.data);
          if (response.status === 200 && response.data) {
            setState({
              qrcodeBase64: response.data.qr_code,
              inboundAddress: response.data.inbound_address,
              loadingWallet: false,
            });
          } else {
            setState({
              loadingWallet: false,
            });
          }
        })
        .catch((error) => {
          //Failure
          setState({
            loadingWallet: false,
          });
        });
    }
  }

  sendWithLockedRate(locked_rate_id, txhash, setState) {
    swapAPI
      .sendWithLockedRate(locked_rate_id, txhash)
      .then((response) => {
        console.log("sendWithRateLocked", response);
        if (response.status === 200 && response.data) {
          setState({
            selectedDivIndex: null,
            showModalSend: false,
            loadingSent: false,
            resultSuccess: true,
            resultType: "send-with-locked-rate",
            stage: 2,
          });
        } else {
          setState({
            selectedDivIndex: null,
            showModalSend: false,
            loadingSent: false,
            resultSuccess: false,
            resultType: "send-with-locked-rate",
            stage: 2,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("sendWithRateLocked error");
        setState({
          selectedDivIndex: null,
          showModalSend: false,
          loadingSent: false,
          resultSuccess: false,
          resultType: "send-with-locked-rate",
          stage: 2,
        });
      });
  }

  convertWithLockedRate(locked_rate_id, setState) {
    swapAPI
      .convertWithLockedRate(locked_rate_id)
      .then((response) => {
        console.log("convertWithLockedRate", response);
        if (response.status === 200 && response.data) {
          setState({
            selectedDivIndex: null,
            showModalConvert: false,
            loadingSent: false,
            resultSuccess: true,
            resultType: "convert-with-locked-rate",
            stage: 2,
          });
        } else {
          setState({
            selectedDivIndex: null,
            showModalConvert: false,
            loadingSent: false,
            resultSuccess: false,
            resultType: "convert-with-locked-rate",
            stage: 2,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("convertWithLockedRate error");
        setState({
          selectedDivIndex: null,
          showModalConvert: false,
          loadingSent: false,
          resultSuccess: false,
          resultType: "convert-with-locked-rate",
          stage: 2,
        });
      });
  }

  cancelLockedRate(locked_rate_id, setState) {
    swapAPI
      .cancelLockedRate(locked_rate_id)
      .then((response) => {
        console.log("cancelLockedRate", response);
        if (response.status === 200 && response.data) {
          setState({
            selectedDivIndex: null,
            showModalCancel: false,
            loadingSent: false,
            resultSuccess: true,
            resultType: "cancel-locked-rate",
            stage: 2,
          });
        } else {
          setState({
            selectedDivIndex: null,
            showModalCancel: false,
            loadingSent: false,
            resultSuccess: false,
            resultType: "cancel-locked-rate",
            stage: 2,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("cancelLockedRate error");
        setState({
          selectedDivIndex: null,
          showModalCancel: false,
          loadingSent: false,
          resultSuccess: false,
          resultType: "cancel-locked-rate",
          stage: 2,
        });
      });
  }

  getMockLockedRates() {
    return '[ { "locked_rate_id": "2280e7ec-5767-4aca-bdb3-f97cc1a90de1", "amount": 2, "from_currency": "USDT", "to_currency": "USD", "desired_lock_time_in_secs": 120, "expires_on": "2024-04-28T20:47:56.170462+00:00", "status": "CREATED", "created_on": "2024-04-28T20:45:56.170465+00:00", "locked_rate": 1.97 }, { "locked_rate_id": "9d457c7d-2425-4946-b2e8-917290d3c552", "amount": 20, "from_currency": "USDT", "to_currency": "USD", "desired_lock_time_in_secs": 1800, "expires_on": "2024-04-28T21:37:59.995133+00:00", "status": "CREATED", "created_on": "2024-04-28T21:07:59.995137+00:00", "locked_rate": 16.83 } ]';
  }
}
