import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./footer.css";
import {
  RiFacebookBoxLine,
  RiInstagramLine,
  RiLinkedinBoxLine,
  RiTwitterFill,
} from "react-icons/ri";
import { SiTiktok } from "react-icons/si";

class Footer extends Component {
  state = {
    name: null,
    account: null,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
    }
  }

  render() {
    return (
      <React.Fragment>
        <div class="layout-footer">
          <div class="top-row">
            <div class="logo-div">
              <img
                class="logo"
                src={"/themes/" + this.props.theme + "/logo.png"}
              ></img>
              <div class="title"> {process.env.REACT_APP_COMPANY_NAME}</div>
            </div>
            {process.env.REACT_APP_FB_URL != "" ||
            process.env.REACT_APP_IG_URL != "" ||
            process.env.REACT_APP_LK_URL != "" ||
            process.env.REACT_APP_TK_URL != "" ||
            process.env.REACT_APP_TWT_URL != "" ? (
              <div class="follow_us_at">
                <div class="title">
                  {" "}
                  {this.props.localizedText["follow-us-at"]}
                </div>
                <div class="icons">
                  {process.env.REACT_APP_FB_URL != "" ? (
                    <a href={process.env.REACT_APP_FB_URL}>
                      <RiFacebookBoxLine></RiFacebookBoxLine>
                    </a>
                  ) : null}{" "}
                  {process.env.REACT_APP_IG_URL != "" ? (
                    <a href={process.env.REACT_APP_IG_URL}>
                      <RiInstagramLine></RiInstagramLine>
                    </a>
                  ) : null}{" "}
                  {process.env.REACT_APP_LK_URL != "" ? (
                    <a href={process.env.REACT_APP_LK_URL}>
                      <RiLinkedinBoxLine></RiLinkedinBoxLine>
                    </a>
                  ) : null}{" "}
                  {process.env.REACT_APP_TK_URL != "" ? (
                    <a href={process.env.REACT_APP_TK_URL} className="tiktok">
                      <SiTiktok></SiTiktok>
                    </a>
                  ) : null}{" "}
                  {process.env.REACT_APP_TWT_URL != "" ? (
                    <a href={process.env.REACT_APP_TWT_URL}>
                      <RiTwitterFill></RiTwitterFill>
                    </a>
                  ) : null}
                </div>
              </div>
            ) : null}
            <div class="go_to">
              <div class="title">{this.props.localizedText["go-to"]}</div>
              <div class="links-div">
                <a href="https://bloxcross.com/#">
                  {this.props.localizedText["home"]}
                </a>
                <a href="https://bloxcross.com/#benefits">
                  {this.props.localizedText["benefits"]}
                </a>
                <a href="https://bloxcross.com/#about_us">
                  {this.props.localizedText["about-us"]}
                </a>
                <a href="https://bloxcross.com/#contact">
                  {this.props.localizedText["contact"]}
                </a>
              </div>
            </div>
            <div class="terms">
              <a href="https://app.termly.io/user/policy-not-found">
                {this.props.localizedText["terms-and-condition"]}
              </a>
              <a href="https://bloxcross.com/privacy_policy_en.pdf">
                {this.props.localizedText["privacy-policy"]}
              </a>
            </div>
          </div>
          <div class="bottom-row">
            {this.props.localizedText["footer-bottom-top-text"] +
              process.env.REACT_APP_COMPANY_NAME +
              this.props.localizedText["footer-bottom-middle-text"] +
              process.env.REACT_APP_COMPANY_WEBSITE +
              this.props.localizedText["footer-bottom-bottom-text"]}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Footer);
