import { loginAPI } from "../../apis/loginAPI";

export default class LoginService {
  forgotPassword(email, setState, setMessages, goToDeleteAccountResultPage) {
    loginAPI
      .forgotPassword(email)
      .then((response) => {
        if (response.status === 200) {
          //Success
          goToDeleteAccountResultPage(true);
        } else {
          //Failure
          setMessages("*sorry,-we-couldn't-find-the-email");
          setState({
            invalidEmail: true,
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        setMessages("*sorry,-we-couldn't-find-the-email");
        setState({
          invalidEmail: true,
          loadingSent: false,
        });
      });
  }
}
