import { loginAPI } from "../../../apis/loginAPI";
import { settingsAPI } from "../../../apis/settingsAPI";

export default class SettingsService {
  getClientConfig(setState) {
    loginAPI
      .getClientConfig()
      .then((response) => {
        if (response.status === 200 && response.data) {
          setState({
            loading: false,
            firstName: response.data.fname,
            lastName: response.data.lname,
            email: response.data.email,
            phone: response.data.phone,
            newFirstName: response.data.fname,
            newLastName: response.data.lname,
            newEmail: response.data.email,
            newPhone: response.data.phone,
            authChoice:
              response.data.auth_choice == "auth_pending" ||
              response.data.auth_choice == "auth_declined"
                ? "Phone"
                : "Google Auth",
            phoneVerified: response.data.phone_validated,
            emailVerified: response.data.email_validated,
            idVerified: response.data.id_verified,
          });
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
      });
  }

  alwasyOn2FA(setState) {
    settingsAPI
      .alwasyOn2FA()
      .then((response) => {
        console.log(response);
        if (response.status === 200 && response.data.qrcode) {
          setState({
            qrcodeBase64: response.data.qrcode,
            authKey: response.data.key,
            authChoice: "Google Auth",
            showGoogleAuthModal: true,
            loadingSentAuthChange: false,
            authKeyCopied: false,
          });
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
      });
  }

  decline2FA(setState) {
    settingsAPI
      .decline2FA()
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setState({
            authChoice: "Phone",
            loadingSentAuthChange: false,
          });
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
      });
  }

  changeName(state, setState) {
    settingsAPI
      .changeName(state.newFirstName, state.newLastName)
      .then((response) => {
        console.log(response);
        var aux = state.changeInfoResults;
        if (response.status === 200) {
          aux.push(true);
          setState({
            firstName: state.newFirstName,
            lastName: state.newLastName,
            finishedNumber: state.finishedNumber + 1,
            changeInfoResults: aux,
          });
        } else {
          //Failure
          aux.push(false);
          setState({
            finishedNumber: state.finishedNumber + 1,
            changeInfoResults: aux,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        var aux = state.changeInfoResults;
        aux.push(false);
        setState({
          finishedNumber: state.finishedNumber + 1,
          changeInfoResults: aux,
        });
      });
  }

  confirmPhone(setState) {
    loginAPI
      .confirmPhone()
      .then((response) => {
        if (response.status === 200) {
          //Success
          var mySubString = response.data.payload.substring(
            response.data.payload.indexOf("[") + 1,
            response.data.payload.lastIndexOf("]]")
          );

          setState(
            {
              confirmPhoneMessage:
                "xxx-xxx-" +
                mySubString.slice(mySubString.length - 3, mySubString.length),
            },
            () => {
              setState({ loadingPhone: false });
            }
          );
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
      });
  }

  deleteAccount(code, goToResultPage) {
    settingsAPI
      .deleteAccount(code)
      .then((response) => {
        if (response.status === 200) {
          goToResultPage(true);
        } else {
          //Failure
          goToResultPage(false);
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        goToResultPage(false);
      });
  }

  deactivateAccount(code, goToResultPage) {
    settingsAPI
      .deactivateAccount(code)
      .then((response) => {
        if (response.status === 200) {
          goToResultPage(true);
        } else {
          //Failure
          goToResultPage(false);
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        goToResultPage(false);
      });
  }
}
