import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./depositCreditCard.css";
import DepositCreditCardService from "./depositCreditCardService.jsx";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { utils } from "../../../utils/utils.jsx";
import ConversionLinkComponent from "../../conversionLink/conversionLink.jsx";
import { Spinner } from "react-bootstrap";
import FormControl from "react-bootstrap/FormControl";
import ErrorMessageComponent from "../../errorMessage/errorMessage.jsx";

class DepositCreditCard extends Component {
  state = {
    width: window.innerWidth,

    listNetworks: [],

    errorMessageType: "",

    crypto: "",
    network: "",
    amount: "",
    cryptoAmountToReceive: "",
    cryptoFiatFee: "",
    cryptoFiatAmount: "",
    payerEmail: "",

    depositURL: "",

    loadingConversion: false,
    loadingNetworks: false,
    loadingSent: false,

    successfullyPaymentEmailSent: false,

    invalidCrypto: false,
    invalidNetwork: false,
    invalidAmount: false,
    invalidPayerEmail: false,

    resultSuccess: true,
  };

  service = new DepositCreditCardService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      this.props.tryToGoBackStage
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        this.props.tryToGoBackStage
      )
    );
    if (this.props.account && this.props.account.username) {
      this.setState({
        payerEmail: this.props.account.username,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {}

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  handleChangeForCryptoCreditCard = (event, setState) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setState(
      {
        crypto: value,
        successfullyPaymentEmailSent: false,
      },
      () => {
        this.props.setCrypto(this.state.crypto);
        this.setState({
          loadingNetworks: true,
        });
        this.service.getNetworks(this.state.crypto, this.setState.bind(this));
      }
    );
  };

  handleChangeForAmountInUSD = (event, setState) => {
    const target = event.target;
    const value = target.value;

    setState({
      amount: value,
      successfullyPaymentEmailSent: false,
    });

    if (value.length > 1) {
      this.setState(
        {
          loadingConversion: true,
        },
        () => {
          this.service.getQuote(
            "USD",
            value,
            this.state.crypto.toLowerCase(),
            this.state.network,
            this.setState.bind(this),
            this.setMessages.bind(this)
          );
        }
      );
    }
  };

  handleChangePayerEmail = (event, setState) => {
    const target = event.target;
    const value = target.value;

    setState({
      payerEmail: value,
      successfullyPaymentEmailSent: false,
    });
  };

  handleCheckout = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (
          this.state.crypto != "" &&
          this.state.payerEmail != "" &&
          this.state.network != "" &&
          this.state.amount != ""
        ) {
          this.setState(
            {
              invalidCrypto: false,
              invalidPayerEmail: false,
              invalidNetwork: false,
              invalidAmount: false,

              loadingSent: true,
            },
            () => {
              this.service.checkoutUrl(
                "shift4",
                "USD",
                this.state.amount,
                this.state.crypto.toLowerCase(),
                this.state.network,
                this.state.payerEmail,
                this.setState.bind(this),
                this.setMessages.bind(this),
                this.props.goToResultStage.bind(this)
              );
            }
          );
        } else {
          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidCrypto: this.state.crypto == "",
            invalidPayerEmail: this.state.payerEmail == "",
            invalidNetwork: this.state.network == "",
            invalidAmount: this.state.amount == "",
          });
        }
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <div class="deposit-credit-card-div">
          {" "}
          <ConversionLinkComponent
            width={this.state.width}
            currencyFrom={this.state.currency}
            currencyTo={"USD"}
            region={this.props.region}
            localizedText={this.props.localizedText}
          />
          <div class="section">
            <div class="left-input">
              <div class="background"></div>
              <div class="content">
                <div class="form-div">
                  <Form.Group className="mb-3">
                    <Form.Label className={"input-label-inside-div"}>
                      {
                        this.props.localizedText[
                          "which-coin-would-you-like-to-deposit-in?"
                        ]
                      }
                    </Form.Label>
                    <InputGroup className="mb-3 input-field">
                      <Form.Select
                        variant="outline-secondary"
                        onChange={(e) =>
                          this.handleChangeForCryptoCreditCard(
                            e,
                            this.setState.bind(this)
                          )
                        }
                        name="crypto"
                        style={{
                          width: "100%",
                          flex: "0 1 auto",
                          textAlign: "start",
                        }}
                        isInvalid={this.state.invalidCrypto}
                        disabled={this.state.loadingSent}
                      >
                        <option></option>
                        {this.props.listCryptos.map((crypto) => (
                          <option value={crypto.base_currency}>
                            {crypto.base_currency + " (" + crypto.name + ")"}
                          </option>
                        ))}
                      </Form.Select>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className="input-label-inside-div">
                      {this.props.localizedText["email"]}
                    </Form.Label>
                    <FormControl
                      name="payerEmail"
                      value={this.state.payerEmail}
                      onChange={(e) =>
                        utils.handleChange(e, this.setState.bind(this))
                      }
                      isInvalid={this.state.invalidPayerEmail}
                      className="input-field"
                      disabled={this.state.loadingSent}
                      autoComplete="off"
                    />
                  </Form.Group>
                </div>{" "}
                <div class="message-div">
                  {
                    this.props.localizedText[
                      "*a-pay-link-will-be-sent-to-the-email"
                    ]
                  }
                </div>
              </div>
            </div>{" "}
            <div class="right-div">
              <div class="value-input">
                {this.state.crypto != "" ? (
                  <div>
                    {this.state.loadingNetworks ? (
                      <div class="fade-in-up">
                        <Spinner className="loading-conversions" />
                      </div>
                    ) : (
                      <Form.Group className="mb-3 fade-in-up">
                        <Form.Label className={"input-label-outside-div"}>
                          {
                            this.props.localizedText[
                              "which-blockchain-would-you-like-to-use?"
                            ]
                          }
                        </Form.Label>
                        <InputGroup className="mb-3 input-field">
                          <Form.Select
                            variant="outline-secondary"
                            onChange={(e) =>
                              utils.handleChange(e, this.setState.bind(this))
                            }
                            name="network"
                            style={{
                              width: "100%",
                              flex: "0 1 auto",
                              textAlign: "start",
                            }}
                            isInvalid={this.state.invalidNetwork}
                            disabled={
                              this.state.loadingSent ||
                              this.state.listNetworks.length <= 1
                            }
                          >
                            {this.state.listNetworks.length == 1 ? (
                              <option>
                                {this.state.listNetworks[0].description}
                              </option>
                            ) : null}
                            <option></option>
                            {this.state.listNetworks.map((network) => (
                              <option value={network.blockchain}>
                                {network.description}
                              </option>
                            ))}
                          </Form.Select>
                        </InputGroup>
                      </Form.Group>
                    )}
                  </div>
                ) : null}
                {this.state.crypto != "" ? (
                  <Form.Group className="mb-3 fade-in-up">
                    <Form.Label className="input-label-outside-div">
                      {this.props.localizedText["amount-in-usd"]}
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        className="input-text no-background-input-outside"
                        type="number"
                        name="amount"
                        value={this.state.amount}
                        isInvalid={this.state.invalidAmount}
                        autoComplete="off"
                        onChange={(e) =>
                          this.handleChangeForAmountInUSD(
                            e,
                            this.setState.bind(this)
                          )
                        }
                      />{" "}
                      <InputGroup.Text
                        className={
                          this.state.invalidAmount
                            ? "outside-input-symbol input-symbol-danger"
                            : "outside-input-symbol"
                        }
                      >
                        USD
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                ) : null}

                {this.state.loadingConversion ? (
                  <div class="fade-in-up loading-quote">
                    <Spinner className="loading-conversions" />
                  </div>
                ) : null}
                {!this.state.loadingConversion &&
                this.state.cryptoAmountToReceive != "" ? (
                  <div class="fee-div fade-in-up">
                    <div class="fee-title">
                      {this.props.localizedText["transaction-details"]}
                    </div>
                    <div class="info-div">
                      <div class="fee-row">
                        <div>
                          {this.props.localizedText["amount-to-deposit"]}
                        </div>
                        <div>{this.state.amount} USD</div>
                      </div>
                      <div class="fee-row"></div>
                      <div class="fee-row">
                        <div>{this.props.localizedText["fee-of-deposit"]}</div>
                        <div>
                          {Number(this.state.amount) -
                            Number(this.state.cryptoFiatAmount)}
                          USD
                        </div>
                      </div>
                    </div>
                    <div class="totals-div">
                      <div class="fee-row">
                        <div>{this.props.localizedText["usd-to-recieve"]}</div>
                        <div>{this.state.cryptoFiatAmount} USD </div>
                      </div>
                      <div class="fee-row">
                        <div>
                          {
                            this.props.localizedText[
                              "selected-crypto-to-recieve"
                            ]
                          }
                        </div>{" "}
                        <div>
                          {this.state.cryptoAmountToReceive} {this.state.crypto}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>{" "}
          <ErrorMessageComponent
            errorMessage={this.props.localizedText[this.state.errorMessageType]}
          ></ErrorMessageComponent>
          <div class="buttons-div fade-in-up">
            <Button
              className="primary-button outside-primary-button"
              onClick={this.handleCheckout}
              disabled={this.state.loadingSent}
            >
              {this.state.loadingSent ? (
                <Spinner className="loader-send-outside-div-button"></Spinner>
              ) : null}
              {this.props.localizedText["send-payment-request"]}
            </Button>

            <Button
              className="secondary-button outside-secondary-button"
              onClick={this.props.tryToGoBackStage}
              disabled={this.state.loadingSent}
            >
              {this.props.localizedText["cancel"]}
            </Button>
            {this.state.successfullyPaymentEmailSent ? (
              <Form.Label class="input-label-inside-div greent-text">
                {this.props.localizedText["request-payment-sent"]}
              </Form.Label>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(DepositCreditCard);
