import { loginAPI } from "../../apis/loginAPI";
import { utils } from "../../utils/utils";

export default class LoginService {
  doLogin(state, redirectCallback, setState, setMessages) {
    loginAPI
      .login(state)
      .then((response) => {
        console.log(response);
        if (response.status === 200 && response.data.access_token != null) {
          //Success

          utils.setTokenFromStorage(response.data);
          redirectCallback("/verification", response.data);
        } else {
          //Failure
          setMessages("*incorrect-email-or-password");
          setState({
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        console.log(error.response);
        setMessages("*incorrect-email-or-password");
        setState({
          loadingSent: false,
        });
      });
  }
}
