import { portfolioAPI } from "../../apis/portfolioAPI";
import { marketDataAPI } from "../../apis/marketDataAPI";
import { utils } from "../../utils/utils";

export default class PortfolioService {
  getDefaultPortfolio(setState, chartOption, chartIndexPicked) {
    portfolioAPI
      .getDefaultPortfolio()
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("success", response);
          this.getPortfolioBalances(
            setState,
            response.data.name,
            chartOption,
            chartIndexPicked
          );
        } else {
          console.log("failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("error", error);
      });
  }

  getPortfolioBalances(setState, portfolioName, chartOption, chartIndexPicked) {
    portfolioAPI
      .getPortfolioBalances(portfolioName)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let assets = [];
          let promiseArrayHistoricalCandles = [];
          let fiatRequests = [];
          let fiatResponses = [];
          let cryptoRequests = [];
          let cryptoResponses = [];
          for (let i = 0; i < response.data.length; i++) {
            promiseArrayHistoricalCandles.push(
              marketDataAPI.getHistoricalCandles(
                response.data[i].base_currency + "-USD",
                chartOption[chartIndexPicked].granularity,
                Date.now() / 1000 -
                  86400 * chartOption[chartIndexPicked].length, //Multiplying days to get in seconds
                Date.now() / 1000
              )
            );
            if (response.data[i].fiat) {
              //conversion for fiat
              fiatRequests.push(response.data[i].base_currency);
              fiatResponses.push(
                marketDataAPI.getFiatConversion(
                  response.data[i].base_currency,
                  "USD",
                  response.data[i].qty
                )
              );
            } else {
              //conversion for crypto
              cryptoRequests.push(response.data[i].base_currency);
              cryptoResponses.push(
                marketDataAPI.getProductStats24H(
                  response.data[i].base_currency,
                  "USD"
                )
              );
            }
          }
          console.log("fiatRequests", fiatRequests);
          console.log("cryptoRequests", cryptoRequests);
          Promise.all([
            Promise.all(fiatResponses),
            Promise.all(cryptoResponses),
          ])
            .then(([fiatResponses, cryptoResponses]) => {
              for (let i = 0; i < fiatResponses.length; i++) {
                assets.push({
                  data: response.data.filter(
                    (element) => element.base_currency == fiatRequests[i]
                  )[0],
                  "value-usd":
                    fiatResponses[i].data.payload &&
                    JSON.parse(fiatResponses[i].data.payload).total
                      ? JSON.parse(fiatResponses[i].data.payload).total
                      : null,
                });
              }
              for (let i = 0; i < cryptoResponses.length; i++) {
                assets.push({
                  data: response.data.filter(
                    (element) => element.base_currency == cryptoRequests[i]
                  )[0],
                  "value-usd":
                    cryptoResponses[i].data.payload &&
                    JSON.parse(cryptoResponses[i].data.payload).last
                      ? JSON.parse(cryptoResponses[i].data.payload).last *
                        response.data.filter(
                          (element) =>
                            element.base_currency == cryptoRequests[i]
                        )[0].qty
                      : null,
                });
              }

              this.getHistoricalCandles(
                setState,
                assets,
                chartOption,
                chartIndexPicked
              );
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log("error", error);
      });
  }

  getHistoricalCandles(setState, assets, chartOption, chartIndexPicked) {
    let assetsAux = assets;
    let promiseArrayHistoricalCandles = [];

    for (let i = 0; i < assets.length; i++) {
      promiseArrayHistoricalCandles.push(
        marketDataAPI.getHistoricalCandles(
          assets[i].data.base_currency + "-USD",
          chartOption[chartIndexPicked].granularity,
          Date.now() / 1000 - 86400 * chartOption[chartIndexPicked].length, //Multiplying days to get in seconds
          Date.now() / 1000
        )
      );
    }

    Promise.all(promiseArrayHistoricalCandles)
      .then((data) => {
        for (let i = 0; i < data.length; i++) {
          assetsAux[i] = {
            name: assets[i]["data"]["base_currency"],
            data: assets[i]["data"],
            "chart-data":
              data[i]["data"] &&
              data[i]["data"]["payload"] &&
              Array.isArray(JSON.parse(data[i]["data"]["payload"]))
                ? JSON.parse(data[i]["data"]["payload"]).reverse()
                : null,
            "value-usd": assets[i]["value-usd"],
          };
        }
        let assetsAuxOrdered = utils.orderAlphabetically(assetsAux, "name");
        setState({
          chartIndexPicked: chartIndexPicked,
          listPortfolios: assetsAuxOrdered,
          listPortfoliosShowed: assetsAuxOrdered,
          totalPortfolios: assetsAuxOrdered.length,
          loadingAssets: false,
          loadingHistoricalCandles: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getTransactionHistory(setState) {
    portfolioAPI
      .getTransactionHistory()
      .then((response) => {
        console.log("/portfolio/get_deep_portfolio_info", response);
        if (response.status === 200) {
          setState({
            listTransactions: this.getAllTransactionsAndGroupByMonth(response),
            listTransactionsShowed:
              this.getAllTransactionsAndGroupByMonth(response),
            loadingTransactions: false,
          });
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log("error /portfolio/get_deep_portfolio_info", error);
      });
  }

  getAllTransactionsAndGroupByMonth(response) {
    let array = [];

    for (let i = 0; i < response.data[0].holdings.length; i++) {
      for (
        var j = 0;
        j < response.data[0].holdings[i].transactions.length;
        j++
      ) {
        if (response.data[0].holdings[i].transactions[j].qty != 0) {
          response.data[0].holdings[i].transactions[j]["base_currency"] =
            response.data[0].holdings[i]["base_currency"];
          response.data[0].holdings[i].transactions[j]["creation_time"] =
            new Date(
              response.data[0].holdings[i].transactions[j]["creation_time"]
            ).toLocaleString("en-US");

          array.push(response.data[0].holdings[i].transactions[j]);
        }
      }
    }

    console.log("array", array);
    return this.groupByMonth(array);
  }

  changeCurrencyFavorite(state, setState, currency, enabled) {
    portfolioAPI
      .getDefaultPortfolio()
      .then((responseDefaultPortfolio) => {
        if (
          responseDefaultPortfolio.status === 200 &&
          responseDefaultPortfolio.data
        ) {
          console.log("getDefaultPortfolio success", responseDefaultPortfolio);
          portfolioAPI
            .changeCurrencyFavorite(
              responseDefaultPortfolio.data.name,
              currency,
              enabled
            )
            .then((response) => {
              if (response.status === 200 || response.status === 204) {
                console.log("changeCurrencyFavorite success", response);
                var auxAurr = state.listLoadingFavorite.filter(
                  (item) => item != currency
                );
                var listPortfolios = state.listPortfolios;
                const index = listPortfolios.findIndex(
                  (item) => item.data.base_currency == currency
                );
                if (index !== -1) {
                  listPortfolios[index].data.fav = enabled;
                }
                this.updateOnlyPortfolioBalances(
                  setState,
                  listPortfolios,
                  auxAurr
                );
              } else {
                console.log("changeCurrencyFavorite failure", response);
                var auxAurr = state.listLoadingFavorite.filter(
                  (item) => item != currency
                );
                setState(
                  {
                    listLoadingFavorite: auxAurr,
                  },
                  () => {
                    //enableChartAnimation();
                  }
                );
              }
            })
            .catch((error) => {
              //Failure
              console.log("changeCurrencyFavorite error", error);
              var auxAurr = state.listLoadingFavorite.filter(
                (item) => item != currency
              );

              setState(
                {
                  listLoadingFavorite: auxAurr,
                },
                () => {
                  //enableChartAnimation();
                }
              );
            });
        } else {
          console.log("getDefaultPortfolio failure", responseDefaultPortfolio);
        }
      })
      .catch((error) => {
        //Failure
        console.log("getDefaultPortfolio error", error);
      });
  }

  updateOnlyPortfolioBalances(setState, listPortfolios, listLoadingFavorite) {
    let convertRequests = [];
    let convertResponses = [];
    for (let i = 0; i < listPortfolios.length; i++) {
      convertRequests.push(listPortfolios[i]["data"].base_currency);
      if (listPortfolios[i]["data"].base_currency != "USD") {
        convertResponses.push(
          marketDataAPI.getProductStats24H(
            listPortfolios[i]["data"].base_currency,
            "USD"
          )
        );
      } else {
        convertResponses.push(null);
      }
    }

    var listPortfoliosUpdated = listPortfolios;

    Promise.all(convertResponses)
      .then((convertResponses) => {
        for (let i = 0; i < convertResponses.length; i++) {
          const index = listPortfolios.findIndex(
            (item) => item.data.base_currency == convertRequests[i]
          );
          listPortfoliosUpdated[index]["value-usd"] =
            convertResponses[i] &&
            convertResponses[i].data &&
            convertResponses[i].data.payload &&
            JSON.parse(convertResponses[i].data.payload).last
              ? JSON.parse(convertResponses[i].data.payload).last *
                listPortfolios[index]["data"]["qty"]
              : null;
        }

        setState({
          shouldCheckTotalBalance: true,
          listLoadingFavorite: listLoadingFavorite,
          listPortfolios: listPortfoliosUpdated,
          listPortfoliosShowed: listPortfoliosUpdated,
          totalPortfolios: listPortfoliosUpdated.length,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  groupByMonth(array) {
    const months = Object.entries(
      array.reduce((b, a) => {
        let m = new Date(a.creation_time).toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
        });
        if (b.hasOwnProperty(m)) b[m].push(a);
        else b[m] = [a];
        return b;
      }, {})
    )
      .sort((a, b) => {
        return (
          new Date(b[0].split("/").reverse().join("-")) -
          new Date(a[0].split("/").reverse().join("-"))
        );
      })
      .map((e) => ({ [e[0]]: e[1] }));

    var groupedByMonth = [];
    console.log("months", months);
    for (let i = 0; i < months.length; i++) {
      var monthKey = Object.keys(months[i])[0];

      var object = {
        [monthKey]: months[i][monthKey].sort(function (a, b) {
          return new Date(b.creation_time) - new Date(a.creation_time);
        }),
      };

      groupedByMonth.push(object);
    }

    return groupedByMonth;
  }

  getTransactionHistoryMockResponse() {
    return {
      data: [
        {
          portfolio_id: "fefc101d-4932-4697-a7a4-e008485efc55",
          name: "My Portfolio",
          default: true,
          holdings: [
            {
              base_currency: "USD",
              fiat: true,
              fx_stablecurrency: false,
              qty: 0.49,
              realized_pnl: 0,
              fee_amount: null,
              transactions: [
                {
                  creation_time: "2024-02-12T20:46:44.600662+00:00",
                  order_id: null,
                  transaction_id: 50631808729901012,
                  transaction_type: "HOLD",
                  transaction_subtype: null,
                  from: "fefc101d-4932-4697-a7a4-e008485efc55",
                  to: "effecty",
                  qty: -0.01,
                  price: 1,
                  fee_amount: null,
                  realized_pnl: 0,
                  description: "[asmuguruza@bloxcross.com] Withdraw to Effecty",
                },
                {
                  creation_time: "2024-02-01T21:29:56.404858+00:00",
                  order_id: null,
                  transaction_id: 49684000534088522,
                  transaction_type: "DEPOSIT",
                  transaction_subtype: null,
                  from: "Bloxcross",
                  to: "fefc101d-4932-4697-a7a4-e008485efc55",
                  qty: 0,
                  price: 0,
                  fee_amount: null,
                  realized_pnl: 0,
                  description:
                    "Created initial FIAT asset with Zero balance for Porfolio My Portfolio",
                },
                {
                  creation_time: "2024-02-05T20:37:12.171458+00:00",
                  order_id: null,
                  transaction_id: 50026436300685918,
                  transaction_type: "DEPOSIT",
                  transaction_subtype: "RECEIVED_FROM_PEER",
                  from: "William Barrios",
                  to: "fefc101d-4932-4697-a7a4-e008485efc55",
                  qty: 0.5,
                  price: 1,
                  fee_amount: null,
                  realized_pnl: 0,
                  description: "50026436300677253",
                },
              ],
            },
          ],
        },
      ],
    };
  }
}
