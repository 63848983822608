import axios from "axios";

export default class TransactionsService {
  getDepositWithdrawHistory(id, setState) {
    const headers = {
      headers: { Authorization: `Bearer 1234` },
    };

    return axios
      .get(
        process.env.REACT_APP_BASE_API_URL +
          "/prime/get_deposit-withdraw-history",
        headers
      )
      .then((response) => {
        if (response.status === 200 && response.data) {
          setState({
            listDepositWithdrawHistory: response.data,
            listDepositWithdrawHistoryShowed: response.data,
            totalDepositWithdrawHistory: response.data.length,
            loadingDepositWithdraw: false,
          });
        } else {
          //Failure
          console.log("getDepositWithdrawHistory", response);
          setState({
            listDepositWithdrawHistory:
              this.getMockDepositWithdrawHistoryResponse(),
            listDepositWithdrawHistoryShowed:
              this.getMockDepositWithdrawHistoryResponse(),
            totalDepositWithdrawHistory:
              this.getMockDepositWithdrawHistoryResponse().length,
            loadingDepositWithdraw: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("getDepositWithdrawHistory", error);
        setState({
          listDepositWithdrawHistory:
            this.getMockDepositWithdrawHistoryResponse(),
          listDepositWithdrawHistoryShowed:
            this.getMockDepositWithdrawHistoryResponse(),
          totalDepositWithdrawHistory:
            this.getMockDepositWithdrawHistoryResponse().length,
          loadingDepositWithdraw: false,
        });
      });
  }

  getDepositWithdrawHistoryWithTimer(id, setState) {
    setTimeout(this.getMockDepositWithdrawHistory.bind(this, setState), 1500);
  }

  getPaymentHistory(id, setState) {
    this.getMockPaymentHistory(setState);
  }

  getMockDepositWithdrawHistory(setState) {
    setState({
      listDepositWithdrawHistory: this.getMockDepositWithDrawHistoryResponse(),
      listDepositWithdrawHistoryShowed:
        this.getMockDepositWithDrawHistoryResponse(),
      totalDepositWithdrawHistory:
        this.getMockDepositWithDrawHistoryResponse().length,
      loadingDepositWithdraw: false,
    });
  }

  getMockPaymentHistory(setState) {
    setState({
      listPaymentHistory: this.getMockPaymentHistoryResponse(),
      listPaymentHistoryShowed: this.getMockPaymentHistoryResponse(),
      totalListPaymentHistory: this.getMockPaymentHistoryResponse().length,
      loadingPayments: false,
    });
  }

  getMockDepositWithDrawHistoryResponse() {
    const response = [
      {
        method: "Paypal",
        type: "Deposit",
        currency: "USD",
        amount: "431.00",
        date: "2023-07-03T01:25:47.568Z",
        "operation-number": "1049323",
      },
      {
        method: "Venmo",
        type: "Deposit",
        currency: "USD",
        amount: "45.00",
        date: "2023-07-03T01:25:47.568Z",
        "operation-number": "1049322",
      },
      {
        method: "Bank",
        type: "Withdraw",
        currency: "USD",
        amount: "200.00",
        date: "2023-07-02T01:25:47.568Z",
        "operation-number": "1049321",
      },
      {
        method: "Paypal",
        type: "Deposit",
        currency: "USD",
        amount: "431.00",
        date: "2023-07-03T01:25:47.568Z",
        "operation-number": "1049320",
      },
      {
        method: "Paypal",
        type: "Deposit",
        currency: "USD",
        amount: "50.00",
        date: "2023-06-03T01:25:47.568Z",
        "operation-number": "1049319",
      },
      {
        method: "Bank",
        type: "Withdraw",
        currency: "USD",
        amount: "200.00",
        date: "2023-06-01T01:25:47.568Z",
        "operation-number": "1049318",
      },
    ];

    return this.groupByMonth(response);
  }

  getMockPaymentHistoryResponse() {
    const response = [
      {
        method: "Paypal",
        type: "Sent",
        currency: "USD",
        amount: "431.00",
        date: "2023-04-03T01:25:47.568Z",
        "sender-id": "johndoegmail.com",
      },
      {
        method: process.env.REACT_APP_COMPANY_NAME + " User",
        type: "Sent",
        currency: "USD",
        amount: "45.00",
        date: "2023-04-03T01:25:47.568Z",
        "sender-id": "john#9743930",
      },
      {
        method: "Paypal",
        type: "Recieved",
        currency: "USD",
        amount: "200.00",
        date: "2023-04-02T01:25:47.568Z",
        "sender-id": "johndoegmail.com",
      },
      {
        method: "Paypal",
        type: "Sent",
        currency: "Pesos",
        amount: "431.00",
        date: "2023-03-03T01:25:47.568Z",
        "sender-id": "johndoegmail.com",
      },
      {
        method: "Paypal",
        type: "Sent",
        currency: "Soles",
        amount: "50.00",
        date: "2023-03-03T01:25:47.568Z",
        "sender-id": "johndoegmail.com",
      },
      {
        method: "Bank",
        type: "Recieved",
        currency: "USD",
        amount: "200.00",
        date: "2023-02-01T01:25:47.568Z",
        "sender-id": "12394059",
      },
    ];

    return this.groupByMonth(response);
  }

  groupByMonth(array) {
    const months = Object.entries(
      array.reduce((b, a) => {
        let m = a.date.split("T")[0].slice(5, 7);
        if (b.hasOwnProperty(m)) b[m].push(a);
        else b[m] = [a];
        return b;
      }, {})
    )
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map((e) => ({ [e[0]]: e[1] }));

    var groupedByMonth = [];
    for (let i = 0; i < months.length; i++) {
      var monthKey = Object.keys(months[i])[0];

      var object = { [monthKey]: months[i][monthKey] };

      groupedByMonth.push(object);
    }

    console.log("groupedByMonth", groupedByMonth);
    return groupedByMonth;
  }
}
