import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./depositWithdraw.css";
import DepositWithdrawService from "./depositWithdrawService";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { TabContent } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { RiBankFill } from "react-icons/ri";
import { ImSearch } from "react-icons/im";
import { IoCard } from "react-icons/io5";
import { RxCrossCircled } from "react-icons/rx";
import { FaMoneyBillWave } from "react-icons/fa";
import TotalBalance from "../../components/total-balance/totalBalance";
import GoBackButton from "../../components/go-back-button/goBackButton";
import Result from "../../components/result/result";
import { Spinner } from "react-bootstrap";
import { utils } from "../../utils/utils.jsx";
import ErrorMessageComponent from "../../components/errorMessage/errorMessage.jsx";
import FormControl from "react-bootstrap/FormControl";

class DepositWithdraw extends Component {
  state = {
    isMobileWidth: false,
    name: null,
    account: {},
    listDeposit: [
      {
        name: "Bank",
        type: "bank",
      },
      {
        name: "Card",
        type: "card",
      },
      {
        name: "Cash",
        type: "cash",
      },
    ],

    errorMessageType: "",

    stage: 1,

    paymentMethod: null,
    fundsToSend: "",
    instrument: "",

    invalidFundsToSend: false,
    invalidInstrument: false,

    tabPicked: "deposit",

    resultSuccess: null,

    loadingSent: false,
  };

  service = new DepositWithdrawService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.phoneVerified != null && !this.props.phoneVerified) {
      this.goToURL("/dashboard/settings/verify-phone");
    } else if (this.props.emailVerified != null && !this.props.emailVerified) {
      this.goToURL("/dashboard/settings/verify-email");
    } else if (this.props.idVerified != null && !this.props.idVerified) {
      this.goToURL("/dashboard/settings/id-verification");
    }

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      "/dashboard"
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        "/dashboard"
      )
    );

    if (this.props.account) {
      this.setState({
        account: this.props.account,
      });
    } else {
      //ComponentDidUpdateWill take care of it
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.account) {
        this.setState({
          account: this.props.account,
        });
      }
      if (this.props.phoneVerified != null && !this.props.phoneVerified) {
        this.goToURL("/dashboard/settings/verify-phone");
      } else if (
        this.props.emailVerified != null &&
        !this.props.emailVerified
      ) {
        this.goToURL("/dashboard/settings/verify-email");
      } else if (this.props.idVerified != null && !this.props.idVerified) {
        this.goToURL("/dashboard/settings/id-verification");
      }
    }
  }

  goToURL = (url) => {
    this.props.history.push({ pathname: url, megastate: { from: true } });
  };

  getIcon = (type) => {
    if (type == "bank") {
      return <RiBankFill class="icon"></RiBankFill>;
    } else if (type == "card") {
      return <IoCard class="icon"></IoCard>;
    } else if (type == "cash") {
      return <FaMoneyBillWave class="icon"></FaMoneyBillWave>;
    }
  };

  choosePaymentMethod = (paymentMethod) => {
    this.setState({
      paymentMethod: paymentMethod,
      stage: this.state.stage + 1,
    });
  };

  eliminatePaymentMethod = () => {
    this.setState({
      paymentMethod: null,
      stage: this.state.stage - 1,
    });
  };

  clickTab = (tabClicked) => {
    this.setState({
      tabPicked: tabClicked,
    });
  };

  startDeposit = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (this.state.fundsToSend != "") {
          if (!isNaN(this.state.fundsToSend)) {
            this.setState(
              {
                invalidFundsToSend: false,
                invalidInstrument: false,
                loadingSent: true,
              },
              () => {
                this.service.getDefaultPortfolio(
                  this.state.fundsToSend,
                  this.state.instrument,
                  this.setState.bind(this)
                );
              }
            );
          } else {
            this.setMessages("error-valid-number");
            this.setState({
              invalidFundsToSend: isNaN(this.state.fundsToSend),
              invalidInstrument: isNaN(this.state.instrument),
            });
          }
        } else {
          this.setMessages("error-incomplete-fields");
          this.setState({
            invalidFundsToSend: this.state.fundsToSend === "",
            invalidInstrument: this.state.instrument === "",
          });
        }
      }
    );
  };

  possibleDeposits = ({ deposits }) => (
    <div class="deposit-list">
      {deposits.map((deposit, index) => (
        <div
          class="deposit-row-container"
          onClick={this.choosePaymentMethod.bind(this, deposit)}
        >
          <div class="deposit-row">
            <div class="background"> </div>
            <div class="content">
              <div class="metadata-column">
                {this.getIcon(deposit["type"])}
                <div class="text">
                  <div class="title">{deposit["name"]}</div>
                  <div class="region">{deposit["region"]}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div class="deposit-withdraw-container">
          <div class="deposit-withdraw-body">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
              ></GoBackButton>
            </div>
            <div class="middle-container container-padding">
              {this.state.stage === 1 || this.state.stage === 2 ? (
                <div>
                  <TotalBalance
                    account={this.state.account}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                  ></TotalBalance>
                  <Tab.Container defaultActiveKey="deposit">
                    <div class="tab-header">
                      <div class="tab-buttons">
                        <Nav.Item
                          class={
                            this.state.tabPicked == "deposit"
                              ? "tab-button tab-picked"
                              : "tab-button"
                          }
                        >
                          <Nav.Link
                            eventKey="deposit"
                            onClick={this.clickTab.bind(this, "deposit")}
                          >
                            Deposit
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          class={
                            this.state.tabPicked == "withdraw"
                              ? "tab-button tab-picked"
                              : "tab-button"
                          }
                        >
                          <Nav.Link
                            eventKey="withdraw"
                            onClick={this.clickTab.bind(this, "withdraw")}
                          >
                            Withdraw
                          </Nav.Link>
                        </Nav.Item>
                      </div>
                    </div>
                    <Tab.Content>
                      <Tab.Pane
                        eventKey="deposit"
                        title="Deposit"
                        tabClassName="tab"
                      >
                        <TabContent className="tab-container">
                          <div class="top-section">
                            <div class="form-container">
                              <div class="background"></div>
                              <div class="form-section-div content">
                                <div class="form-div">
                                  {!this.state.paymentMethod ? (
                                    <InputGroup className="mb-3 input-payment-method">
                                      <Form.Label className="input-label-inside-div">
                                        From
                                      </Form.Label>

                                      <div class="bar">
                                        <ImSearch class="search-icon"></ImSearch>
                                        <div className="text">Search</div>
                                        <div class="browse-button">Browse</div>
                                      </div>
                                    </InputGroup>
                                  ) : (
                                    <InputGroup
                                      className="mb-3 input-payment-method"
                                      onClick={this.eliminatePaymentMethod}
                                    >
                                      <Form.Label className="input-label-inside-div">
                                        From
                                      </Form.Label>

                                      <div class="bar">
                                        {this.getIcon(
                                          this.state.paymentMethod["type"]
                                        )}
                                        <div className="text">
                                          {this.state.paymentMethod["name"]}
                                        </div>
                                        <RxCrossCircled class="eliminate-icon"></RxCrossCircled>
                                      </div>
                                    </InputGroup>
                                  )}
                                </div>
                                {this.state.stage == 1 ? (
                                  <div class="text-detail">Description</div>
                                ) : (
                                  <div class="form-div">
                                    <Form.Group className="mb-3 input-payment-method">
                                      <Form.Label className="input-label-inside-div">
                                        Funds to Send
                                      </Form.Label>
                                      <InputGroup className="mb-3">
                                        <Form.Control
                                          type="text"
                                          name="fundsToSend"
                                          value={this.state.fundsToSend}
                                          isInvalid={
                                            this.state.invalidFundsToSend
                                          }
                                          onChange={(e) =>
                                            utils.handleChange(
                                              e,
                                              this.setState.bind(this)
                                            )
                                          }
                                          className="input-text no-background-input-inside"
                                        />
                                        <InputGroup.Text
                                          className={
                                            this.state.invalidFundsToSend
                                              ? "inside-input-symbol input-symbol-danger"
                                              : "inside-input-symbol"
                                          }
                                        >
                                          USD
                                        </InputGroup.Text>
                                      </InputGroup>
                                    </Form.Group>
                                  </div>
                                )}
                              </div>
                            </div>
                            {this.state.stage == 1 ? (
                              <div class="deposit-list-container">
                                <this.possibleDeposits
                                  deposits={this.state.listDeposit}
                                ></this.possibleDeposits>
                                <div class="search-bar-div"> </div>
                                <div class="deposit-list-row"></div>
                              </div>
                            ) : null}
                            {this.state.stage == 2 ? (
                              <div class="form-container form-to">
                                <div class="form-section-div content">
                                  <div class="form-div">
                                    <InputGroup className="mb-3 input-payment-method">
                                      <Form.Label className="input-label-outside-div">
                                        To
                                      </Form.Label>

                                      <div class="bar no-cursor outside">
                                        <FaMoneyBillWave className="icon"></FaMoneyBillWave>
                                        <div className="text">
                                          {process.env.REACT_APP_COMPANY_NAME}
                                          <div class="value-in-blox">
                                            $1.00 USD
                                          </div>
                                        </div>
                                      </div>
                                    </InputGroup>
                                  </div>

                                  <div class="form-div">
                                    <Form.Group className="mb-3 form-group">
                                      <Form.Label className="input-label-outside-div">
                                        Currency
                                      </Form.Label>
                                      <InputGroup className="mb-3">
                                        <FormControl
                                          type="text"
                                          name="instrument"
                                          value={this.state.instrument}
                                          isInvalid={
                                            this.state.invalidInstrument
                                          }
                                          onChange={(e) =>
                                            utils.handleChange(
                                              e,
                                              this.setState.bind(this)
                                            )
                                          }
                                          className="input-text no-background-input-outside"
                                          autoComplete="off"
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          {this.state.stage == 2 ? (
                            <div class="bottom-section">
                              <div class="info-div">
                                <div class="exchange-rate">
                                  Exchange Rate
                                  <b>1 USD</b>
                                </div>
                                <div class="info-table">
                                  <div class="data-row">0.1 USDT</div>
                                  <div class="data-row">
                                    Peer variable commisson: <b>0.70%</b>
                                  </div>
                                  <div class="data-row">$0.70 USD</div>
                                  <div class="data-row">0.1 USDT</div>
                                  <div class="data-row">
                                    Peer variable commisson: <b>0.70%</b>
                                  </div>
                                  <div class="data-row">$0.70 USD</div>
                                </div>
                              </div>

                              <ErrorMessageComponent
                                errorMessage={
                                  this.props.localizedText[
                                    this.state.errorMessageType
                                  ]
                                }
                              ></ErrorMessageComponent>

                              <div class="buttons-div">
                                <Button
                                  className="primary-button outside-primary-button"
                                  onClick={this.startDeposit.bind(this)}
                                  disabled={this.state.loadingSent}
                                >
                                  {this.state.loadingSent ? (
                                    <Spinner className="loader-send-outside-div-button"></Spinner>
                                  ) : null}
                                  Deposit
                                </Button>
                              </div>
                            </div>
                          ) : null}
                        </TabContent>
                      </Tab.Pane>

                      <Tab.Pane
                        eventKey="withdraw"
                        title="Withdraw"
                        tabClassName="tab"
                      >
                        <TabContent className="tab-container">
                          <div class="top-section">
                            <div class="form-container">
                              <div class="background"></div>
                              <div class="form-section-div content">
                                <div class="form-div">
                                  {!this.state.paymentMethod ? (
                                    <InputGroup className="mb-3 input-payment-method">
                                      <Form.Label className="input-label-inside-div">
                                        To
                                      </Form.Label>

                                      <div class="bar">
                                        <ImSearch class="search-icon"></ImSearch>
                                        <div className="text"></div>
                                        <div class="browse-button">Browse</div>
                                      </div>
                                    </InputGroup>
                                  ) : (
                                    <InputGroup className="mb-3 input-payment-method">
                                      <Form.Label className="input-label-inside-div">
                                        To
                                      </Form.Label>

                                      <div class="bar no-cursor">
                                        <FaMoneyBillWave className="image"></FaMoneyBillWave>
                                        <div className="text">
                                          {process.env.REACT_APP_COMPANY_NAME}
                                          <div class="value-in-blox">
                                            $1.00 USD
                                          </div>
                                        </div>
                                      </div>
                                    </InputGroup>
                                  )}
                                </div>
                                {this.state.stage == 1 ? (
                                  <div class="text-detail">Description</div>
                                ) : (
                                  <div class="form-div">
                                    <Form.Group className="mb-3 input-payment-method">
                                      <Form.Label className="input-label-inside-div">
                                        Funds to Send
                                      </Form.Label>

                                      <InputGroup className="mb-3">
                                        <Form.Control
                                          type="text"
                                          name="fundsToSend"
                                          value={this.state.fundsToSend}
                                          onChange={(e) =>
                                            utils.handleChange(
                                              e,
                                              this.setState.bind(this)
                                            )
                                          }
                                          className="input-text no-background-input-inside"
                                        />
                                        <InputGroup.Text className="currency">
                                          USD
                                        </InputGroup.Text>
                                      </InputGroup>
                                    </Form.Group>
                                  </div>
                                )}
                              </div>
                            </div>
                            {this.state.stage == 1 ? (
                              <div class="deposit-list-container">
                                <this.possibleDeposits
                                  deposits={this.state.listDeposit}
                                ></this.possibleDeposits>
                                <div class="search-bar-div"></div>
                                <div class="deposit-list-row"></div>
                              </div>
                            ) : null}
                            {this.state.stage == 2 ? (
                              <div class="form-container form-from">
                                <div class="form-section-div content">
                                  <div class="form-div">
                                    <InputGroup className="mb-3 input-payment-method ">
                                      <Form.Label className="input-label-inside-div">
                                        From
                                      </Form.Label>

                                      <div
                                        class="bar"
                                        onClick={this.eliminatePaymentMethod}
                                      >
                                        {this.getIcon(
                                          this.state.paymentMethod["type"]
                                        )}
                                        <div className="text">
                                          {this.state.paymentMethod["name"]}
                                        </div>
                                        <RxCrossCircled class="eliminate-icon"></RxCrossCircled>
                                      </div>
                                    </InputGroup>
                                  </div>

                                  <div class="form-div">
                                    <Form.Group className="mb-3 input-payment-method">
                                      <Form.Label className="input-label-inside-div">
                                        Funds to Recieve
                                      </Form.Label>
                                      <InputGroup className="mb-3">
                                        <Form.Control
                                          type="text"
                                          name="fundsToSend"
                                          value={"$ " + this.state.fundsToSend}
                                          className="input-text"
                                          disabled
                                        />
                                        <InputGroup.Text className="currency">
                                          USD2
                                        </InputGroup.Text>
                                      </InputGroup>
                                    </Form.Group>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          {this.state.stage == 2 ? (
                            <div class="bottom-section">
                              <div class="info-div">
                                <div class="exchange-rate">
                                  Exchange Rate
                                  <b>1 USDT</b>
                                </div>
                                <div class="info-table">
                                  <div class="data-row">0.1 USDT</div>
                                  <div class="data-row">
                                    Peer variable commisson: <b>0.70%</b>
                                  </div>
                                  <div class="data-row">$0.70 USD</div>
                                  <div class="data-row">0.1 USDT</div>
                                  <div class="data-row">
                                    Peer variable commisson: <b>0.70%</b>
                                  </div>
                                  <div class="data-row">$0.70 USD</div>
                                </div>
                              </div>

                              <Button
                                className="deposit-withdraw-button outside-primary-button"
                                onClick={this.startDeposit.bind(this)}
                                disabled={this.state.loadingSent}
                              >
                                {this.state.loadingSent ? (
                                  <Spinner className="loader-send-outside-div-button"></Spinner>
                                ) : null}
                                Deposit
                              </Button>
                            </div>
                          ) : null}
                        </TabContent>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              ) : null}
              {this.state.stage === 3 ? (
                <Result
                  success={this.state.resultSuccess}
                  secondButton={false}
                  title={"Success"}
                  message={"Deposit done"}
                  primaryButtonText={"Continue"}
                  primaryButtonFunction={this.goToURL.bind(this, "/dashboard/")}
                ></Result>
              ) : null}
            </div>
            <div class="side-container "></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(DepositWithdraw);
