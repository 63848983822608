import { portfolioAPI } from "../../apis/portfolioAPI";
import { depositAPI } from "../../apis/depositAPI";

export default class DepositWithdrawService {
  getDefaultPortfolio(amount, instrument, setState) {
    portfolioAPI
      .getDefaultPortfolio()
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("success", response);
          this.depositDev(amount, instrument, response.data.name, setState);
        } else {
          console.log("failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("error", error);
      });
  }

  depositDev(amount, instrument, portfolioName, setState) {
    depositAPI
      .depositDev(amount, instrument, portfolioName)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setState({
            stage: 3,
            resultSuccess: true,
          });
        } else {
          //Failure
          setState({
            stage: 3,
            resultSuccess: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        setState({
          stage: 3,
          resultSuccess: false,
        });
      });
  }
}
