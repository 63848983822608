import axios from "axios";
import { utils } from "../utils/utils";

class MarketDataAPI {
  getHistoricalCandles(ticker, granularity, start, end) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      ticker: ticker,
      granularity: granularity,
      start: start,
      end: end,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/market_data/product_ohlc_historic",
      requestBody,
      headers
    );
  }

  getFiatConversion(fromCurrency, toCurrency, amount) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {
      from_curr: fromCurrency,
      to_curr: toCurrency,
      amount: Number(amount),
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/market_data/fx_convert",
      requestBody,
      headers
    );
  }

  getProductStats24H(fromCurrency, toCurrency) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {
      ticker: fromCurrency + "-" + toCurrency,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/market_data/product_24hr_stats",
      requestBody,
      headers
    );
  }
}

export const marketDataAPI = new MarketDataAPI();
