import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./payVendor.css";
import PayVendorService from "./payVendorService";
import GoBackButton from "../../../components/go-back-button/goBackButton";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import TransactionConfirmation from "../../../components/transactionConfirmation/transactionConfirmation";
import Result from "../../../components/result/result";
import { Spinner } from "react-bootstrap";
import { utils } from "../../../utils/utils";
import ErrorMessageComponent from "../../../components/errorMessage/errorMessage";
import ConversionComponent from "../../../components/conversion-component/conversionComponent";
import ConversionLinkComponent from "../../../components/conversionLink/conversionLink";

class PayVendor extends Component {
  state = {
    width: window.innerWidth,
    account: {},

    currencyFrom: "",
    currencyTo: "USD",
    amount: "",
    conversion: "",
    memo: "",
    description: "",
    network: "",
    paymentMethod: {},

    code: "",
    invalidCode: false,

    errorMessageType: "",

    listCurrenciesFrom: [],
    listCurrenciesTo: [],

    invalidCurrency: false,
    invalidAmount: false,
    invalidMemo: false,
    validAmount: false,

    totalBalance: 0,

    resultSuccess: true,

    loadingTotalBalance: true,
    loadingConversion: false,
    loadingSent: false,

    showMore: false,

    goBackURL: null, //checkprops will make goback function only goback to either vendors or vendor detail

    stage: 1,
  };

  service = new PayVendorService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.checkProps();

    this.service.getClientConfig(this.setState.bind(this));
    this.service.getCurrencies(this.setState.bind(this));
    this.service.getDefaultPortfolio(this.setState.bind(this));

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      this.tryToGoBackStage
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        this.tryToGoBackStage
      )
    );

    if (this.props.account) {
      this.setState(
        {
          account: this.props.account,
        },
        () => {
          console.log("account", this.state.account);
        }
      );
    } else {
      //ComponentDidUpdateWill take care of it
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.account) {
        this.setState({
          account: this.props.account,
        });
      }
    }
  }

  checkProps = () => {
    if (
      this.props.location.megastate &&
      this.props.location.megastate.hasOwnProperty("paymentMethod")
    ) {
      this.setState({
        paymentMethod: this.props.location.megastate.paymentMethod,
      });
      if (
        this.props.location.megastate.paymentMethod.pay_method_type == "crypto"
      ) {
        if (
          this.props.location.megastate.paymentMethod.metadata &&
          this.props.location.megastate.paymentMethod.metadata.token_symbol
        ) {
          this.setState(
            {
              currencyFrom:
                this.props.location.megastate.paymentMethod.metadata.token_symbol.split(
                  "-"
                )[0],
              currencyTo:
                this.props.location.megastate.paymentMethod.metadata.token_symbol.split(
                  "-"
                )[0],
              network:
                this.props.location.megastate.paymentMethod.metadata.token_symbol.split(
                  "-"
                )[1],
            },
            () => {
              console.log("state.currencyFrom", this.state.currencyFrom);
            }
          );
        } else {
          this.goToURL("/dashboard/payment/vendors/");
        }
      }
    } else {
      this.goToURL("/dashboard/payment/vendors/");
    }
  };

  handleChangeForRatio = (event, setState) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setState(
      {
        [name]: value,
      },
      () => {
        if (
          this.state.currencyFrom != this.state.currencyTo &&
          this.state.amount != "" &&
          this.state.currencyFrom != "" &&
          this.state.currencyTo != ""
        ) {
          setState(
            {
              conversion: "",
              loadingConversion: true,
            },
            () => {
              this.service.getConversion(
                this.state.amount,
                this.state.currencyFrom,
                this.state.currencyTo,
                this.setState.bind(this),
                this.setMessages.bind(this)
              );
            }
          );
        } else if (this.state.currencyFrom == this.state.currencyTo) {
          setState(
            {
              conversion: "",
            },
            () => {
              setState({
                conversion: "1",
              });
            }
          );
        }

        utils.handleChangeForInvalidAmount(
          this.state.listCurrenciesFrom,
          this.state.amount,
          this.state.currencyFrom,
          this.setMessages.bind(
            this,
            "*please-adjust-the-entered-amount.-it-exceeds-your-current-holdings"
          ),
          this.setState.bind(this)
        );
      }
    );
  };

  getDynamicLabel = (key) => {
    if (key == "acount_name") {
      return this.props.localizedText["name-on-the-account"];
    } else if (key == "country") {
      return this.props.localizedText["country-of-bank-account"];
    } else if (key == "recipient_name") {
      return this.props.localizedText["recipient-name"];
    } else if (key == "address_1") {
      return this.props.localizedText["full-address"];
    } else if (key == "address_2") {
      return this.props.localizedText["address-line-2"];
    } else if (key == "city") {
      return this.props.localizedText["city"];
    } else if (key == "state") {
      return this.props.localizedText["state"];
    } else if (key == "postal_code") {
      return this.props.localizedText["zip-or-postal-code"];
    } else if (key == "phone_number") {
      return this.props.localizedText["phone-number"];
    } else if (key == "routing_number") {
      return this.props.localizedText["routing-number"];
    } else if (key == "account_type") {
      return this.props.localizedText["checking-or-saving"];
    } else if (key == "account_class") {
      return this.props.localizedText["business-or-personal"];
    } else if (key == "account_number") {
      return this.props.localizedText["bank-account-number"];
    } else if (key == "swift_bic_code") {
      return this.props.localizedText["swift-/-bic-code"];
    } else if (key == "venmo_user_name") {
      return this.props.localizedText["venmo-@username"];
    } else if (key == "phone") {
      return this.props.localizedText["phone-registered-at-venmo"];
    } else if (key == "email") {
      return this.props.localizedText["email-registered-ay-venmo"];
    } else if (key == "paypal_user_name") {
      return this.props.localizedText["paypal-@username"];
    } else if (key == "token_symbol") {
      return this.props.localizedText["token-symbol-name"];
    } else if (key == "wallet_address") {
      return this.props.localizedText["wallet-address-to-deposit-to"];
    } else if (key == "wallet_label") {
      return this.props.localizedText["wallet-internal-name"];
    } else if (key == "name_on_card") {
      return this.props.localizedText["name-on-the-account"];
    } else if (key == "beneficiary_name") {
      return this.props.localizedText["beneficiary-name"];
    } else {
      return key;
    }
  };

  goToURL = (url) => {
    this.props.history.push({ pathname: url, megastate: { from: true } });
  };

  moveToConfirmation = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (
          this.state.currencyFrom != "" &&
          this.state.amount != "" &&
          this.state.currencyTo != "" &&
          (this.state.paymentMethod.pay_method_type == "crypto" ||
            (this.state.paymentMethod.pay_method_type != "crypto" &&
              this.state.memo != ""))
        ) {
          if (
            this.state.listCurrenciesFrom.filter(
              (currency) => currency.base_currency === this.state.currencyFrom
            ).length > 0 &&
            this.state.listCurrenciesFrom.filter(
              (element) => element.base_currency == this.state.currencyFrom
            )[0].qty >= Number(this.state.amount)
          ) {
            this.setState({
              invalidCurrency: false,
              invalidAmount: false,
              invalidMemo: false,
              stage: 2,
            });
          } else {
            this.setMessages(
              "*please-adjust-the-entered-amount.-it-exceeds-your-current-holdings"
            );
            this.setState({
              invalidAmount: true,
            });
          }
        } else {
          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidCurrency: this.state.currencyFrom == "",
            invalidAmount: this.state.amount == "",
            invalidMemo: this.state.memo == "",
          });
        }
      }
    );
  };

  validateAuth = (code, authChoice) => {
    this.setState(
      {
        invalidCode: false,
        loadingSent: true,
        errorMessageType: "",
      },
      () => {
        if (code != "") {
          if (authChoice == "Phone") {
            this.service.validatePhone(
              code,
              this.payVendor.bind(this),
              this.setState.bind(this),
              this.setMessages.bind(this)
            );
          } else {
            this.service.validateAuth(
              code,
              this.payVendor.bind(this),
              this.setState.bind(this),
              this.setMessages.bind(this)
            );
          }
        } else {
          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidCode: code == "",
          });
        }
      }
    );
  };

  payVendor = () => {
    console.log("payVendor");
    if (
      this.props.location.megastate.paymentMethod.pay_method_type != "crypto"
    ) {
      this.service.payVendor(
        this.state.paymentMethod.payment_account_id,
        this.state,
        this.setState.bind(this),
        this.setMessages.bind(this)
      );
    } else {
      this.service.withdrawCrypto(
        this.state.paymentMethod.metadata.wallet_address,
        this.state.currencyFrom,
        this.state.network,
        this.state.amount,
        this.state.stage,
        this.setState.bind(this)
      );
    }
  };

  moveToResult = () => {};

  moveStage = (value) => {
    this.setState({
      stage: this.state.stage + value,
    });
  };

  goBackStage = () => {
    this.setState({
      stage: this.state.stage - 1,
      invalidCode: false,
      errorMessageType: "",
    });
  };

  tryToGoBackStage = () => {
    if (this.state.stage == 1) {
      this.props.history.goBack();
    } else {
      this.goBackStage();
    }
  };

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  confirmPhone = () => {
    this.setState(
      {
        loadingPhone: true,
        inputKey: this.state.inputKey + 1,
        code: "",
        invalidCode: false,
        errorMessageType: "",
      },
      () => {
        this.service.confirmPhone(this.setState.bind(this));
      }
    );
  };

  toggleShowMore = () => {
    this.setState({
      showMore: !this.state.showMore,
    });
  };

  getResultMessage = () => {
    if (this.state.resultSuccess) {
      return this.props.localizedText["you-have-successfully-payed-a-vendor"];
    } else {
      return this.props.localizedText[
        "unfortunately,-there-was-a-problem-with-the-payment"
      ];
    }
  };

  render() {
    return (
      <React.Fragment>
        <div class="pay-vendor-container">
          <div class="pay-vendor-body">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
                goBack={this.tryToGoBackStage}
              ></GoBackButton>
            </div>
            <div class="middle-container container-padding">
              {this.state.stage === 1 ? (
                <div>
                  <div class="main-title">
                    {this.props.localizedText["pay"]}
                  </div>
                  <ConversionLinkComponent
                    width={this.state.width}
                    currencyFrom={this.state.currencyFrom}
                    currencyTo={this.state.currencyTo}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                  ></ConversionLinkComponent>
                  <div class="top-section">
                    <div class="from-container">
                      <div class="title">
                        {this.props.localizedText["from"]}
                      </div>
                      <div class="total-balance-info">
                        <div class="info-column">
                          <div class="image"></div>
                          <div class="text">
                            <div class="username">
                              {this.state.account.username}
                            </div>
                            <div class="region">
                              {this.state.account.domicile}
                            </div>
                          </div>
                        </div>

                        {this.state.loadingTotalBalance ? (
                          <Spinner className="loader-total-balance"></Spinner>
                        ) : (
                          <div class="total-column">
                            <div class="total">
                              {this.props.localizedText["balance"]}
                            </div>
                            <div class="amount">
                              {" "}
                              $
                              {utils.roundToTwoDecimals(
                                this.state.totalBalance
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <Form.Group className="mb-3 form-group">
                        <Form.Label className="input-label-inside-div">
                          {this.props.localizedText["pay-with"]}
                        </Form.Label>
                        <InputGroup className="mb-3 input-field">
                          <Form.Select
                            variant="outline-secondary"
                            onChange={(e) =>
                              this.handleChangeForRatio(
                                e,
                                this.setState.bind(this)
                              )
                            }
                            name="currencyFrom"
                            isInvalid={this.state.invalidCurrency}
                            style={{
                              width: "100%",
                              flex: "0 1 auto",
                              textAlign: "start",
                            }}
                            disabled={
                              this.state.paymentMethod &&
                              this.state.paymentMethod.pay_method_type ==
                                "crypto"
                            }
                          >
                            {this.props.location.megastate &&
                            this.props.location.megastate.paymentMethod
                              .pay_method_type == "crypto" &&
                            this.state.listCurrenciesFrom.length > 0 ? (
                              <option>
                                {this.state.currencyFrom +
                                  (this.state.listCurrenciesFrom.filter(
                                    (currency) =>
                                      currency.base_currency ===
                                      this.state.currencyFrom
                                  ).length > 0
                                    ? " (" +
                                      utils.roundToTwoDecimals(
                                        this.state.listCurrenciesFrom.filter(
                                          (currency) =>
                                            currency.base_currency ===
                                            this.state.currencyFrom
                                        )[0].qty
                                      ) +
                                      ")"
                                    : " (0.00)")}
                              </option>
                            ) : (
                              <option></option>
                            )}

                            {this.state.listCurrenciesFrom.map((currency) => (
                              <option value={currency.base_currency}>
                                {currency.base_currency +
                                  " (" +
                                  utils.roundToTwoDecimals(currency.qty) +
                                  ")"}
                              </option>
                            ))}
                          </Form.Select>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group className="mb-3 form-group">
                        <Form.Label className="input-label-inside-div">
                          {this.props.localizedText["amount"]}
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <FormControl
                            type="number"
                            name="amount"
                            value={this.state.amount}
                            isValid={this.state.validAmount}
                            isInvalid={this.state.invalidAmount}
                            onChange={(e) =>
                              this.handleChangeForRatio(
                                e,
                                this.setState.bind(this)
                              )
                            }
                            className="input-text no-background-input-inside"
                            autoComplete="off"
                          />
                          <InputGroup.Text
                            className={
                              this.state.invalidAmount
                                ? "inside-input-symbol input-symbol-danger"
                                : "inside-input-symbol"
                            }
                          >
                            {this.state.currencyFrom}
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>

                      {this.props.location.megastate &&
                      this.props.location.megastate.paymentMethod &&
                      this.props.location.megastate.paymentMethod
                        .pay_method_type != "crypto" ? (
                        <Form.Group className="mb-3">
                          <Form.Label className="input-label-inside-div">
                            {
                              this.props.localizedText[
                                "memo-to-beneficiary-bank"
                              ]
                            }
                          </Form.Label>
                          <FormControl
                            name="memo"
                            value={this.state.memo}
                            isInvalid={this.state.invalidMemo}
                            onChange={(e) =>
                              utils.handleChange(e, this.setState.bind(this))
                            }
                            required
                            className="input-field"
                            autoComplete="off"
                          />
                        </Form.Group>
                      ) : null}

                      <Form.Group className="mb-3">
                        <Form.Label className="input-label-inside-div">
                          {this.props.localizedText["description-(optional)"]}
                        </Form.Label>
                        <FormControl
                          name="description"
                          value={this.state.description}
                          onChange={(e) =>
                            utils.handleChange(e, this.setState.bind(this))
                          }
                          required
                          className="input-field"
                          autoComplete="off"
                        />
                      </Form.Group>
                    </div>
                    <div class="to-container">
                      <div class="title">{this.props.localizedText["to"]}</div>

                      <div class="to-info">
                        <div class="to-info-row">
                          <div class="name">
                            {this.props.localizedText["name"]}
                          </div>
                          <div class="value">
                            {this.state.paymentMethod.name}
                          </div>{" "}
                        </div>
                        <div class="to-info-row">
                          <div class="name">
                            {this.props.localizedText["type"]}
                          </div>
                          <div class="value">
                            {this.state.paymentMethod.pay_method_type}
                          </div>{" "}
                        </div>
                      </div>

                      <Form.Group className="mb-3 form-group">
                        <Form.Label className="input-label-outside-div">
                          {this.props.localizedText["pay-to"]}
                        </Form.Label>
                        <InputGroup className="mb-3 input-field">
                          <Form.Select
                            variant="outline-secondary"
                            onChange={(e) =>
                              this.handleChangeForRatio(
                                e,
                                this.setState.bind(this)
                              )
                            }
                            name="currencyTo"
                            style={{
                              width: "100%",
                              flex: "0 1 auto",
                              textAlign: "start",
                            }}
                            disabled
                          >
                            <option>{this.state.currencyTo}</option>
                            {this.state.listCurrenciesTo.map((currency) => (
                              <option value={currency.base_currency}>
                                {currency.name +
                                  " (" +
                                  currency.base_currency +
                                  ")"}
                              </option>
                            ))}
                          </Form.Select>
                        </InputGroup>
                      </Form.Group>

                      {this.props.location.megastate &&
                      this.props.location.megastate.paymentMethod
                        .pay_method_type == "crypto" ? (
                        <Form.Group className="mb-3 form-group">
                          <Form.Label className="input-label-outside-div">
                            {this.props.localizedText["network"]}
                          </Form.Label>
                          <InputGroup className="mb-3 input-field">
                            <Form.Select
                              variant="outline-secondary"
                              style={{
                                width: "100%",
                                flex: "0 1 auto",
                                textAlign: "start",
                              }}
                              disabled
                            >
                              <option>{this.state.network}</option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>
                      ) : null}

                      {this.state.conversion != "" ? (
                        <Form.Group className="mb-3 form-group">
                          <Form.Label className="input-label-outside-div">
                            {this.props.localizedText["funds-to-send"]}
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <FormControl
                              type="text"
                              value={utils.roundToTwoDecimals(
                                Number(
                                  this.state.amount * this.state.conversion
                                )
                              )}
                              onChange={(e) =>
                                utils.handleChange(e, this.setState.bind(this))
                              }
                              className="input-text no-background-input-outside"
                              autoComplete="off"
                              disabled
                            />
                            <InputGroup.Text className={"outside-input-symbol"}>
                              {this.state.currencyTo}
                            </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      ) : null}
                      {this.state.loadingConversion != "" ? (
                        <div class="loading-conversions-div fade-in-up">
                          <Spinner />
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div class="bottom-section">
                    {this.state.paymentMethod.metadata &&
                    Object.keys(this.state.paymentMethod.metadata).length >
                      0 ? (
                      <div class="payment-method-info-container">
                        <div class="title">
                          {this.props.localizedText["payment-method-info"]}
                        </div>
                        <div
                          class={
                            "payment-method-info-div-wrapper " +
                            (this.state.showMore ? "show-more" : "")
                          }
                        >
                          <div class="payment-method-info-div">
                            {Object.keys(this.state.paymentMethod.metadata).map(
                              (key) => (
                                <div class="payment-method-info-row">
                                  <div class="key">
                                    {this.getDynamicLabel(key)}
                                  </div>
                                  <div class="value">
                                    {this.state.paymentMethod.metadata[key]}
                                  </div>
                                </div>
                              )
                            )}{" "}
                          </div>
                        </div>
                        {Object.keys(this.state.paymentMethod.metadata).length >
                        3 ? (
                          <div
                            className="show-more-button link-outside"
                            onClick={this.toggleShowMore}
                          >
                            {this.state.showMore
                              ? this.props.localizedText["show-less"]
                              : this.props.localizedText["show-more"]}
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  <ErrorMessageComponent
                    errorMessage={
                      this.props.localizedText[this.state.errorMessageType]
                    }
                  ></ErrorMessageComponent>
                  <div class="buttons-div">
                    <Button
                      className="primary-button outside-primary-button"
                      onClick={this.moveToConfirmation}
                    >
                      {this.props.localizedText["pay"]}
                    </Button>

                    <Button
                      className="secondary-button outside-secondary-button"
                      onClick={utils.goBack.bind(this, this.props)}
                    >
                      {this.props.localizedText["cancel"]}
                    </Button>
                  </div>
                </div>
              ) : null}

              {this.state.stage === 2 ? (
                <TransactionConfirmation
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  account={this.state.account}
                  loadingSent={this.state.loadingSent}
                  amountFrom={this.state.amount}
                  currencyFrom={this.state.currencyFrom}
                  amountTo={Number(this.state.amount * this.state.conversion)}
                  currencyTo={this.state.currencyTo}
                  name={this.state.paymentMethod.name}
                  id={this.state.paymentMethod.payment_account_id}
                  type={this.state.paymentMethod.pay_method_type}
                  primaryButtonText={
                    this.props.localizedText["confirm-payment"]
                  }
                  primaryButtonFunction={this.validateAuth.bind(this)}
                  secondaryButtonText={this.props.localizedText["back"]}
                  secondaryButtonFunction={this.goBackStage}
                  invalidCode={this.state.invalidCode}
                  errorMessage={
                    this.props.localizedText[this.state.errorMessageType]
                  }
                ></TransactionConfirmation>
              ) : null}

              {this.state.stage === 3 ? (
                <Result
                  success={this.state.resultSuccess}
                  secondButton={false}
                  title={
                    this.props.localizedText[
                      utils.getResultTitle(this.state.resultSuccess)
                    ]
                  }
                  message={this.getResultMessage()}
                  primaryButtonText={this.props.localizedText["continue"]}
                  primaryButtonFunction={this.goToURL.bind(
                    this,
                    "/dashboard/payment/vendors"
                  )}
                ></Result>
              ) : null}
            </div>
            <div class="side-container">
              {" "}
              {this.state.stage == 1 &&
              this.state.currencyFrom &&
              this.state.currencyTo &&
              this.state.currencyFrom != this.state.currencyTo ? (
                <ConversionComponent
                  currencyFrom={this.state.currencyFrom}
                  currencyTo={this.state.currencyTo}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                ></ConversionComponent>
              ) : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(PayVendor);
