import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ConversionComponent from "../conversion-component/conversionComponent";
import "./conversionLink.css";
export default function ConversionLinkComponent(props) {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return props.width != null && props.width != null && props.width < 1200 ? (
    <div class="see-conversion-link-container">
      <div className="see-conversion-link fade-in-up" onClick={toggleModal}>
        {props.localizedText ? props.localizedText["see-conversion-rate"] : ""}
      </div>
      <Modal
        show={showModal} // Use showModal state to control the visibility of the modal
        onHide={toggleModal} // Use toggleModal function to handle modal closing
        className="primary-modal see-conversion-link-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="see-conversion-component-div">
            <ConversionComponent
              currencyFrom={
                props.currencyFrom != "" && props.currencyFrom != null
                  ? props.currencyFrom
                  : "USDT"
              }
              currencyTo={props.currenyTo}
              region={props.region}
              localizedText={props.localizedText}
            ></ConversionComponent>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  ) : null;
}
