import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./updateVendor.css";
import UpdateVendorService from "./updateVendorService";
import GoBackButton from "../../../components/go-back-button/goBackButton";
import Result from "../../../components/result/result";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import { utils } from "../../../utils/utils";
import ErrorMessageComponent from "../../../components/errorMessage/errorMessage";

class NewVendor extends Component {
  state = {
    name: "",
    email: "",
    countryCode: "",
    phoneNumber: "",
    location: "",
    address: "",
    city: "",

    errorMessageType: "",
    newVendorId: "",
    listAreaCodes: [],
    listCountries: [],
    stage: 1,

    invalidName: false,
    invalidEmail: false,
    invalidRegionCode: false,
    invalidPhoneNumber: false,
    invalidLocation: false,
    invalidAddress: false,
    invalidCity: false,

    loadingCountries: true,
    loadingSent: false,
  };

  service = new UpdateVendorService();

  emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.phoneVerified != null && !this.props.phoneVerified) {
      this.goToURL("/dashboard/settings/verify-phone");
    } else if (this.props.emailVerified != null && !this.props.emailVerified) {
      this.goToURL("/dashboard/settings/verify-email");
    } else if (this.props.idVerified != null && !this.props.idVerified) {
      this.goToURL("/dashboard/settings/id-verification");
    }

    this.service.getAllCountries(
      this.setState.bind(this),
      this.loadData.bind(this)
    );

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      "/dashboard/payment/vendors"
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        "/dashboard/payment/vendors"
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.phoneVerified != null && !this.props.phoneVerified) {
        this.goToURL("/dashboard/settings/verify-phone");
      } else if (
        this.props.emailVerified != null &&
        !this.props.emailVerified
      ) {
        this.goToURL("/dashboard/settings/verify-email");
      } else if (this.props.idVerified != null && !this.props.idVerified) {
        this.goToURL("/dashboard/settings/id-verification");
      }
    }
  }

  loadData = () => {
    if (this.props.location.megastate && this.props.location.megastate.vendor) {
      this.setState({
        name: this.props.location.megastate.vendor.name,
        email: this.props.location.megastate.vendor.email,
        countryCode: utils.filterFunction(
          this.state.listCountries,
          ["gec"],
          this.props.location.megastate.vendor.phone_country
        )[0]["gec"],
        phoneNumber: this.props.location.megastate.vendor.phone,
        location: utils.filterFunction(
          this.state.listCountries,
          ["gec"],
          this.props.location.megastate.vendor.location
        )[0]["gec"],
        address: this.props.location.megastate.vendor.address
          ? this.props.location.megastate.vendor.address
          : "",
        city: this.props.location.megastate.vendor.city
          ? this.props.location.megastate.vendor.city
          : "",
      });
    } else {
      this.goToURL(
        "/dashboard/payments/vendors/detail/" + this.props.match.params.id
      );
    }
  };

  goToURL = (url) => {
    this.props.history.push({ pathname: url, megastate: { from: true } });
  };

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  updateVendor = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (
          this.state.name != "" &&
          this.state.email != "" &&
          this.state.countryCode != "" &&
          this.state.phoneNumber != "" &&
          this.state.location != "" &&
          this.state.address != "" &&
          this.state.city != ""
        ) {
          if (this.emailRegex.test(this.state.email)) {
            this.setState(
              {
                invalidName: false,
                invalidEmail: false,
                invalidRegionCode: false,
                invalidPhoneNumber: false,
                invalidLocation: false,
                invalidAddress: false,
                invalidCity: false,
                errorMessageType: "",
                loadingSent: true,
              },
              () => {
                this.service.updateVendor(
                  this.state,
                  this.props.match.params.id,
                  this.setState.bind(this),
                  this.setMessages.bind(this)
                );
              }
            );
          } else {
            this.setMessages("*please-enter-a-valid-email");
            this.setState({
              invalidEmail: !this.emailRegex.test(this.state.email),
            });
          }
        } else {
          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidName: this.state.name === "",
            invalidEmail: this.state.email === "",
            invalidRegionCode: this.state.countryCode === "",
            invalidPhoneNumber: this.state.phoneNumber === "",
            invalidLocation: this.state.country === "",
            invalidAddress: this.state.address === "",
            invalidCity: this.state.city === "",
          });
        }
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <div class="new-vendor-container">
          <div class="new-vendor-body">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
                goBack={"/dashboard/payment/vendors"}
              ></GoBackButton>
            </div>
            <div class="middle-container">
              {this.state.stage === 1 ? (
                <div class="vendor-form-div">
                  <div class="main-title">
                    {this.props.localizedText["update-vendor"]}
                  </div>
                  {this.state.loadingCountries ? (
                    <Spinner className="loader"></Spinner>
                  ) : (
                    <div className="fade-in-up">
                      <div class="div-parent-flex-column">
                        <Form.Group className="mb-3">
                          <Form.Label className="input-label-inside-div">
                            {this.props.localizedText["name"]}
                          </Form.Label>
                          <FormControl
                            type="text"
                            name="name"
                            value={this.state.name}
                            onChange={(e) =>
                              utils.handleChange(e, this.setState.bind(this))
                            }
                            isInvalid={this.state.invalidName}
                            className="input-field"
                            disabled={this.state.loadingSent}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label className="input-label-inside-div">
                            {this.props.localizedText["email"]}
                          </Form.Label>
                          <FormControl
                            name="email"
                            value={this.state.email}
                            onChange={(e) =>
                              utils.handleChange(e, this.setState.bind(this))
                            }
                            isInvalid={this.state.invalidEmail}
                            className="input-field"
                            disabled={this.state.loadingSent}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label className="input-label-inside-div">
                            {this.props.localizedText["phone-number"]}
                          </Form.Label>
                          <InputGroup className="mb-3 input-field">
                            <Form.Select
                              variant="outline-secondary"
                              onChange={(e) =>
                                utils.handleChange(e, this.setState.bind(this))
                              }
                              name="countryCode"
                              isInvalid={this.state.invalidRegionCode}
                              style={{
                                width: "8rem",
                                flex: "0 1 auto",
                                textAlign: "center",
                              }}
                              disabled={this.state.loadingSent}
                              value={this.state.countryCode}
                            >
                              <option></option>
                              {this.state.listAreaCodes.map((country) => (
                                <option value={country.gec}>
                                  {country.phone_area_code}
                                </option>
                              ))}
                            </Form.Select>
                            <Form.Control
                              type="text"
                              name="phoneNumber"
                              value={this.state.phoneNumber}
                              onChange={(e) =>
                                utils.handleChange(e, this.setState.bind(this))
                              }
                              isInvalid={this.state.invalidPhoneNumber}
                              className="input-field"
                            />
                          </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3 form-group">
                          <Form.Label className="input-label-inside-div">
                            {this.props.localizedText["location"]}
                          </Form.Label>
                          <InputGroup className="mb-3 input-field">
                            <Form.Select
                              variant="outline-secondary"
                              onChange={(e) =>
                                utils.handleChange(e, this.setState.bind(this))
                              }
                              name="location"
                              isInvalid={this.state.invalidLocation}
                              style={{
                                width: "100%",
                                flex: "0 1 auto",
                                textAlign: "start",
                              }}
                              disabled={this.state.loadingSent}
                              value={this.state.location}
                            >
                              <option></option>
                              {this.state.listCountries.map((country) => (
                                <option value={country.gec}>
                                  {country.country}
                                </option>
                              ))}
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label className="input-label-inside-div">
                            {this.props.localizedText["address"]}
                          </Form.Label>
                          <FormControl
                            name="address"
                            value={this.state.address}
                            onChange={(e) =>
                              utils.handleChange(e, this.setState.bind(this))
                            }
                            isInvalid={this.state.invalidAddress}
                            className="input-field"
                            disabled={this.state.loadingSent}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label className="input-label-inside-div">
                            {this.props.localizedText["city"]}
                          </Form.Label>
                          <FormControl
                            name="city"
                            value={this.state.city}
                            onChange={(e) =>
                              utils.handleChange(e, this.setState.bind(this))
                            }
                            isInvalid={this.state.invalidCity}
                            className="input-field"
                            disabled={this.state.loadingSent}
                          />
                        </Form.Group>

                        <ErrorMessageComponent
                          errorMessage={
                            this.props.localizedText[
                              this.state.errorMessageType
                            ]
                          }
                        ></ErrorMessageComponent>
                      </div>

                      <div class="buttons-div">
                        <Button
                          className="primary-button outside-primary-button"
                          onClick={this.updateVendor}
                          disabled={this.state.loadingSent}
                        >
                          {this.state.loadingSent ? (
                            <Spinner className="loader-send-outside-div-button"></Spinner>
                          ) : null}
                          {this.props.localizedText["update"]}
                        </Button>

                        <Button
                          className="secondary-button outside-secondary-button"
                          onClick={utils.goBack.bind(this, this.props)}
                        >
                          {this.props.localizedText["cancel"]}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <Result
                  success={true}
                  secondButton={false}
                  title={this.props.localizedText["great-news!"]}
                  message={
                    this.props.localizedText[
                      "the-information-was-updated-successfully!"
                    ]
                  }
                  primaryButtonText={this.props.localizedText["continue"]}
                  primaryButtonFunction={utils.goBack.bind(this, this.props)}
                ></Result>
              )}
            </div>
            <div class="side-container"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(NewVendor);
