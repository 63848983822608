import { swapAPI } from "../../apis/swapAPI.jsx";

export default class ConversionService {
  convertEstimate(amount, fromCurrency, toCurrency, lockTime, setState) {
    swapAPI
      .convertEstimate(amount, fromCurrency, toCurrency, lockTime)
      .then((response) => {
        console.log("convertEstimate", response);
        if (response.status === 200 && response.data) {
          setState({
            loadingEstimate: false,
            amountTo: response.data.amount_to_receive,
            conversionDataEstimate: response.data,
          });
        } else {
          setState({
            loadingEstimate: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("conversionLock error");
        setState({
          loadingEstimate: false,
        });
      });
  }

  reverseConvertEstimate(amount, fromCurrency, toCurrency, lockTime, setState) {
    swapAPI
      .reverseConvertEstimate(amount, fromCurrency, toCurrency, lockTime)
      .then((response) => {
        console.log("convertEstimate", response);
        if (response.status === 200 && response.data) {
          setState({
            loadingEstimate: false,
            amountFrom: response.data.amount_to_send,
            conversionDataEstimate: response.data,
          });
        } else {
          setState({
            loadingEstimate: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("conversionLock error");
        setState({
          loadingEstimate: false,
        });
      });
  }

  conversionLock(
    amount,
    fromCurrency,
    toCurrency,
    lockTime,
    parentCallback,
    setState
  ) {
    swapAPI
      .conversionLock(amount, fromCurrency, toCurrency, lockTime)
      .then((response) => {
        console.log("conversionLock", response);
        if (response.status === 200 && response.data) {
          setState(
            {
              conversionDataLocked: response.data,
              conversionLockedSuccessfully: true,
              loadingLock: false,
            },
            () => {
              if (parentCallback) {
                parentCallback();
              }
            }
          );
        } else {
          setState({
            loadingLock: false,
            conversionLockedSuccessfully: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("conversionLock error");
        setState({
          loadingLock: false,
          conversionLockedSuccessfully: false,
        });
      });
  }

  reverseConversionLock(
    amount,
    fromCurrency,
    toCurrency,
    lockTime,
    parentCallback,
    setState
  ) {
    swapAPI
      .reverseConversionLock(amount, fromCurrency, toCurrency, lockTime)
      .then((response) => {
        console.log("reverseConversionLock", response);
        if (response.status === 200 && response.data) {
          setState(
            {
              loadingLock: false,
              conversionDataLocked: response.data,
              conversionLockedSuccessfully: true,
            },
            () => {
              if (parentCallback) {
                parentCallback();
              }
            }
          );
        } else {
          setState({
            loadingLock: false,
            conversionLockedSuccessfully: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("reverseConversionLock error");
        setState({
          loadingLock: false,
          conversionLockedSuccessfully: false,
        });
      });
  }

  convertNow(amount, fromCurrency, toCurrency, parentCallback, setState) {
    swapAPI
      .convertNow(amount, fromCurrency, toCurrency)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("convertNow success", response);
          setState(
            { loadingConvertNow: false, convertedSuccessfully: true },
            () => {
              if (parentCallback) {
                parentCallback(true);
              }
            }
          );
        } else {
          setState(
            {
              loadingConvertNow: false,
              convertedSuccessfully: false,
            },
            () => {
              if (parentCallback) {
                parentCallback(false);
              }
            }
          );
        }
      })
      .catch((error) => {
        //Failure
        console.log("conversionLock error");
        setState(
          {
            loadingConvertNow: false,
            convertedSuccessfully: false,
          },
          () => {
            if (parentCallback) {
              parentCallback(false);
            }
          }
        );
      });
  }
}
