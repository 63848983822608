import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import FormControl from "react-bootstrap/FormControl";
import { withRouter } from "react-router-dom";
import "./login.css";
import LoginService from "./loginService";
import Footer from "../../components/footer/footer";
import ThemeRegionDiv from "../../components/theme-region-div/themeRegionDiv";
import { utils } from "../../utils/utils.jsx";
import { Link } from "react-router-dom";
import ErrorMessageComponent from "../../components/errorMessage/errorMessage.jsx";
class Login extends Component {
  state = {
    isMobileWidth: false,
    username: process.env.REACT_APP_ENV != "prod" ? "lkcomt@bloxcross.com" : "",
    password: process.env.REACT_APP_ENV != "prod" ? "Trying24@" : "",
    errorMessageType: "",

    loadingSent: false,

    invalidUsername: false,
    invalidPassword: false,
  };

  service = new LoginService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.checkWindowWidth();
    window.addEventListener("resize", this.checkWindowWidth);
  }

  componentDidUpdate(prevProps, prevState) {}

  checkWindowWidth = () => {
    this.windowSize = window.innerWidth;
    let flag = true;

    if (this.windowSize <= 1200) {
      flag = true;
    } else {
      flag = false;
    }

    this.setState({
      isMobileWidth: flag,
    });
  };

  goToURL = (url) => {
    this.props.history.push({ pathname: url });
  };

  goToVerification = (token) => {
    this.props.history.push({
      pathname: "/verification",
      megastate: {
        token: token,
      },
    });
  };

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  doLogin = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (this.state.username != "" && this.state.password != "") {
          this.setState(
            {
              invalidUsername: false,
              invalidPassword: false,
              loadingSent: true,
            },
            () => {
              this.service.doLogin(
                this.state,
                this.goToVerification.bind(this),
                this.setState.bind(this),
                this.setMessages.bind(this)
              );
            }
          );
        } else {
          this.setMessages("*username-or-password-is-missing");
          this.setState({
            invalidUsername: this.state.username == "",
            invalidPassword: this.state.password == "",
          });
        }
      }
    );
  };

  goToDashboard = () => {
    this.props.history.push({
      pathname: "/dashboard",
    });
  };

  goToCompanyWebsite = () => {
    window.location.replace(process.env.REACT_APP_COMPANY_WEBSITE);
  };

  render() {
    return (
      <React.Fragment>
        <div class="login-container">
          <div class="login-2fa-div">
            <a href={process.env.REACT_APP_COMPANY_WEBSITE}>
              <img
                class="login-image"
                onClick={this.goToCompanyWebsite}
                src={"/themes/" + this.props.theme + "/logo.png"}
              ></img>
            </a>
            <div class="register-title">
              {this.props.localizedText["sign-in"]}
            </div>
            <Link to="/register" class="register-subtitle">
              {this.props.localizedText["create-account?"]}
            </Link>
            <div class="login-form-div div-parent-flex-column">
              <Form.Group className="mb-3">
                <Form.Label
                  className={
                    this.state.isMobileWidth
                      ? "input-label-outside-div"
                      : "input-label-inside-div"
                  }
                >
                  {this.props.localizedText["email-address"]}
                </Form.Label>
                <FormControl
                  type="text"
                  name="username"
                  value={this.state.username}
                  onChange={(e) =>
                    utils.handleChange(e, this.setState.bind(this))
                  }
                  isInvalid={this.state.invalidUsername}
                  className="input-field"
                  disabled={this.state.loadingSent}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label
                  className={
                    this.state.isMobileWidth
                      ? "input-label-outside-div"
                      : "input-label-inside-div"
                  }
                >
                  {this.props.localizedText["password"]}
                </Form.Label>
                <FormControl
                  type="password"
                  name="password"
                  value={this.state.password}
                  onChange={(e) =>
                    utils.handleChange(e, this.setState.bind(this))
                  }
                  isInvalid={this.state.invalidPassword}
                  className="input-field"
                  disabled={this.state.loadingSent}
                />
              </Form.Group>

              <Link
                to="/forgot-password"
                class="forgot-password"
                onClick={this.goToURL.bind(this, "/forgot-password")}
              >
                {this.props.localizedText["forgot-password?"]}
              </Link>
            </div>
            <ErrorMessageComponent
              errorMessage={
                this.props.localizedText[this.state.errorMessageType]
              }
            ></ErrorMessageComponent>
            <div class="buttons-div">
              <Button
                className={
                  this.state.isMobileWidth
                    ? "primary-button outside-primary-button"
                    : "primary-button inside-primary-button"
                }
                onClick={this.doLogin}
                disabled={this.state.loadingSent}
              >
                {this.state.loadingSent ? (
                  <Spinner
                    className={
                      this.state.isMobileWidth
                        ? "loader-send-outside-div-button"
                        : "loader-send-inside-div-button"
                    }
                  ></Spinner>
                ) : null}
                {this.props.localizedText["sign-in"]}
              </Button>

              <div class="version-number">{process.env.REACT_APP_VERSION}</div>
            </div>
          </div>
          <ThemeRegionDiv
            changeTheme={this.props.changeTheme}
            theme={this.props.theme}
            handleModalOpen={this.props.handleModalOpen}
            changeRegion={this.props.changeRegion} //localization change
            region={this.props.region}
            localizedText={this.props.localizedText}
          ></ThemeRegionDiv>
        </div>
        <Footer
          region={this.props.region}
          localizedText={this.props.localizedText}
          theme={this.props.theme}
        ></Footer>
      </React.Fragment>
    );
  }
}

export default withRouter(Login);
