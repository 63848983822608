import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { withRouter } from "react-router-dom";
import "./verifyEmail.css";
import VerifyEmailService from "./verifyEmailService";
import ReactCodeInput from "react-code-input";
import Result from "../../../components/result/result";
import GoBackButton from "../../../components/go-back-button/goBackButton";
import ErrorMessageComponent from "../../../components/errorMessage/errorMessage";

class VerifyEmail extends Component {
  state = {
    isMobileWidth: false,
    message: "",

    code: "",
    invalidCode: false,

    stage: 1,

    //for rerendering and clearing
    inputKey: "",

    //gauth service isn't neccesairly called unless user forgts
    loadingEmail: true,
    loadingSent: false,

    errorMessageType: "",

    goBackURL: null, //checkprops will make goback function only goback to either settings or else
  };

  service = new VerifyEmailService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.checkProps();
    this.service.confirmEmail(this.setState.bind(this));

    this.checkWindowWidth();
    window.addEventListener("resize", this.checkWindowWidth);
  }

  componentDidUpdate(prevProps, prevState) {}

  //doesnt use utils func because it needs to check props to see where it came from
  checkWindowWidth = () => {
    this.windowSize = window.innerWidth;
    let flag = true;

    if (this.windowSize <= 1000) {
      flag = true;
    } else {
      flag = false;
    }

    this.checkGoBackNavBar(this.windowSize);
    this.setState({
      isMobileWidth: flag,
    });
  };

  checkGoBackNavBar = (windowSize) => {
    if (windowSize <= 1000) {
      if (
        this.props.location &&
        this.props.location.megastate &&
        this.props.location.megastate.fromSettings
      ) {
        this.props.changeGoBackNavBar(true, "/dashboard/settings");
      } else {
        this.props.changeGoBackNavBar(true, "/dashboard");
      }
    }
  };

  checkProps = () => {
    if (
      this.props.location &&
      this.props.location.megastate &&
      this.props.location.megastate.fromSettings
    ) {
      this.setState({
        goBackURL: "/dashboard/settings",
      });
    } else {
      this.setState({
        goBackURL: "/dashboard/",
      });
    }
  };

  onChange = (value) => {
    this.setState({
      code: value,
    });
  };

  goToURL = (url) => {
    this.props.history.push({ pathname: url, megastate: { from: true } });
  };

  confirmEmail = () => {
    this.setState(
      {
        loadingEmail: true,
        inputKey: this.state.inputKey + 1,
        code: "",
        invalidCode: false,
        errorMessageType: "",
      },
      () => {
        this.service.confirmEmail(this.setState.bind(this));
      }
    );
  };

  validateEmail = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (this.state.code.length == 8) {
          this.setState(
            {
              loadingSent: true,
            },
            () => {
              this.service.validateEmail(
                this.state.code,
                this.setState.bind(this),
                this.setMessages.bind(this),
                this.props.getClientConfig.bind(this)
              );
            }
          );
        } else {
          this.setMessages("*please-enter-an-8-digit-code");
          this.setState({
            invalidCode: true,
          });
        }
      }
    );
  };

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div class="verify-info-container">
          <div class="side-container">
            <GoBackButton
              region={this.props.region}
              localizedText={this.props.localizedText}
              goBack={this.state.goBackURL}
            ></GoBackButton>
          </div>
          <div class="middle-container container-padding">
            {this.state.stage === 1 ? (
              <div class="vendor-form-div">
                <div class="main-title">
                  {this.props.localizedText["verify-email"]}
                </div>
                {this.state.loadingEmail ? (
                  <Spinner className="loader"></Spinner>
                ) : (
                  <div class="div-parent-flex-column">
                    <p>
                      {this.props.localizedText[
                        "an-8-digit-verification-code-has-been-sent-to-your-email-address"
                      ] + this.state.message}
                    </p>

                    <ReactCodeInput
                      fields={8}
                      name="code"
                      value={this.state.code}
                      onChange={this.onChange}
                      key={this.state.inputKey}
                      isValid={!this.state.invalidCode}
                      disabled={this.state.loadingSent}
                      type="tel"
                    ></ReactCodeInput>

                    <div class="resend-message">
                      {
                        this.props.localizedText[
                          "it-may-take-some-seconds-for-the-code-to-arrive!"
                        ]
                      }
                      <br />
                      {this.props.localizedText["still-a-minute?"]}&nbsp;
                      <b onClick={this.confirmEmail}>
                        {this.props.localizedText["resend-code"]}
                      </b>
                    </div>

                    <ErrorMessageComponent
                      errorMessage={
                        this.props.localizedText[this.state.errorMessageType]
                      }
                    ></ErrorMessageComponent>

                    <div class="buttons-div">
                      <Button
                        className="primary-button outside-primary-button"
                        onClick={this.validateEmail}
                        disabled={this.state.loadingSent}
                      >
                        {this.state.loadingSent ? (
                          <Spinner className="loader-send-outside-div-button"></Spinner>
                        ) : null}
                        {this.props.localizedText["verify"]}
                      </Button>

                      <Button
                        className="secondary-button outside-secondary-button"
                        onClick={this.goToURL.bind(this, this.state.goBackURL)}
                      >
                        {this.props.localizedText["cancel"]}
                      </Button>
                    </div>
                    <div class="version-number">
                      {process.env.REACT_APP_VERSION}
                    </div>
                  </div>
                )}
              </div>
            ) : null}

            {this.state.stage === 2 ? (
              <Result
                success={true}
                secondButton={false}
                title={this.props.localizedText["great-news!"]}
                message={
                  this.props.localizedText[
                    "your-email-has-been-successfully-verified."
                  ]
                }
                primaryButtonText={this.props.localizedText["continue"]}
                primaryButtonFunction={this.goToURL.bind(
                  this,
                  this.state.goBackURL
                )}
              ></Result>
            ) : null}
          </div>
          <div class="side-container"></div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(VerifyEmail);
