import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./newVendor.css";
import NewVendorService from "./newVendorService";
import GoBackButton from "../../../components/go-back-button/goBackButton";
import Result from "../../../components/result/result";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import { utils } from "../../../utils/utils";
import ErrorMessageComponent from "../../../components/errorMessage/errorMessage";

class NewVendor extends Component {
  state = {
    name: "",
    email: "",
    countryCode: "",
    phoneNumber: "",
    location: "",
    address: "",
    city: "",
    errorMessageType: "",
    newVendorId: "",

    listCountries: [],
    listAreaCodes: [],

    stage: 1,

    invalidName: false,
    invalidEmail: false,
    invalidCountryCode: false,
    invalidPhoneNumber: false,
    invalidLocation: false,
    invalidAddress: false,
    invalidCity: false,

    loadingCountries: true,
    loadingSent: false,
  };

  service = new NewVendorService();
  emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.phoneVerified != null && !this.props.phoneVerified) {
      this.goToURL("/dashboard/settings/verify-phone");
    } else if (this.props.emailVerified != null && !this.props.emailVerified) {
      this.goToURL("/dashboard/settings/verify-email");
    } else if (this.props.idVerified != null && !this.props.idVerified) {
      this.goToURL("/dashboard/settings/id-verification");
    }

    this.service.getAllCountries(this.setState.bind(this));

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      "/dashboard/payment/vendors"
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        "/dashboard/payment/vendors"
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.account) {
        this.setState({
          account: this.props.account,
        });
      }
      if (this.props.phoneVerified != null && !this.props.phoneVerified) {
        this.goToURL("/dashboard/settings/verify-phone");
      } else if (
        this.props.emailVerified != null &&
        !this.props.emailVerified
      ) {
        this.goToURL("/dashboard/settings/verify-email");
      } else if (this.props.idVerified != null && !this.props.idVerified) {
        this.goToURL("/dashboard/settings/id-verification");
      }
    }
  }

  goToURL = (url) => {
    this.props.history.push({ pathname: url, megastate: { from: true } });
  };

  goToAddPaymentMethod = () => {
    this.props.history.push({
      pathname: "/dashboard/payment/vendors/payment-methods",
      megastate: {
        from: true,
        client_vendor_id: this.state.newVendorId,
      },
    });
  };

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  createVendor = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (
          this.state.name != "" &&
          this.state.email != "" &&
          this.state.countryCode != "" &&
          this.state.phoneNumber != "" &&
          this.state.location != "" &&
          this.state.address != "" &&
          this.state.city != ""
        ) {
          if (this.emailRegex.test(this.state.email)) {
            this.setState(
              {
                invalidName: false,
                invalidEmail: false,
                invalidCountryCode: false,
                invalidPhoneNumber: false,
                invalidLocation: false,
                invalidAddress: false,
                invalidCity: false,
                errorMessageType: "",
                loadingSent: true,
              },
              () => {
                this.service.createVendor(
                  this.state.name,
                  this.state.email,
                  this.state.countryCode,
                  this.state.phoneNumber,
                  this.state.location,
                  this.state.address,
                  this.state.city,
                  this.setState.bind(this),
                  this.setMessages.bind(this)
                );
              }
            );
          } else {
            this.setMessages("*please-enter-a-valid-email");
            this.setState({
              invalidName: this.state.name === "",
              invalidEmail: !this.emailRegex.test(this.state.email),
              invalidCountryCode: this.state.countryCode === "",
              invalidPhoneNumber: this.state.phoneNumber === "",
              invalidLocation: this.state.location === "",
              invalidAddress: this.state.address === "",
              invalidCity: this.state.city === "",
            });
          }
        } else {
          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidName: this.state.name === "",
            invalidEmail: this.state.email === "",
            invalidCountryCode: this.state.countryCode === "",
            invalidPhoneNumber: this.state.phoneNumber === "",
            invalidLocation: this.state.location === "",
            invalidAddress: this.state.address === "",
            invalidCity: this.state.city === "",
          });
        }
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <div class="new-vendor-container">
          <div class="new-vendor-body">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
                goBack={"/dashboard/payment/vendors"}
              ></GoBackButton>
            </div>
            <div class="middle-container">
              {this.state.stage === 1 ? (
                <div class="vendor-form-div">
                  <div class="main-title">
                    {this.props.localizedText["new-vendor"]}
                  </div>
                  {this.state.loadingCountries ? (
                    <Spinner className="loader"></Spinner>
                  ) : (
                    <div className="fade-in-up">
                      <div class="div-parent-flex-column">
                        <Form.Group className="mb-3">
                          <Form.Label className="input-label-inside-div">
                            {this.props.localizedText["name"]}
                          </Form.Label>
                          <FormControl
                            type="text"
                            name="name"
                            value={this.state.name}
                            onChange={(e) =>
                              utils.handleChange(e, this.setState.bind(this))
                            }
                            isInvalid={this.state.invalidName}
                            className="input-field"
                            disabled={this.state.loadingSent}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label className="input-label-inside-div">
                            {this.props.localizedText["email"]}
                          </Form.Label>
                          <FormControl
                            name="email"
                            value={this.state.email}
                            onChange={(e) =>
                              utils.handleChange(e, this.setState.bind(this))
                            }
                            isInvalid={this.state.invalidEmail}
                            className="input-field"
                            disabled={this.state.loadingSent}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label className="input-label-inside-div">
                            {this.props.localizedText["phone-number"]}
                          </Form.Label>
                          <InputGroup className="input-field">
                            <Form.Select
                              variant="outline-secondary"
                              onChange={(e) =>
                                utils.handleChange(e, this.setState.bind(this))
                              }
                              name="countryCode"
                              isInvalid={this.state.invalidCountryCode}
                              style={{
                                width: "7rem",
                                flex: "0 1 auto",
                                textAlign: "center",
                              }}
                              disabled={this.state.loadingSent}
                              value={this.state.countryCode}
                            >
                              <option></option>
                              {this.state.listAreaCodes.map((country) => (
                                <option value={country.gec}>
                                  {country.phone_area_code}
                                </option>
                              ))}
                            </Form.Select>
                            <Form.Control
                              type="text"
                              name="phoneNumber"
                              value={this.state.phoneNumber}
                              onChange={(e) =>
                                utils.handleChange(e, this.setState.bind(this))
                              }
                              isInvalid={this.state.invalidPhoneNumber}
                              className="input-field"
                            />
                          </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label className="input-label-inside-div">
                            {this.props.localizedText["location"]}
                          </Form.Label>
                          <InputGroup className="input-field">
                            <Form.Select
                              variant="outline-secondary"
                              onChange={(e) =>
                                utils.handleChange(e, this.setState.bind(this))
                              }
                              name="location"
                              isInvalid={this.state.invalidLocation}
                              style={{
                                width: "100%",
                                flex: "0 1 auto",
                                textAlign: "start",
                              }}
                              disabled={this.state.loadingSent}
                            >
                              <option></option>
                              {this.state.listCountries.map((country) => (
                                <option value={country.gec}>
                                  {country.country}
                                </option>
                              ))}
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label className="input-label-inside-div">
                            {this.props.localizedText["address"]}
                          </Form.Label>
                          <FormControl
                            name="address"
                            value={this.state.address}
                            onChange={(e) =>
                              utils.handleChange(e, this.setState.bind(this))
                            }
                            isInvalid={this.state.invalidAddress}
                            className="input-field"
                            disabled={this.state.loadingSent}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label className="input-label-inside-div">
                            {this.props.localizedText["city"]}
                          </Form.Label>
                          <FormControl
                            name="city"
                            value={this.state.city}
                            onChange={(e) =>
                              utils.handleChange(e, this.setState.bind(this))
                            }
                            isInvalid={this.state.invalidCity}
                            className="input-field"
                            disabled={this.state.loadingSent}
                          />
                        </Form.Group>
                      </div>
                      <ErrorMessageComponent
                        errorMessage={
                          this.props.localizedText[this.state.errorMessageType]
                        }
                      ></ErrorMessageComponent>
                      <div class="buttons-div">
                        <Button
                          className="primary-button outside-primary-button"
                          onClick={this.createVendor}
                          disabled={this.state.loadingSent}
                        >
                          {this.state.loadingSent ? (
                            <Spinner className="loader-send-outside-div-button"></Spinner>
                          ) : null}
                          {this.props.localizedText["create"]}
                        </Button>

                        <Button
                          className="secondary-button outside-secondary-button"
                          onClick={this.goToURL.bind(
                            this,
                            "/dashboard/payment/vendors"
                          )}
                        >
                          {this.props.localizedText["cancel"]}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <Result
                  success={true}
                  secondButton={true}
                  title={this.props.localizedText["great-news!"]}
                  message={
                    this.props.localizedText[
                      "vendor-has-been-successfully-created"
                    ]
                  }
                  primaryButtonText={
                    this.props.localizedText["add-payment-method"]
                  }
                  primaryButtonFunction={this.goToAddPaymentMethod}
                  secondaryButtonText={
                    this.props.localizedText["go-to-vendors"]
                  }
                  secondaryButtonFunction={this.goToURL.bind(
                    this,
                    "/dashboard/payment/vendors"
                  )}
                ></Result>
              )}
            </div>
            <div class="side-container"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(NewVendor);
