import { loginAPI } from "../../apis/loginAPI";

export default class DashboardService {
  getClientConfig(setState, firstLoad) {
    loginAPI
      .getClientConfig()
      .then((response) => {
        if (response.status === 200 && response.data) {
          setState({
            phoneVerified: response.data.phone_validated,
            emailVerified: response.data.email_validated,
            idVerified: response.data.kyc_completed,

            showVerifyModal:
              firstLoad &&
              (!response.data.kyc_completed ||
                !response.data.email_validated ||
                !response.data.phone_validated),

            showPaymentInfoModal:
              firstLoad &&
              response.data.kyc_completed &&
              response.data.email_validated &&
              response.data.phone_validated,
          });
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
      });
  }
}
