import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class BloxTerms extends Component {
  constructor(props) {
    super(props);
  }

  staticHtml = require("./blox-terms-english.js");

  render() {
    return (
      <React.Fragment>
        <div
          style={{ background: "white", padding: "1rem" }}
          dangerouslySetInnerHTML={{ __html: this.staticHtml }}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(BloxTerms);
