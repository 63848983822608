import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./depositCrypto.css";
import DepositCryptoService from "./depositCryptoService.jsx";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Spinner } from "react-bootstrap";
import { utils } from "../../../utils/utils.jsx";
import { AiFillCopy } from "react-icons/ai";
import { FaCheck } from "react-icons/fa6";
import ConversionLinkComponent from "../../conversionLink/conversionLink.jsx";
import ErrorMessageComponent from "../../errorMessage/errorMessage.jsx";

class Deposit extends Component {
  state = {
    width: window.innerWidth,

    listNetworks: [],

    errorMessageType: "",

    crypto: "",
    network: "",
    amount: "",
    conversion: "",
    fundsToRecieve: "",
    note: "",

    qrcodeBase64: "",
    inboundAddress: "",
    inboundAddressCopied: false,

    loadingConversion: false,
    loadingSent: false,
    loadingNetworks: false,
    loadingWallet: false,

    invalidCurrency: false,
    invalidAmount: false,

    resultSuccess: true,

    stage: 1,
  };

  service = new DepositCryptoService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      this.props.tryToGoBackStage
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        this.props.tryToGoBackStage
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {}

  goToURL = (url) => {
    this.props.history.push({ pathname: url, megastate: { from: true } });
  };

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  handleChangeForCrypto = (event, setState) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (this.state.crypto != value) {
      if (value != "" && value != null) {
        this.setState(
          {
            [name]: value,
          },
          () => {
            this.props.setCrypto(this.state.crypto);
            this.setState({
              loadingNetworks: true,
              loadingWallet: false,
              qrcodeBase64: "",
            });
            this.service.getNetworks(
              this.state.crypto,
              this.setState.bind(this)
            );
            this.service.getConversion(
              1,
              this.state.crypto,
              "USD",
              this.setState.bind(this),
              this.setMessages.bind(this)
            );
          }
        );
      } else {
        if (value == "") {
          this.setState({
            network: "",
            loadingNetworks: false,
            loadingWallet: false,
            qrcodeBase64: "",
          });
        }

        this.setState({
          [name]: value,
        });
      }
    }
  };

  handleChangeForNetwork = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (this.state.network != value) {
      if (value != "" && value != null) {
        this.setState(
          {
            [name]: value,
            qrcodeBase64: "",
            loadingWallet: false,
          },
          () => {
            this.setState(
              {
                qrcodeBase64: "",
                loadingWallet: true,
              },
              () => {
                console.log("THIS CRYPTO", this.state.crypto);
                console.log("THIS NETWORK", this.state.network);
                this.service.getWallet(
                  this.state.crypto,
                  this.state.network,
                  this.setState.bind(this)
                );
              }
            );
          }
        );
      } else {
        if (value == "") {
          this.setState({
            qrcodeBase64: "",
            loadingWallet: false,
          });
        }

        this.setState({
          [name]: value,
        });
      }
    }
  };

  goToResultStage = () => {};

  copyText = () => {
    this.setState({
      inboundAddressCopied: true,
    });
    navigator.clipboard.writeText(this.state.inboundAddress);
  };

  render() {
    return (
      <React.Fragment>
        <div class=" deposit-crypto-div section">
          <ConversionLinkComponent
            width={this.state.width}
            currencyFrom={this.state.crypto}
            currencyTo={"USD"}
            region={this.props.region}
            localizedText={this.props.localizedText}
          />
          <div class="left-input">
            <div class="background"></div>
            <div class="content">
              <div class="form-div">
                <Form.Group className="mb-3">
                  <Form.Label className={"input-label-inside-div"}>
                    {
                      this.props.localizedText[
                        "which-coin-would-you-like-to-deposit-in?"
                      ]
                    }
                  </Form.Label>
                  <InputGroup className="mb-3 input-field">
                    <Form.Select
                      variant="outline-secondary"
                      onChange={(e) =>
                        this.handleChangeForCrypto(e, this.setState.bind(this))
                      }
                      name="crypto"
                      style={{
                        width: "100%",
                        flex: "0 1 auto",
                        textAlign: "start",
                      }}
                      disabled={this.state.loadingSent}
                    >
                      <option></option>
                      {this.props.listCryptos.map((crypto) => (
                        <option value={crypto.base_currency}>
                          {crypto.base_currency + " (" + crypto.name + ")"}
                        </option>
                      ))}
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
              <div class="message-div">
                {
                  this.props.localizedText[
                    "*please-scan-the-qr-code-with-your-wallet-app-or-copy-the-address-below"
                  ]
                }
              </div>
            </div>
          </div>{" "}
          <div class="right-div">
            <div class="value-input">
              {this.state.crypto != "" ? (
                <div>
                  {this.state.loadingNetworks ? (
                    <div class="fade-in-up">
                      <Spinner className="loading-conversions" />
                    </div>
                  ) : (
                    <Form.Group className="mb-3 fade-in-up">
                      <Form.Label className={"input-label-outside-div"}>
                        {
                          this.props.localizedText[
                            "which-blockchain-would-you-like-to-use?"
                          ]
                        }
                      </Form.Label>
                      <InputGroup className="mb-3 input-field">
                        <Form.Select
                          variant="outline-secondary"
                          onChange={(e) => this.handleChangeForNetwork(e)}
                          name="network"
                          style={{
                            width: "100%",
                            flex: "0 1 auto",
                            textAlign: "start",
                          }}
                          disabled={
                            this.state.loadingSent ||
                            this.state.listNetworks.length <= 1
                          }
                        >
                          {this.state.listNetworks.length == 1 ? (
                            <option>
                              {this.state.listNetworks[0].description}
                            </option>
                          ) : null}
                          <option></option>
                          {this.state.listNetworks.map((network) => (
                            <option value={network.blockchain}>
                              {network.description}
                            </option>
                          ))}
                        </Form.Select>
                      </InputGroup>
                    </Form.Group>
                  )}
                </div>
              ) : null}
              {this.state.loadingWallet ? (
                <div class="fade-in-up">
                  <Spinner className="loading-conversions" />
                </div>
              ) : null}
              {!this.state.loadingWallet && this.state.qrcodeBase64 != "" ? (
                <div class="fade-in-up">
                  <img
                    className="qrcode"
                    src={`data:image/png;base64, ${this.state.qrcodeBase64}`}
                  />
                  <div class="key-div" onClick={this.copyText}>
                    <div class="key">{this.state.inboundAddress}</div>
                    {this.state.inboundAddressCopied ? (
                      <FaCheck class="copy-success-icon" />
                    ) : (
                      <AiFillCopy class="copy-key-icon" />
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <ErrorMessageComponent
            errorMessage={this.props.localizedText[this.state.errorMessageType]}
          ></ErrorMessageComponent>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Deposit);
