import { portfolioAPI } from "../../apis/portfolioAPI";
import { marketDataAPI } from "../../apis/marketDataAPI";
import { swapAPI } from "../../apis/swapAPI.jsx";
import { utils } from "../../utils/utils";

export default class AssetDetailService {
  getDefaultPortfolio(setState, currency) {
    portfolioAPI
      .getDefaultPortfolio()
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getDefaultPortfolio success", response);
          this.getPortfolioBalances(setState, response.data.name, currency);
          this.getTransactionHistoryForSymbol(
            setState,
            response.data.name,
            currency
          );
        } else {
          console.log("getDefaultPortfolio failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("getDefaultPortfolio error", error);
      });
  }

  getPortfolioBalances(setState, portfolioName, currency) {
    portfolioAPI
      .getPortfolioBalances(portfolioName)
      .then((response) => {
        if (response.status === 200) {
          console.log("getPortfolioBalances success", response);
          let currencyData = response.data.filter(
            (element) => element.base_currency == currency
          )[0];
          console.log(
            "filter",
            response.data.filter((element) => element.base_currency == currency)
          );
          if (currencyData.base_currency != "USD") {
            swapAPI
              .convertEstimate(
                currencyData.qty,
                currencyData.base_currency,
                "USD",
                0
              )
              .then((responseConvert) => {
                if (responseConvert.status === 200) {
                  console.log("convertEstimate success", responseConvert);
                  currencyData["value-usd"] =
                    responseConvert.data.amount_to_receive;
                  console.log("currencyData", currencyData);
                  setState({
                    currencyData: currencyData,
                    loadingCurrencyData: false,
                  });
                } else {
                  console.log("convertEstimate failure", responseConvert);
                  //Failure
                }
              })
              .catch((error) => {
                //Failure
                console.log("convertEstimate error", error);
              });
          } else {
            currencyData["value-usd"] = currencyData.qty;
            console.log("currencyData", currencyData);
            setState({
              currencyData: currencyData,
              loadingCurrencyData: false,
            });
          }
        } else {
          console.log("getPortfolioBalances failure", response);
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log("getPortfolioBalances error", error);
      });
  }

  getHistoricalCandles(setState, currency, granularity, start, period, index) {
    marketDataAPI
      .getHistoricalCandles(
        currency + "-USD",
        granularity,
        start,
        period, //Multiplying days to get in seconds
        Date.now() / 1000
      )
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getHistoricalCandles success", response);

          setState({
            chartIndexPicked: index,
            chartData:
              response.data &&
              response.data["payload"] &&
              Array.isArray(JSON.parse(response.data["payload"]))
                ? JSON.parse(response.data["payload"]).reverse()
                : null,
            loadingHistoricalCandles: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("getHistoricalCandles error", error);
      });
  }

  getTransactionHistoryForSymbol(setState, portfolioName, currency) {
    portfolioAPI
      .getTransactionHistoryForSymbol(portfolioName, currency)
      .then((response) => {
        if (response.status === 200) {
          console.log("getTransactionHistoryForSymbol success", response);
          setState({
            listTransactions: response.data,
            listTransactionsShowed: response.data,
            loadingTransactions: false,
          });
        } else {
          console.log("getTransactionHistoryForSymbol failure", response);
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log("getTransactionHistoryForSymbol error", error);
      });
  }
}
