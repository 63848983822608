import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import { withRouter } from "react-router-dom";
import "./register.css";
import RegisterService from "./registerService";
import Footer from "../../components/footer/footer";
import InputGroup from "react-bootstrap/InputGroup";
import ThemeRegionDiv from "../../components/theme-region-div/themeRegionDiv";
import Spinner from "react-bootstrap/Spinner";
import { utils } from "../../utils/utils.jsx";
import ErrorMessageComponent from "../../components/errorMessage/errorMessage.jsx";
import { Link } from "react-router-dom";

class Register extends Component {
  state = {
    isMobileWidth: false,
    fName: process.env.REACT_APP_ENV != "prod" ? "Luis" : "",
    lName: process.env.REACT_APP_ENV != "prod" ? "Kcomt" : "",
    email: process.env.REACT_APP_ENV != "prod" ? "lkcomt@bloxcross.com" : "",
    countryCode: process.env.REACT_APP_ENV != "prod" ? "+51" : "",
    phoneNumber: process.env.REACT_APP_ENV != "prod" ? "920547923" : "",
    password: process.env.REACT_APP_ENV != "prod" ? "Trying24@" : "",
    retypePassword: process.env.REACT_APP_ENV != "prod" ? "Trying24@" : "",
    domicile: process.env.REACT_APP_ENV != "prod" ? "PE" : "",
    language: process.env.REACT_APP_ENV != "prod" ? "eng" : "",
    preferredCurrency: process.env.REACT_APP_ENV != "prod" ? "COP" : "",
    refCode: process.env.REACT_APP_REF_CODE,

    errorMessageType: "",

    loadingCountries: true,
    loadingCurrencies: true,
    loadingSent: false,

    invalidFName: false,
    invalidLName: false,
    invalidEmail: false,
    invalidCountryCode: false,
    invalidPhoneNumber: false,
    invalidPassword: false,
    invalidRetypePassword: false,
    invalidDomicile: false,
    invalidLanguage: false,
    invalidPreferredCurrency: false,

    listCountries: [],
    listAreaCodes: [],

    listLanguages: [
      {
        alpha3_b: "eng",
        alpha3_t: null,
        alpha2: "en",
        english: "English",
        french: "anglais",
        german: "Englisch",
        notes: null,
      },
      {
        alpha3_b: "spa",
        alpha3_t: null,
        alpha2: "es",
        english: "Spanish; Castilian",
        french: "espagnol; castillan",
        german: "Spanisch",
        notes: null,
      },
    ],
    listCurrencies: [],
  };

  service = new RegisterService();
  emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  passwordRegex = new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
  );

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.service.getCountries(this.setState.bind(this));
    this.service.getCurrencies(this.setState.bind(this));
    this.checkWindowWidth();
    window.addEventListener("resize", this.checkWindowWidth);
  }

  componentDidUpdate(prevProps, prevState) {}

  checkWindowWidth = () => {
    this.windowSize = window.innerWidth;
    let flag = true;

    if (this.windowSize <= 1200) {
      flag = true;
    } else {
      flag = false;
    }

    this.setState({
      isMobileWidth: flag,
    });
  };

  handlePasswordChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let invalidVar = null;

    if (name == "password") {
      invalidVar = "invalidPassword";
    }
    if (name == "retypePassword") {
      invalidVar = "invalidRetypePassword";
    }

    this.setState(
      {
        [name]: value,
        [invalidVar]: !this.passwordRegex.test(value),
      },
      () => {
        if (!this.passwordRegex.test(value)) {
          this.setMessages(
            "*password-must-be-minimum-8-digits-and-contain-at-least-one-upper-and-lowercase-letter,-a-number,-and-special-character"
          );
        } else {
          this.setState({
            errorMessageType: "",
          });
        }
      }
    );
  };

  handleCountryChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
      },
      () => {
        //filter areaCodes
        if (
          this.state.listAreaCodes.filter(
            (country) => country.gec === this.state.domicile
          ).length > 0
        ) {
          this.setState({
            countryCode: this.state.listAreaCodes.filter(
              (country) => country.gec === this.state.domicile
            )[0].phone_area_code,
          });
        }

        //filter currencies
        const selectedCountryCurrency = this.state.listCountries.filter(
          (country) => country.gec === this.state.domicile
        )[0].currency_id;
        if (
          this.state.listCurrencies.filter(
            (currency) => currency.id === selectedCountryCurrency
          ).length > 0
        ) {
          this.setState({
            preferredCurrency: this.state.listCurrencies.filter(
              (currency) => currency.id === selectedCountryCurrency
            )[0].id,
          });
        }
      }
    );
  };

  goToResultPage = (value) => {
    if (value) {
      this.props.history.push({
        pathname: "/result",
        megastate: {
          success: value,
          type: "register",
          primaryButtonFunction: this.goToURL.bind(this, "/"),
          secondButton: false,
        },
      });
    } else {
      this.props.history.push({
        pathname: "/result",
        megastate: {
          success: value,
          type: "register",
          primaryButtonFunction: this.goToURL.bind(this, "/"),
          secondButton: false,
        },
      });
    }
  };

  goToURL = (url) => {
    this.props.history.push({ pathname: url, megastate: { from: true } });
  };

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  doRegister = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (
          this.state.fName != "" &&
          this.state.lName != "" &&
          this.state.email != "" &&
          this.state.countryCode != "" &&
          this.state.phoneNumber != "" &&
          this.state.password != "" &&
          this.state.retypePassword != "" &&
          this.state.domicile != "" &&
          this.state.language != "" &&
          this.state.preferredCurrency != ""
        ) {
          if (this.state.password === this.state.retypePassword) {
            if (this.emailRegex.test(this.state.email)) {
              this.setState(
                {
                  invalidFName: false,
                  invalidLName: false,
                  invalidEmail: false,
                  invalidCountryCode: false,
                  invalidPhoneNumber: false,
                  invalidPassword: false,
                  invalidRetypePassword: false,
                  invalidDomicile: false,
                  invalidLanguage: false,
                  invalidPreferredCurrency: false,
                  loadingSent: true,
                },
                () => {
                  this.service.registerAccount(
                    this.state,
                    this.setState.bind(this),
                    this.goToResultPage,
                    this.setMessages.bind(this)
                  );
                }
              );
            } else {
              this.setMessages("*please-enter-a-valid-email");
              this.setState({
                invalidEmail: true,
              });
            }
          } else {
            this.setMessages("*passwords-don't-match");
          }
        } else {
          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidFName: this.state.fName === "",
            invalidLName: this.state.lName === "",
            invalidEmail:
              this.state.email === "" ||
              !this.emailRegex.test(this.state.email),
            invalidCountryCode: this.state.countryCode === "",
            invalidPhoneNumber: this.state.phoneNumber === "",
            invalidPassword: this.state.password === "",
            invalidRetypePassword: this.state.retypePassword === "",
            invalidDomicile: this.state.domicile === "",
            invalidLanguage: this.state.language === "",
            invalidPreferredCurrency: this.state.preferredCurrency === "",
          });
        }
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <div class="register-container">
          <div class="middle-center-2">
            <Link to="/">
              <img
                class="register-image"
                src={"/themes/" + this.props.theme + "/logo.png"}
              ></img>
            </Link>
            <div class="register-title">
              {this.props.localizedText["register"]}
            </div>
            <div class="register-form-div">
              <div class="div-parent-flex-row">
                <div class="div-50-son">
                  <Form.Group className="mb-3">
                    <Form.Label
                      className={
                        this.state.isMobileWidth
                          ? "input-label-outside-div"
                          : "input-label-inside-div"
                      }
                    >
                      {this.props.localizedText["first-name"]}
                    </Form.Label>
                    <FormControl
                      type="text"
                      name="fName"
                      value={this.state.fName}
                      onChange={(e) =>
                        utils.handleChange(e, this.setState.bind(this))
                      }
                      isInvalid={this.state.invalidFName}
                      className="input-field"
                      disabled={this.state.loadingSent}
                    />
                  </Form.Group>
                </div>
                <div class="div-50-son">
                  <Form.Group className="mb-3">
                    <Form.Label
                      className={
                        this.state.isMobileWidth
                          ? "input-label-outside-div"
                          : "input-label-inside-div"
                      }
                    >
                      {this.props.localizedText["last-name"]}
                    </Form.Label>
                    <FormControl
                      type="text"
                      name="lName"
                      value={this.state.lName}
                      onChange={(e) =>
                        utils.handleChange(e, this.setState.bind(this))
                      }
                      isInvalid={this.state.invalidLName}
                      className="input-field"
                      disabled={this.state.loadingSent}
                    />
                  </Form.Group>
                </div>
              </div>

              <div class="div-parent-flex-row">
                <div class="div-width-100">
                  <Form.Group className="mb-3">
                    <Form.Label
                      className={
                        this.state.isMobileWidth
                          ? "input-label-outside-div"
                          : "input-label-inside-div"
                      }
                    >
                      {this.props.localizedText["email"]}
                    </Form.Label>
                    <FormControl
                      type="email"
                      name="email"
                      value={this.state.email}
                      onChange={(e) =>
                        utils.handleChange(e, this.setState.bind(this))
                      }
                      isInvalid={this.state.invalidEmail}
                      className="input-field"
                      disabled={this.state.loadingSent}
                    />
                  </Form.Group>
                </div>
              </div>
              <div class="div-parent-flex-row">
                <div class="div-50-son">
                  <Form.Group className="mb-3">
                    <Form.Label
                      className={
                        this.state.isMobileWidth
                          ? "input-label-outside-div"
                          : "input-label-inside-div"
                      }
                    >
                      {this.props.localizedText["password"]}
                    </Form.Label>
                    <FormControl
                      type="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handlePasswordChange}
                      isInvalid={this.state.invalidPassword}
                      className="input-field"
                      disabled={this.state.loadingSent}
                    />
                  </Form.Group>
                </div>

                <div class="div-50-son">
                  <Form.Group className="mb-3">
                    <Form.Label
                      className={
                        this.state.isMobileWidth
                          ? "input-label-outside-div"
                          : "input-label-inside-div"
                      }
                    >
                      {this.props.localizedText["retype-password"]}
                    </Form.Label>
                    <FormControl
                      type="password"
                      name="retypePassword"
                      value={this.state.retypePassword}
                      onChange={this.handlePasswordChange}
                      isInvalid={this.state.invalidRetypePassword}
                      className="input-field"
                      disabled={this.state.loadingSent}
                    />
                  </Form.Group>
                </div>
              </div>
              <div class="div-parent-flex-row">
                <div class="div-50-son">
                  <Form.Group className="mb-3 form-group">
                    <Form.Label
                      className={
                        this.state.isMobileWidth
                          ? "input-label-outside-div"
                          : "input-label-inside-div"
                      }
                    >
                      {this.props.localizedText["country"]}
                    </Form.Label>
                    <InputGroup className="mb-3 input-field">
                      <Form.Select
                        variant="outline-secondary"
                        onChange={this.handleCountryChange}
                        name="domicile"
                        isInvalid={this.state.invalidDomicile}
                        style={{
                          width: "100%",
                          flex: "0 1 auto",
                          textAlign: "start",
                        }}
                        disabled={this.state.loadingSent}
                        value={this.state.domicile}
                      >
                        <option></option>
                        {this.state.listCountries.map((country) => (
                          <option value={country.iso_3166_2}>
                            {country.country}
                          </option>
                        ))}
                      </Form.Select>
                    </InputGroup>
                  </Form.Group>
                </div>

                <div class="div-50-son">
                  <Form.Group className="mb-3">
                    <Form.Label
                      className={
                        this.state.isMobileWidth
                          ? "input-label-outside-div"
                          : "input-label-inside-div"
                      }
                    >
                      {this.props.localizedText["phone-number"]}
                    </Form.Label>
                    <InputGroup className="mb-3 input-field">
                      <Form.Select
                        variant="outline-secondary"
                        onChange={(e) =>
                          utils.handleChange(e, this.setState.bind(this))
                        }
                        name="countryCode"
                        isInvalid={this.state.invalidCountryCode}
                        style={{
                          width: "5rem",
                          flex: "0 1 auto",
                          textAlign: "center",
                        }}
                        disabled={this.state.loadingSent}
                        value={this.state.countryCode}
                      >
                        <option></option>
                        {this.state.listAreaCodes.map((country) => (
                          <option value={country.phone_area_code}>
                            {country.phone_area_code}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control
                        type="tel"
                        name="phoneNumber"
                        value={this.state.phoneNumber}
                        onChange={(e) =>
                          utils.handleChange(e, this.setState.bind(this))
                        }
                        isInvalid={this.state.invalidPhoneNumber}
                        className="input-field"
                        disabled={this.state.loadingSent}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
              </div>
              <div class="div-parent-flex-row">
                <div class="div-50-son">
                  <Form.Group className="mb-3 form-group">
                    <Form.Label
                      className={
                        this.state.isMobileWidth
                          ? "input-label-outside-div"
                          : "input-label-inside-div"
                      }
                    >
                      {this.props.localizedText["language"]}
                    </Form.Label>
                    <InputGroup className="mb-3 input-field">
                      <Form.Select
                        variant="outline-secondary"
                        onChange={(e) =>
                          utils.handleChange(e, this.setState.bind(this))
                        }
                        name="language"
                        isInvalid={this.state.invalidLanguage}
                        style={{
                          width: "100%",
                          flex: "0 1 auto",
                          textAlign: "start",
                        }}
                        disabled={this.state.loadingSent}
                        value={this.state.language}
                      >
                        <option></option>
                        {this.state.listLanguages.map((language) => (
                          <option value={language.alpha3_b}>
                            {language.english}
                          </option>
                        ))}
                      </Form.Select>
                    </InputGroup>
                  </Form.Group>
                </div>

                <div class="div-50-son">
                  <Form.Group className="mb-3 form-group">
                    <Form.Label
                      className={
                        this.state.isMobileWidth
                          ? "input-label-outside-div"
                          : "input-label-inside-div"
                      }
                    >
                      {this.props.localizedText["preferred-currency"]}
                    </Form.Label>
                    <InputGroup className="mb-3 input-field">
                      <Form.Select
                        variant="outline-secondary"
                        onChange={(e) =>
                          utils.handleChange(e, this.setState.bind(this))
                        }
                        name="preferredCurrency"
                        isInvalid={this.state.invalidPreferredCurrency}
                        style={{
                          width: "100%",
                          flex: "0 1 auto",
                          textAlign: "start",
                        }}
                        disabled={this.state.loadingSent}
                        value={this.state.preferredCurrency}
                      >
                        <option></option>
                        {this.state.listCurrencies.map((currency) => (
                          <option value={currency.id}>
                            {currency.name + " (" + currency.id + ")"}
                          </option>
                        ))}
                      </Form.Select>
                    </InputGroup>
                  </Form.Group>
                </div>
              </div>
            </div>

            <ErrorMessageComponent
              errorMessage={
                this.props.localizedText[this.state.errorMessageType]
              }
            ></ErrorMessageComponent>
            <div class="buttons-div">
              <Button
                className={
                  this.state.isMobileWidth
                    ? "primary-button outside-primary-button"
                    : "primary-button inside-primary-button"
                }
                onClick={this.doRegister}
                disabled={
                  this.state.loadingSent ||
                  this.state.invalidPassword ||
                  this.state.invalidRetypePassword
                }
              >
                {this.state.loadingSent ? (
                  <Spinner
                    className={
                      this.state.isMobileWidth
                        ? "loader-send-outside-div-button"
                        : "loader-send-inside-div-button"
                    }
                  ></Spinner>
                ) : null}
                {this.props.localizedText["continue"]}
              </Button>
              <Button
                className={
                  this.state.isMobileWidth
                    ? "secondary-button outside-secondary-button"
                    : "secondary-button inside-secondary-button"
                }
                onClick={this.goToURL.bind(this, "/")}
                disabled={this.state.loadingSent}
              >
                {this.props.localizedText["back"]}
              </Button>
            </div>
          </div>

          <ThemeRegionDiv
            changeTheme={this.props.changeTheme}
            theme={this.props.theme}
            handleModalOpen={this.props.handleModalOpen}
            changeRegion={this.props.changeRegion} //localization change
            region={this.props.region}
            localizedText={this.props.localizedText}
          ></ThemeRegionDiv>
        </div>
        <Footer
          region={this.props.region}
          localizedText={this.props.localizedText}
          theme={this.props.theme}
        ></Footer>
      </React.Fragment>
    );
  }
}

export default withRouter(Register);
