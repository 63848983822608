import { refdataAPI } from "../../../apis/refdataAPI";
import { vendorsAPI } from "../../../apis/vendorsAPI";
import { utils } from "../../../utils/utils";

export default class UpdateVendorService {
  updateVendor(state, id, setState, setMessages) {
    vendorsAPI
      .updateVendor(state, id)
      .then((response) => {
        console.log("createVendor", response);
        if (response.data.code === 0) {
          setState(
            {
              loadingSent: false,
              newVendorId: response.data.payload,
            },
            () => {
              setState({
                stage: 2,
              });
            }
          );
        } else {
          setMessages(
            "*an-error-has-ocurred-while-trying-to-create-the-vendor"
          );
          setState({
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        setMessages("*an-error-has-ocurred-while-trying-to-create-the-vendor");
        setState({
          loadingSent: false,
        });
      });
  }

  getAllCountries(setState, loadData) {
    refdataAPI
      .getAllCountries()
      .then((response) => {
        console.log("getCountries", response);
        if (response.status === 200 && response.data) {
          setState(
            {
              listCountries: utils.orderAlphabetically(
                response.data.filter((country) => country.phone_area_code),
                "country"
              ),
              listAreaCodes: utils.removeObjectDuplicates(
                response.data
                  .filter((country) => country.phone_area_code)
                  .sort((a, b) => {
                    const phone_area_codeA = a.phone_area_code;
                    const phone_area_codeB = b.phone_area_code;
                    if (phone_area_codeA < phone_area_codeB) {
                      return -1;
                    }
                    if (phone_area_codeA > phone_area_codeB) {
                      return 1;
                    }
                    return 0;
                  }),
                "phone_area_code"
              ),
              loadingCountries: false,
            },
            () => {
              loadData();
            }
          );
        } else {
        }
      })
      .catch((error) => {
        //Failure
      });
  }
}
