import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import { withRouter } from "react-router-dom";
import "./layout.css";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import Offcanvas from "react-bootstrap/Offcanvas";
import { MdNightlight, MdSunny } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { CgClose } from "react-icons/cg";
import { FiGlobe } from "react-icons/fi";
import { BiLogOut } from "react-icons/bi";
import { IoMdSettings } from "react-icons/io";
import { HiIdentification } from "react-icons/hi";
import GoBackButton from "../../components/go-back-button/goBackButton";
import ThemeRegionDiv from "../../components/theme-region-div/themeRegionDiv";
import LayoutService from "./layoutService";
import { Link } from "react-router-dom";

class Layout extends Component {
  state = {
    name: null,
    account: null,
    isMobileWidth: false,
    showThemeRegion: true,
    flagSideBar: false,
    isSticky: false,
    shouldGoBack: false,
  };

  service = new LayoutService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.checkWindowWidth();

    window.addEventListener("resize", this.checkWindowWidth);
    window.addEventListener("scroll", this.changeNavbarColor);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.account) {
        this.setState({
          name: this.props.account.username,
        });
      }
    }
  }

  changeNavbarColor = () => {
    if (window.scrollY >= 60) {
      this.setState({
        isSticky: true,
      });
    } else {
      this.setState({
        isSticky: false,
      });
    }
  };

  checkWindowWidth = () => {
    this.windowSize = window.innerWidth;
    let flag = true;

    if (this.windowSize <= 1200) {
      flag = true;
    } else {
      flag = false;
    }

    if (this.windowSize <= 1600) {
      this.setState({
        showThemeRegion: false,
      });
    } else {
      this.setState({
        showThemeRegion: true,
      });
    }

    this.setState({
      isMobileWidth: flag,
    });
  };

  logout = () => {
    this.service.logout(this.goToURL.bind(this));
  };

  goToURL = (url) => {
    this.props.history.push({ pathname: url, megastate: { from: true } });
  };

  sideBarGoToUrl = (url) => {
    this.setState(
      {
        flagSideBar: false,
      },
      () => {
        this.props.history.push({ pathname: url, megastate: { from: true } });
      }
    );
  };

  toggleSideBar = (value) => {
    this.setState({
      flagSideBar: value,
    });
  };

  toggleTheme = (theme) => {
    this.setState({
      flagSideBar: false,
    });
    this.props.changeTheme(theme);
  };

  toggleRegion = (region) => {
    this.setState({
      flagSideBar: false,
    });
    this.props.handleModalOpen();
  };

  render() {
    return (
      <React.Fragment>
        <Navbar
          variant="dark"
          className={
            this.state.isSticky ? "navbar-black nav-bar-sticky" : "navbar-black"
          }
          expand={!this.state.isMobileWidth}
        >
          <Container>
            {!this.state.isMobileWidth ? (
              <img
                class="navbar-img"
                onClick={this.goToURL.bind(this, "/dashboard")}
                src={"/themes/" + this.props.theme + "/logo.png"}
              ></img>
            ) : (
              <div style={{ width: "35px" }}>
                {!this.props.goBackNavBar ? (
                  <Link to="/dashboard">
                    <img
                      class="navbar-img"
                      src={"/themes/" + this.props.theme + "/logo.png"}
                    ></img>
                  </Link>
                ) : (
                  <GoBackButton
                    flagFullText={true}
                    goBack={this.props.goBackUrl}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                    isSticky={this.state.isSticky}
                  ></GoBackButton>
                )}
              </div>
            )}

            <Link to="/dashboard" class="nav-title">
              <Navbar.Brand
                className={this.state.isSticky ? "nav-text-sticky" : ""}
              >
                {process.env.REACT_APP_TITLE}
              </Navbar.Brand>
            </Link>

            {!this.state.isMobileWidth ? (
              <Nav className="justify-content-start flex-grow-1 pe-3">
                <Link
                  to="/dashboard/payment-options"
                  className={
                    this.state.isSticky
                      ? window.location.pathname ===
                        "/dashboard/payment-options"
                        ? "nav-text nav-text-sticky nav-text-picked"
                        : "nav-text nav-text-sticky"
                      : window.location.pathname ===
                        "/dashboard/payment-options"
                      ? "nav-text nav-text-picked"
                      : "nav-text"
                  }
                >
                  {this.props.localizedText["pay"]}
                </Link>

                <Link
                  to="/dashboard/portfolio/assets"
                  className={
                    this.state.isSticky
                      ? window.location.pathname ===
                        "/dashboard/portfolio/assets"
                        ? "nav-text nav-text-sticky nav-text-picked"
                        : "nav-text nav-text-sticky"
                      : window.location.pathname ===
                        "/dashboard/portfolio/assets"
                      ? "nav-text nav-text-picked"
                      : "nav-text"
                  }
                >
                  {this.props.localizedText["assets"]}
                </Link>

                <Link
                  to="/dashboard/deposit"
                  className={
                    this.state.isSticky
                      ? window.location.pathname === "/dashboard/deposit"
                        ? "nav-text nav-text-sticky nav-text-picked"
                        : "nav-text nav-text-sticky"
                      : window.location.pathname === "/dashboard/deposit"
                      ? "nav-text nav-text-picked"
                      : "nav-text"
                  }
                >
                  {this.props.localizedText["deposit"]}
                </Link>

                <Link
                  to="/dashboard/withdraw"
                  className={
                    this.state.isSticky
                      ? window.location.pathname === "/dashboard/withdraw"
                        ? "nav-text nav-text-sticky nav-text-picked"
                        : "nav-text nav-text-sticky"
                      : window.location.pathname === "/dashboard/withdraw"
                      ? "nav-text nav-text-picked"
                      : "nav-text"
                  }
                >
                  {this.props.localizedText["withdraw"]}
                </Link>

                <Link
                  to="/dashboard/portfolio/history"
                  className={
                    this.state.isSticky
                      ? window.location.pathname ===
                        "/dashboard/portfolio/history"
                        ? "nav-text nav-text-sticky nav-text-picked"
                        : "nav-text nav-text-sticky"
                      : window.location.pathname ===
                        "/dashboard/portfolio/history"
                      ? "nav-text nav-text-picked"
                      : "nav-text"
                  }
                >
                  {this.props.localizedText["history"]}
                </Link>

                <Link
                  to="/dashboard/conversion"
                  className={
                    this.state.isSticky
                      ? window.location.pathname === "/dashboard/conversion"
                        ? "nav-text nav-text-sticky nav-text-picked"
                        : "nav-text nav-text-sticky"
                      : window.location.pathname === "/dashboard/conversion"
                      ? "nav-text nav-text-picked"
                      : "nav-text"
                  }
                >
                  {this.props.localizedText["convert"]}
                </Link>

                <Link
                  to="/dashboard/settings"
                  className={
                    this.state.isSticky
                      ? window.location.pathname === "/dashboard/settings"
                        ? "nav-text nav-text-sticky nav-text-picked"
                        : "nav-text nav-text-sticky"
                      : window.location.pathname === "/dashboard/settings"
                      ? "nav-text nav-text-picked"
                      : "nav-text"
                  }
                >
                  {this.props.localizedText["settings"]}
                </Link>

                {process.env.REACT_APP_ENV !== "prod" ? (
                  <Link
                    to="/dashboard/hidden/deposit-dev"
                    className={
                      this.state.isSticky
                        ? window.location.pathname ===
                          "/dashboard/hidden/deposit-dev"
                          ? "nav-text nav-text-sticky nav-text-picked"
                          : "nav-text nav-text-sticky"
                        : window.location.pathname ===
                          "/dashboard/hidden/deposit-dev"
                        ? "nav-text nav-text-picked"
                        : "nav-text"
                    }
                  >
                    DepositDev
                  </Link>
                ) : null}

                <Navbar.Collapse className="justify-content-end">
                  <div class="navbar-right-div">
                    <div
                      class={
                        this.state.isSticky
                          ? "signed-in-info-div nav-text-sticky-signed"
                          : "signed-in-info-div"
                      }
                    >
                      {this.props.localizedText["signed-in-as"]}&nbsp;
                      <NavDropdown
                        id="nav-dropdown-dark-example"
                        title={this.state.name}
                        className={
                          this.state.isSticky
                            ? "dropdown-div dropdown-div-sticky"
                            : "dropdown-div"
                        }
                      >
                        {process.env.REACT_APP_THEMES == "2" ? (
                          <div>
                            {this.props.theme == "dark" ? (
                              <NavDropdown.Item
                                className="item-dropdown"
                                onClick={this.toggleTheme.bind(this, "light")}
                              >
                                <MdNightlight class="item-icon"></MdNightlight>
                                {this.props.localizedText["change-theme"]}
                              </NavDropdown.Item>
                            ) : (
                              <NavDropdown.Item
                                className="item-dropdown"
                                onClick={this.toggleTheme.bind(this, "dark")}
                              >
                                <MdSunny class="item-icon"></MdSunny>
                                {this.props.localizedText["change-theme"]}
                              </NavDropdown.Item>
                            )}
                          </div>
                        ) : null}
                        {this.props.region == "en" ? (
                          <NavDropdown.Item
                            className="item-dropdown"
                            onClick={this.toggleRegion.bind(this, "es")}
                          >
                            <FiGlobe class="item-icon" />
                            {this.props.localizedText["change-region"]}
                            &nbsp;(ES)
                          </NavDropdown.Item>
                        ) : (
                          <NavDropdown.Item
                            className="item-dropdown"
                            onClick={this.toggleRegion.bind(this, "en")}
                          >
                            <FiGlobe class="item-icon" />
                            {this.props.localizedText["change-region"]}
                            &nbsp;(EN)
                          </NavDropdown.Item>
                        )}
                        <NavDropdown.Item
                          className="item-dropdown"
                          onClick={this.goToURL.bind(
                            this,
                            "/dashboard/settings/id-verification"
                          )}
                        >
                          <HiIdentification class="item-icon" />
                          {this.props.localizedText["verify-id"]}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className="item-dropdown"
                          onClick={this.goToURL.bind(
                            this,
                            "/dashboard/settings"
                          )}
                        >
                          <IoMdSettings class="item-icon" />
                          {this.props.localizedText["settings"]}
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item
                          className="item-dropdown"
                          onClick={this.logout}
                        >
                          <BiLogOut class="item-icon" />
                          {this.props.localizedText["logout"]}
                        </NavDropdown.Item>
                      </NavDropdown>
                    </div>
                  </div>
                </Navbar.Collapse>
              </Nav>
            ) : (
              <Nav>
                <Offcanvas
                  show={this.state.flagSideBar}
                  onHide={this.toggleSideBar.bind(this, false)}
                  placement={"top"}
                  className="side-bar"
                >
                  <Offcanvas.Header className="side-bar-header">
                    <div class="inner-side-bar-header">
                      <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
                        {process.env.REACT_APP_TITLE}
                      </Offcanvas.Title>
                      <CgClose
                        className="top-bar-toggle"
                        onClick={this.toggleSideBar.bind(this, false)}
                      ></CgClose>
                    </div>

                    <div class="inner-side-bar-body">
                      <Nav className="justify-content-start flex-grow-1 pe-3">
                        <Nav.Link
                          onClick={this.sideBarGoToUrl.bind(
                            this,
                            "/dashboard/payment-options"
                          )}
                        >
                          {this.props.localizedText["pay"]}
                        </Nav.Link>

                        <Nav.Link
                          onClick={this.sideBarGoToUrl.bind(
                            this,
                            "/dashboard/portfolio/assets"
                          )}
                        >
                          {this.props.localizedText["assets"]}
                        </Nav.Link>

                        <Nav.Link
                          onClick={this.sideBarGoToUrl.bind(
                            this,
                            "/dashboard/deposit"
                          )}
                        >
                          {this.props.localizedText["deposit"]}
                        </Nav.Link>
                        <Nav.Link
                          onClick={this.sideBarGoToUrl.bind(
                            this,
                            "/dashboard/withdraw"
                          )}
                        >
                          {this.props.localizedText["withdraw"]}
                        </Nav.Link>
                        <Nav.Link
                          onClick={this.sideBarGoToUrl.bind(
                            this,
                            "/dashboard/portfolio/history"
                          )}
                        >
                          {this.props.localizedText["history"]}
                        </Nav.Link>
                        <Nav.Link
                          onClick={this.sideBarGoToUrl.bind(
                            this,
                            "/dashboard/conversion"
                          )}
                        >
                          {this.props.localizedText["convert"]}
                        </Nav.Link>
                        <Nav.Link
                          onClick={this.sideBarGoToUrl.bind(
                            this,
                            "/dashboard/settings"
                          )}
                        >
                          {this.props.localizedText["settings"]}
                        </Nav.Link>
                      </Nav>
                      <div class="bottom-section">
                        {process.env.REACT_APP_THEMES == "2" ? (
                          <div>
                            {this.props.theme == "dark" ? (
                              <div
                                class="theme-region"
                                onClick={this.toggleTheme.bind(this, "light")}
                              >
                                <MdNightlight class="item-icon"></MdNightlight>
                                {this.props.localizedText["change-theme"]}
                              </div>
                            ) : (
                              <div
                                class="theme-region"
                                onClick={this.toggleTheme.bind(this, "dark")}
                              >
                                <MdSunny class="item-icon"></MdSunny>
                                {this.props.localizedText["change-theme"]}
                              </div>
                            )}
                          </div>
                        ) : null}

                        {this.props.region == "en" ? (
                          <div
                            class="theme-region"
                            onClick={this.toggleRegion.bind(this, "es")}
                          >
                            <FiGlobe class="item-icon" />
                            {this.props.localizedText["change-region"]}
                            &nbsp;(ES)
                          </div>
                        ) : (
                          <div
                            class="theme-region"
                            onClick={this.toggleRegion.bind(this, "en")}
                          >
                            <FiGlobe class="item-icon" />
                            {this.props.localizedText["change-region"]}
                            &nbsp;(EN)
                          </div>
                        )}
                        <div class="logout-item" onClick={this.logout}>
                          <BiLogOut class="item-icon" />
                          {this.props.localizedText["logout"]}
                        </div>
                      </div>
                    </div>
                  </Offcanvas.Header>
                </Offcanvas>
                <Nav>
                  <GiHamburgerMenu
                    variant="primary"
                    onClick={this.toggleSideBar.bind(this, true)}
                    className={
                      this.state.isSticky
                        ? "top-bar-toggle nav-text-sticky"
                        : "top-bar-toggle"
                    }
                  ></GiHamburgerMenu>
                </Nav>
              </Nav>
            )}
          </Container>
          {!this.state.isMobileWidth && this.state.showThemeRegion ? (
            <ThemeRegionDiv
              isSticky={this.state.isSticky}
              changeTheme={this.props.changeTheme}
              theme={this.props.theme}
              handleModalOpen={this.props.handleModalOpen}
              changeRegion={this.props.changeRegion} //localization change
              region={this.props.region}
              localizedText={this.props.localizedText}
            ></ThemeRegionDiv>
          ) : null}
        </Navbar>
      </React.Fragment>
    );
  }
}

export default withRouter(Layout);
