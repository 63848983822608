import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./vendorDetail.css";
import GoBackButton from "../../../components/go-back-button/goBackButton";
import Button from "react-bootstrap/Button";
import VendorDetailService from "./vendorDetailService";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { TabContent } from "react-bootstrap";
import { ImSearch } from "react-icons/im";
import { MdOutlineDeleteOutline, MdEdit } from "react-icons/md";
import { FaSearchMinus, FaPrint } from "react-icons/fa";
import { LuPackageSearch } from "react-icons/lu";
import { utils } from "../../../utils/utils";
import { IoMdDownload, IoMdOpen } from "react-icons/io";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import ReceiptComponent from "../../../components/receipt/receipt";
import Spinner from "react-bootstrap/Spinner";
import PrintPDFComponent from "../../../components/printPDF/printPDF";

class VendorDetail extends Component {
  state = {
    isMobileWidth: false,

    vendor: {},
    listVendorsSkeleton: [1, 2, 3, 4, 5, 6],
    listPaymentMethods: [], //only used for loading selected payment methods

    listSelectedVendorPaymentMethods: [],
    listSelectedVendorPaymentMethodsShowed: [],

    listPayments: [],
    listPaymentsShowed: [],

    selectedDivIndex: null,
    selectedPaymentMethod: {}, //to delete
    selectedPayment: {},
    selectedButtonType: null,

    showModalDeletePaymentMethod: false,
    showModalDeleteVendor: false,
    showModalPaymentDetails: false,
    showModalApprovePayment: false,

    tabPicked: "pay",
    loadingPaymentMethods: false,
    loadingPayments: false,
    loadingSentDelete: false,
  };

  service = new VendorDetailService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.phoneVerified != null && !this.props.phoneVerified) {
      this.goToURL("/dashboard/settings/verify-phone");
    } else if (this.props.emailVerified != null && !this.props.emailVerified) {
      this.goToURL("/dashboard/settings/verify-email");
    } else if (this.props.idVerified != null && !this.props.idVerified) {
      this.goToURL("/dashboard/settings/id-verification");
    }

    this.loadInfo();

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      "/dashboard/payment/vendors"
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        "/dashboard/payment/vendors"
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.phoneVerified != null && !this.props.phoneVerified) {
        this.goToURL("/dashboard/settings/verify-phone");
      } else if (
        this.props.emailVerified != null &&
        !this.props.emailVerified
      ) {
        this.goToURL("/dashboard/settings/verify-email");
      } else if (this.props.idVerified != null && !this.props.idVerified) {
        this.goToURL("/dashboard/settings/id-verification");
      }
    }
  }

  loadInfo() {
    //comes from payment detail click
    this.service.getPayments(
      this.props.match.params.id,
      this.setState.bind(this),
      this.openPaymentDetailsFromSelectedPayment.bind(this)
    );
    if (
      this.props.location.megastate &&
      this.props.location.megastate.vendor &&
      this.props.location.megastate.listPaymentMethods
    ) {
      this.setState(
        {
          vendor: this.props.location.megastate.vendor,
          listPaymentMethods: this.props.location.megastate.listPaymentMethods,
        },
        () => {
          if (this.state.listPaymentMethods.length > 0) {
            const listSelectedVendorPaymentMethods =
              this.getSelectedVendorPaymentMethods(this.props.match.params.id);
            this.setState({
              listSelectedVendorPaymentMethods:
                listSelectedVendorPaymentMethods,
              listSelectedVendorPaymentMethodsShowed:
                listSelectedVendorPaymentMethods,
              //have to wait for listPaymethods to fill
            });
          } else {
            this.setState({
              listSelectedVendorPaymentMethods: [],
              listSelectedVendorPaymentMethodsShowed: [],
            });
          }
        }
      );
    } else {
      //call service
      this.setState(
        {
          loadingPaymentMethods: true,
          loadingPayments: true,
        },
        () => {
          this.service.getVendors(
            this.setState.bind(this),
            this.props.match.params.id
          );
          this.service.getVendorPaymentMethods(
            this.setState.bind(this),
            this.getSelectedVendorPaymentMethods.bind(this),
            this.props.match.params.id
          );
        }
      );
    }
  }

  reloadPaymentMethods = () => {
    this.setState(
      {
        loadingPaymentMethods: true,
      },
      () => {
        this.service.getVendorPaymentMethods(
          this.setState.bind(this),
          this.getSelectedVendorPaymentMethods.bind(this),
          this.props.match.params.id
        );
      }
    );
  };

  goToURL = (url) => {
    this.props.history.push({
      pathname: url,
      megastate: { from: "vendorDetail" },
    });
  };

  goToAddPaymentMethod = (id) => {
    this.props.history.push({
      pathname: "/dashboard/payment/vendors/payment-methods",
      megastate: {
        from: "vendorDetail",
        client_vendor_id: id,
      },
    });
  };

  goToUpdateVendor = () => {
    this.props.history.push({
      pathname:
        "/dashboard/payment/vendors/update/" + this.props.match.params.id,
      megastate: {
        from: "vendorDetail",
        vendor: this.state.vendor,
      },
    });
  };

  goToUpdatePaymentMethod = (paymentMethod) => {
    this.props.history.push({
      pathname:
        "/dashboard/payment/vendors/" +
        this.props.match.params.id +
        "/payment-methods/update/" +
        paymentMethod.payment_account_id,
      megastate: {
        from: "vendorDetail",
        client_vendor_id: this.props.match.params.id,
        paymentMethod: paymentMethod,
      },
    });
  };

  goToPayVendor = (paymentMethod) => {
    this.props.history.push({
      pathname: "/dashboard/payment/vendors/pay",
      megastate: {
        from: "vendorDetail",
        paymentMethod: paymentMethod,
      },
    });
  };

  getSelectedVendorPaymentMethods = (id) => {
    const selectedPaymentMethods = this.state.listPaymentMethods.find(
      (x) => x.client_vendor_id === id
    );
    console.log("selectedPaymentMethods", selectedPaymentMethods);
    if (selectedPaymentMethods) {
      return selectedPaymentMethods.json_build_object.accounts;
    } else {
      return [];
    }
  };

  openDeletePaymentMethod = (paymentMethod) => {
    this.setState({
      showModalDeletePaymentMethod: true,
      selectedPaymentMethod: paymentMethod,
    });
  };

  handleCloseDeletePaymentMethod = () => {
    this.setState({
      showModalDeletePaymentMethod: false,
    });
  };

  handleClosePaymentDetails = () => {
    this.setState({
      selectedDivIndex: null,
      showModalPaymentDetails: false,
    });
  };

  handleCloseApprovePayment = () => {
    this.setState({
      selectedDivIndex: null,
      showModalApprovePayment: false,
    });
  };

  openPaymentDetails = (payment, index) => {
    if (payment.status == "Pending" && process.env.REACT_APP_ENV != "prod") {
      this.setState({
        selectedDivIndex: index,
        selectedPayment: payment,
        showModalApprovePayment: true,
      });
    } else {
      this.setState({
        selectedDivIndex: index,
        selectedPayment: payment,
        showModalPaymentDetails: true,
      });
    }
  };

  openPaymentDetailsFromSelectedPayment = () => {
    if (
      this.props.location.megastate &&
      this.props.location.megastate.selectedPaymentIndex != null
    ) {
      this.setState({
        tabPicked: "payments",
        selectedDivIndex: this.props.location.megastate.selectedPaymentIndex,
        selectedPayment:
          this.state.listPayments[
            this.props.location.megastate.selectedPaymentIndex
          ],
        showModalPaymentDetails: true,
      });
    }
  };

  openDeleteVendor = () => {
    this.setState({
      showModalDeleteVendor: true,
    });
  };

  handleCloseDeleteVendor = () => {
    this.setState({
      showModalDeleteVendor: false,
    });
  };

  deletePaymentMethod = () => {
    this.setState(
      {
        loadingSentDelete: true,
      },
      () => {
        this.service.deletePaymentMethod(
          this.state.selectedPaymentMethod.payment_account_id,
          this.reloadPaymentMethods.bind(this),
          this.setState.bind(this)
        );
      }
    );
  };

  deleteVendor = () => {
    this.setState(
      {
        loadingSentDelete: true,
      },
      () => {
        this.service.deleteVendor(
          this.props.match.params.id,
          this.goToDeleteVendorResult.bind(this)
        );
      }
    );
  };

  goToDeleteVendorResult = (value) => {
    this.props.history.push({
      pathname: "/dashboard/result/delete-vendor/" + value,
      megastate: { returnURL: "/dashboard/payment/vendors" },
    });
  };

  clickTab = (tabClicked) => {
    this.setState({
      tabPicked: tabClicked,
      selectedDivIndex: null,
    });
  };

  skeletonCards = ({ cards }) => (
    <div class="div-cards">
      {cards.map((portfolio) => (
        <div class="card-vendor">
          <div class="metadata-column-skeleton">
            <div class=" skeleton-box image-skeleton"></div>
            <div class="text-skeleton">
              <div class="skeleton-box title-skeleton"></div>
              <div class="skeleton-box abbreviation-skeleton"></div>
            </div>
          </div>
          <div class="skeleton-box  graph-column-skeleton"></div>
          <div class="balance-column-skeleton">
            <div class="skeleton-box value-skeleton"></div>
            <div class="skeleton-box percentage-skeleton"></div>
          </div>
        </div>
      ))}
    </div>
  );

  paymentMethods = ({ listPaymentMethods }) => (
    <div style={{ height: "100%" }}>
      <InputGroup className="search-bar search-vendor-detail">
        <ImSearch class="search-icon"></ImSearch>
        <FormControl
          onChange={(e) => {
            utils.editSearchTermDefault(
              e,
              this.setState.bind(this),
              this.state.listSelectedVendorPaymentMethods,
              "listSelectedVendorPaymentMethodsShowed",
              ["name"]
            );
          }}
          type="search"
          id="search-bar-entities"
          placeholder={this.props.localizedText["search-payment-methods"]}
        />
      </InputGroup>
      {this.state.listSelectedVendorPaymentMethods.length > 0 &&
      this.state.listSelectedVendorPaymentMethodsShowed.length > 0 ? (
        <div class="div-cards">
          <div class="headers">
            <div class="name-column">{this.props.localizedText["name"]}</div>
            <div class="type-column">{this.props.localizedText["type"]}</div>
            <div class="metadata-column"></div>
            <div class="button-column"></div>
          </div>
          {listPaymentMethods.map((paymentMethod, index) => (
            <div
              class={
                this.state.selectedDivIndex === index
                  ? "card-vendor selected-card"
                  : "card-vendor"
              }
            >
              <div class="name-column">{paymentMethod.name}</div>
              <div class="type-column">{paymentMethod.pay_method_type}</div>
              {Object.keys(paymentMethod.metadata).length < 4 ? (
                <div class="metadata-column">
                  {Object.keys(paymentMethod.metadata).map((key, i) => (
                    <span class="metadata-data">
                      {key + ": " + paymentMethod.metadata[key]}
                    </span>
                  ))}
                </div>
              ) : (
                <div class="metadata-column">
                  {Object.keys(paymentMethod.metadata)
                    .slice(0, 4)
                    .map((key, i) => (
                      <span class="metadata-data">
                        {key + ": " + paymentMethod.metadata[key]}
                      </span>
                    ))}
                </div>
              )}

              <div class="button-column">
                <MdEdit
                  className="icon"
                  onClick={this.goToUpdatePaymentMethod.bind(
                    this,
                    paymentMethod
                  )}
                ></MdEdit>
                <MdOutlineDeleteOutline
                  className="icon"
                  onClick={this.openDeletePaymentMethod.bind(
                    this,
                    paymentMethod
                  )}
                ></MdOutlineDeleteOutline>{" "}
                <Button
                  className="pay-button inside-primary-button"
                  onClick={this.goToPayVendor.bind(this, paymentMethod)}
                >
                  {this.props.localizedText["pay"]}
                </Button>
              </div>
            </div>
          ))}
        </div>
      ) : null}
      {this.state.listSelectedVendorPaymentMethods.length > 0 &&
      this.state.listSelectedVendorPaymentMethodsShowed.length == 0 ? (
        <div class="no-list-div">
          <FaSearchMinus className="empty-icon" />
          <div class="title">{this.props.localizedText["no-result-found"]}</div>
          <div class="sub-title">
            {
              this.props.localizedText[
                "we-couldn't-find-any-items-matching-your-search"
              ]
            }
          </div>
        </div>
      ) : null}
      {this.state.listSelectedVendorPaymentMethods.length == 0 &&
      this.state.listSelectedVendorPaymentMethodsShowed.length == 0 ? (
        <div class="no-list-div">
          <LuPackageSearch className="empty-icon" />
          <div class="title">
            {this.props.localizedText["no-payment-methods-yet"]}
          </div>
          <div class="sub-title">
            {this.props.localizedText["add-payment-methods-for-easy-payments"]}
          </div>
        </div>
      ) : null}
    </div>
  );

  listPayments = ({ payments }) => (
    <div style={{ height: "100%" }}>
      {" "}
      <InputGroup className="search-bar search-vendor-detail">
        <ImSearch class="search-icon"></ImSearch>
        <FormControl
          onChange={(e) => {
            utils.editSearchTermDefault(
              e,
              this.setState.bind(this),
              this.state.listPayments,
              "listPaymentsShowed",
              ["account", "account_id"]
            );
          }}
          type="search"
          id="search-bar-entities"
          placeholder={this.props.localizedText["search-payments"]}
        />
      </InputGroup>
      {this.state.listPayments.length > 0 &&
      this.state.listPaymentsShowed.length > 0 ? (
        <div class="div-cards">
          <div class="headers">
            <div class="account-column">
              {this.props.localizedText["account"]}
            </div>
            <div class="amount-column">
              {this.props.localizedText["amount"]}
            </div>
            <div class="date-column">{this.props.localizedText["date"]}</div>
            <div class="paid-with-column"></div>{" "}
            {process.env.REACT_APP_ENV != "prod" ? (
              <div class="status-column">
                {this.props.localizedText["status"]}
              </div>
            ) : null}
            <div class="icons-column"></div>
          </div>
          {payments.map((payment, index) => (
            <div
              class={
                this.state.selectedDivIndex === index
                  ? "card-vendor cursor-pointer selected-card"
                  : "card-vendor cursor-pointer"
              }
              onClick={this.openPaymentDetails.bind(this, payment, index)}
            >
              <div class="account-column">{payment.account}</div>
              <div class="amount-column">
                {utils.roundToTwoDecimals(payment.amount)}
              </div>
              <div class="date-column">
                {utils.formatDateUS(payment.creation_time)}
              </div>
              <div class="paid-with-column">
                {payment.paid_with.slice(0, 4) +
                  " " +
                  payment.paid_with.slice(
                    payment.paid_with.length - 8,
                    payment.paid_with.length
                  )}
              </div>
              {payment.status == "Approved" &&
              process.env.REACT_APP_ENV != "prod" ? (
                <div class="status-column success-background">
                  {payment.status}
                </div>
              ) : null}
              {payment.status == "Failed" &&
              process.env.REACT_APP_ENV != "prod" ? (
                <div class="status-column danger-background">
                  {payment.status}
                </div>
              ) : null}{" "}
              {payment.status == "Pending" &&
              process.env.REACT_APP_ENV != "prod" ? (
                <div class="status-column pending-background">
                  {payment.status}
                </div>
              ) : null}
              <div class="icons-column">
                {" "}
                <IoMdOpen class="right-icon" />
              </div>
            </div>
          ))}
        </div>
      ) : null}
      {this.state.listPayments.length > 0 &&
      this.state.listPaymentsShowed.length == 0 ? (
        <div class="no-list-div">
          <FaSearchMinus className="empty-icon" />
          <div class="title">{this.props.localizedText["no-result-found"]}</div>
          <div class="sub-title">
            {
              this.props.localizedText[
                "we-couldn't-find-any-items-matching-your-search"
              ]
            }
          </div>
        </div>
      ) : null}
      {this.state.listPayments.length == 0 &&
      this.state.listPaymentsShowed.length == 0 ? (
        <div class="no-list-div">
          <LuPackageSearch className="empty-icon" />
          <div class="title">{this.props.localizedText["no-payments-yet"]}</div>
          <div class="sub-title">
            {this.props.localizedText["add-payment-methods-for-easy-payments"]}
          </div>
        </div>
      ) : null}
    </div>
  );

  getReceiptProps = () => {
    var arrayData = [
      {
        name: this.props.localizedText["account-id"],
        value: this.state.selectedPayment.account_id,
        type: "normal",
      },
      {
        name: this.props.localizedText["account"],
        value: this.state.selectedPayment.account,
        type: "normal",
      },
      {
        name: this.props.localizedText["amount"],
        value: this.state.selectedPayment.amount,
        type: "amount",
      },
      {
        name: this.props.localizedText["date"],
        value: this.state.selectedPayment.creation_time,
        type: "date",
      },
      {
        name: this.props.localizedText["description"],
        value: this.state.selectedPayment.description,
        type: "normal",
      },
      {
        name: this.props.localizedText["notes"],
        value: this.state.selectedPayment.notes,
        type: "normal",
      },
    ];

    if (process.env.REACT_APP_ENV != "prod") {
      arrayData.push({
        name: this.props.localizedText["status"],
        value: this.state.selectedPayment.status.toLowerCase(),
        type: "status",
      });
    }

    var data = [
      {
        title: this.props.localizedText["transfer-summary"],
        data: arrayData,
      },
    ];

    return data;
  };

  render() {
    return (
      <React.Fragment>
        <div class="vendor-container">
          <div class="vendor-body">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
                goBack={"/dashboard/payment/vendors"}
              ></GoBackButton>
            </div>
            <div class="middle-container container-padding">
              <div class="title-div">
                <div class="title">{this.state.vendor.name}</div>
              </div>
              <Tab.Container
                defaultActiveKey={
                  this.props.location.megastate &&
                  this.props.location.megastate.selectedPaymentIndex != null
                    ? "payments"
                    : "pay"
                }
              >
                <div class="tab-header">
                  <div class="tab-buttons">
                    <Nav.Item
                      class={
                        this.state.tabPicked == "pay"
                          ? "tab-button tab-picked"
                          : "tab-button"
                      }
                    >
                      <Nav.Link
                        eventKey="pay"
                        onClick={this.clickTab.bind(this, "pay")}
                      >
                        {this.props.localizedText["pay"]}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      class={
                        this.state.tabPicked == "payments"
                          ? "tab-button tab-picked"
                          : "tab-button"
                      }
                    >
                      <Nav.Link
                        eventKey="payments"
                        onClick={this.clickTab.bind(this, "payments")}
                      >
                        {this.props.localizedText["payments"]}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      class={
                        this.state.tabPicked == "info"
                          ? "tab-button tab-picked"
                          : "tab-button"
                      }
                    >
                      <Nav.Link
                        eventKey="info"
                        onClick={this.clickTab.bind(this, "info")}
                      >
                        {this.props.localizedText["info"]}
                      </Nav.Link>
                    </Nav.Item>
                  </div>

                  {this.state.tabPicked == "pay" ? (
                    <Button
                      className="primary-button outside-primary-button small-button fade-in-up"
                      onClick={this.goToAddPaymentMethod.bind(
                        this,
                        this.state.vendor.client_vendor_id
                      )}
                    >
                      {this.props.localizedText["add-payment-method"]}
                    </Button>
                  ) : null}
                  {this.state.tabPicked == "payments" ? (
                    <Nav.Link
                      className="primary-button outside-primary-button small-button fade-in-up"
                      eventKey="pay"
                      onClick={this.clickTab.bind(this, "pay")}
                    >
                      {this.props.localizedText["pay"]}
                    </Nav.Link>
                  ) : null}
                  {this.state.tabPicked == "info" ? (
                    <Button
                      className="primary-button outside-primary-button small-button fade-in-up"
                      onClick={this.goToUpdateVendor.bind(this)}
                    >
                      {this.props.localizedText["update-vendor"]}
                    </Button>
                  ) : null}
                </div>
                <Tab.Content>
                  <Tab.Pane eventKey="pay" tabClassName="tab">
                    <TabContent>
                      <div class="bottom-content">
                        {!this.state.loadingPaymentMethods ? (
                          <this.paymentMethods
                            listPaymentMethods={
                              this.state.listSelectedVendorPaymentMethodsShowed
                            }
                          ></this.paymentMethods>
                        ) : (
                          <this.skeletonCards
                            cards={this.state.listVendorsSkeleton}
                          ></this.skeletonCards>
                        )}
                      </div>
                    </TabContent>
                  </Tab.Pane>
                  <Tab.Pane eventKey="payments" tabClassName="tab">
                    <TabContent>
                      <div class="bottom-content">
                        {!this.state.loadingPayments ? (
                          <this.listPayments
                            payments={this.state.listPaymentsShowed}
                          ></this.listPayments>
                        ) : (
                          <this.skeletonCards
                            cards={this.state.listVendorsSkeleton}
                          ></this.skeletonCards>
                        )}
                      </div>
                    </TabContent>
                  </Tab.Pane>

                  <Tab.Pane eventKey="info" tabClassName="tab">
                    <TabContent>
                      <div class="bottom-content">
                        <div class="info-div">
                          {Object.keys(this.state.vendor).map((key, i) => (
                            <div style={{ width: "100%" }}>
                              {typeof this.state.vendor[key] == "string" ? (
                                <div class="info-row">
                                  <div class="key">{key}</div>
                                  <div class="value">
                                    {this.state.vendor[key]}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          ))}
                        </div>
                        <div class="buttons-div">
                          <Button
                            className="primary-button outside-danger-button"
                            onClick={this.openDeleteVendor}
                          >
                            {this.props.localizedText["delete-vendor"]}
                          </Button>
                        </div>
                      </div>
                    </TabContent>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
            <div class="side-container"></div>
          </div>
        </div>

        <Modal
          show={this.state.showModalDeletePaymentMethod}
          onHide={this.handleCloseDeletePaymentMethod}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.localizedText["delete-payment-method"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.localizedText[
              "are-you-sure-you-want-to-delete-the-payment-method"
            ] + " "}
            <b>{this.state.selectedPaymentMethod.name}</b>
            {"?"}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="back-button outside-secondary-button"
              variant=""
              onClick={this.handleCloseDeletePaymentMethod}
            >
              {this.props.localizedText["close"]}
            </Button>
            <Button
              className="verify-button outside-primary-button"
              variant=""
              onClick={this.deletePaymentMethod}
              disabled={this.state.loadingSentDelete}
            >
              {this.state.loadingSentDelete ? (
                <Spinner className="loader-send-outside-div-button"></Spinner>
              ) : null}
              {this.props.localizedText["delete"]}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModalPaymentDetails}
          onHide={this.handleClosePaymentDetails}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.localizedText["payment-details"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="detail-container">
              {Object.keys(this.state.selectedPayment).length > 0 ? (
                <ReceiptComponent
                  data={this.getReceiptProps()}
                ></ReceiptComponent>
              ) : null}

              <div class="icons-row">
                <IoMdDownload
                  class="left-icon"
                  onClick={utils.generatePDF.bind(
                    this,
                    this.state.selectedPayment.account +
                      "-" +
                      this.state.selectedPayment.amount
                  )}
                />{" "}
                <ReactToPrint content={() => this.componentRef}>
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <FaPrint class="right-icon" onClick={handlePrint} />
                    )}
                  </PrintContextConsumer>
                </ReactToPrint>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div class="payment-detail-footer">
              {this.props.localizedText["if-you-need-help"]}
              <br />
              {this.props.localizedText["go-to-our-"]}
              <a href="https://www.blox.global/contact/">
                {this.props.localizedText["resolution-center"]}
              </a>{" "}
              {
                this.props.localizedText[
                  "for-help-with-this-transaction,-to-settle-a-dispute-or-open-a-claim"
                ]
              }
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModalApprovePayment}
          onHide={this.handleCloseApprovePayment}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.localizedText["approve-payment"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="approve-text">
              {
                this.props.localizedText[
                  "are-you-sure-you-want-to-approve-the-following-payment:"
                ]
              }
            </div>
            {Object.keys(this.state.selectedPayment).length > 0 ? (
              <ReceiptComponent
                data={this.getReceiptProps()}
              ></ReceiptComponent>
            ) : null}
          </Modal.Body>
          <Modal.Footer className="vendor-detail-modal-footer">
            <Button className="back-button outside-secondary-button">
              {this.props.localizedText["close"]}
            </Button>
            <div style={{ display: "flex" }}>
              <Button className="reject-button outside-primary-button">
                {this.props.localizedText["reject"]}
              </Button>
              <Button className="outside-primary-button">
                {this.props.localizedText["approve"]}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModalDeleteVendor}
          onHide={this.handleCloseDeleteVendor}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.localizedText["delete-vendor"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.localizedText[
              "are-you-sure-you-want-to-delete-the-vendor"
            ] + " "}
            <b>{this.state.vendor.name}</b>
            {"?"}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="back-button outside-secondary-button"
              variant=""
              onClick={this.handleCloseDeleteVendor}
            >
              {this.props.localizedText["close"]}
            </Button>
            <Button
              className="verify-button outside-primary-button"
              variant=""
              onClick={this.deleteVendor}
              disabled={this.state.loadingSentDelete}
            >
              {this.state.loadingSentDelete ? (
                <Spinner className="loader-send-outside-div-button"></Spinner>
              ) : null}
              {this.props.localizedText["delete"]}
            </Button>
          </Modal.Footer>
        </Modal>

        <div style={{ display: "none" }} id="hidden-div">
          <div
            class="detail-container-print"
            ref={(el) => (this.componentRef = el)}
            id="pdf"
          >
            {Object.keys(this.state.selectedPayment).length > 0 ? (
              <PrintPDFComponent
                theme={this.props.theme}
                title={this.props.localizedText["transaction-details"]}
                receiptData={this.getReceiptProps()}
                selectedTransaction={this.state.selectedPayment}
                text={this.props.localizedText["powered-by-blox"]}
              ></PrintPDFComponent>
            ) : null}
          </div>{" "}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(VendorDetail);
