import { loginAPI } from "../../../apis/loginAPI";

export default class TwoStepVerificationService {
  confirmPhone(setState) {
    loginAPI
      .confirmPhone()
      .then((response) => {
        if (response.status === 200) {
          //Success
          var mySubString = response.data.payload.substring(
            response.data.payload.indexOf("[") + 1,
            response.data.payload.lastIndexOf("]]")
          );

          setState(
            {
              message:
                "xxx-xxx-" +
                mySubString.slice(mySubString.length - 3, mySubString.length),
            },
            () => {
              setState({ loadingPhone: false });
            }
          );
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
      });
  }

  validatePhone(code, setState, setMessages, getClientConfig) {
    loginAPI
      .validatePhone(code)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          //Success
          getClientConfig();
          setState(
            {
              loadingSent: false,
            },
            () => {
              setState({
                stage: 2,
              });
            }
          );
        } else {
          //Failure
          setMessages("verify-phone-error-validate-phone");
          setState({
            invalidCode: true,
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        setMessages("verify-phone-error-validate-phone");
        setState({
          invalidCode: true,
          loadingSent: false,
        });
      });
  }
}
