import { loginAPI } from "../../apis/loginAPI";

export default class LayoutService {
  logout(redirectCallback) {
    loginAPI
      .logout()
      .then((response) => {
        console.log(response);

        localStorage.clear();
        redirectCallback("/");
      })
      .catch((error) => {
        //Failure
        console.log(error);
        localStorage.clear();
        redirectCallback("/");
      });
  }
}
